<template>
  <v-row>
    <v-col>
      <v-row class="mt-1">
        <v-col cols="12">
          <v-container fluid>
            <v-expansion-panels popout multiple accordion tile v-model="openPanels">
              <v-expansion-panel v-if="!noticketsReceivables" value="ticketReceivables">
                <v-expansion-panel-header>
                  {{$t('ticketReceivables')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span v-if="paymentsEntries.length > 0">
                    <p class="subtitle-1 mt-3 mb-n2">{{$t('contractPayments')}}</p>
                    <TicketFinancialsTable
                    :entries="paymentsEntries"
                    @aggregateSelected="contractPaymentSelected"/>
                  </span>

                  <span v-if="correctionEntries.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="correctionEntries"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && logYardSaleReceivableEntries.length > 0" value="logYardSaleReceivables">
                <v-expansion-panel-header>
                  {{$t('logYardSaleReceivables')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <TicketFinancialsTable
                  :entries="logYardSaleReceivableEntries"
                  @aggregateSelected="logYardSaleReceivableSelected"/>

                  <span v-if="logYardSaleCorrectionEntries.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="logYardSaleCorrectionEntries"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-col>
      </v-row>
      <NoData v-if="showNoData" class="mb-4">
        {{ $t('noDataForSelection') }}
      </NoData>
    </v-col>
    <v-dialog v-model="detailDialogOpen" width="900px">
      <TicketwiseFinancialBreakdown
      v-if="detailDialogOpen"
      :isCorrection="focusedContractPaymentIsCorrection"
      :payableSummary="focusedContractPayment"
      :isByproducts="isByproducts"
      @close="closeDialogs"/>
    </v-dialog>
  </v-row>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
import AdjustingEntriesHeaders from '@/headers/AdjustingEntries.js'
import { MiscPaymentType } from '@/utils/Enumerations'
export default {
  name: 'ReceivablesAggregations',

  components: {
    TicketwiseFinancialBreakdown: () => import('@/components/accounting/adjusting-entries/v1/TicketwiseFinancialBreakdown.vue'),
    TicketFinancialsTable: () => import('@/components/accounting/adjusting-entries/v1/TicketFinancialsTable.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  props: {
    receivables: {
      type: Object,
      required: true
    },
    isByproducts: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    detailDialogOpen: false,
    focusedContractPayment: undefined,
    focusedContractPaymentIsCorrection: false,
    focusedReceivableIsTransfer: false,
    openPanels: [],
    MiscPaymentType
  }),

  computed: {
    contractPaymentHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    correctionsHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    paymentsEntries () {
      return Array.from(this.receivables?.contractPayments?.values() ?? [])
    },
    correctionEntries () {
      return Array.from(this.receivables?.corrections?.values() ?? [])
    },
    logYardSaleCorrectionEntries () {
      return Array.from(this.receivables?.logYardSaleCorrections?.values() ?? [])
    },
    logYardSaleReceivableEntries () {
      return Array.from(this.receivables?.logYardSaleReceivables?.values() ?? [])
    },
    noticketsReceivables () {
      return this.paymentsEntries.length === 0 && this.correctionEntries.length === 0
    },
    showNoData () {
      return this.noticketsReceivables &&
        this.logYardSaleReceivableEntries.length === 0 &&
        this.logYardSaleCorrectionEntries.length === 0
    },
    contractColor () {
      return this.isByproducts ? 'byproduct' : 'primary'
    },
    borderStyle () {
      return `border: 3px solid ${this.isByproducts ? '#363636' : '#D15F27'}`
    }
  },

  methods: {
    formatMoney,

    contractPaymentSelected (contractPayment) {
      this.detailDialogOpen = true
      this.focusedContractPayment = contractPayment
    },

    correctionSelected (correction) {
      this.focusedContractPayment = correction
      this.focusedContractPaymentIsCorrection = true
      this.detailDialogOpen = true
    },

    logYardSaleReceivableSelected (logYardSaleReceivable) {
      this.focusedContractPayment = logYardSaleReceivable
      this.focusedReceivableIsLogYardSale = true
      this.detailDialogOpen = true
    },

    closeDialogs () {
      this.detailDialogOpen = false
      this.focusedContractPayment = undefined
      this.focusedContractPaymentIsCorrection = false
      this.focusedReceivableIsTransfer = false
      this.focusedReceivableIsLogYardSale = false
    }
  }
}
</script>
