import { exportCSVFile } from '@/utils/CSVUtility.js'
import { uuidv4 } from '@/utils/NumericMutations.js'
import { groupBy, sumBy } from 'lodash'
import i18n from '@/i18n'
import { DefectCategory } from '@/utils/Enumerations'

// Creates data that a stacked bar chart can use.

export class StackedBarChart {
  constructor (data, key, type, miniBarType = 'product', options = {
    csv: {
      includeWeight: true,
      includeLoadCount: true
    }
  }) {
    miniBarType ??= 'product'
    let availableMiniBars
    switch (miniBarType) {
      case 'product':
        availableMiniBars = Object.keys(data.reduce((acc, i) => {
          i.loadsByProduct.forEach((item) => {
            acc[item.product ?? item.productName] = true
          })
          return acc
        }, {}))
        break
      case 'tract':
        availableMiniBars = Object.keys(data.reduce((acc, i) => {
          i.loadsByTract.forEach((item) => {
            acc[item.tract ?? item.tractName] = true
          })
          return acc
        }, {}))
        break
      case 'destination':
        availableMiniBars = Object.keys(data.reduce((acc, i) => {
          i.loadsByDestination.forEach((item) => {
            acc[item.accountName] = true
          })
          return acc
        }, {}))
        break
      default:
        throw new Error('Unsupported chart type.')
    }

    const itemData = data.reduce((acc, item) => {
      acc[item[key]] = {}
      availableMiniBars.forEach((miniBar) => {
        acc[item[key]][miniBar] = {
          miniBar: miniBar,
          tons: 0,
          loadCount: 0,
          ticketIds: []
        }
      })
      return acc
    }, {})

    switch (miniBarType) {
      case 'product':
        data.forEach(item => {
          item.loadsByProduct.forEach(lbp => {
            itemData[item[key]][lbp.product ?? lbp.productName] = lbp
          })
        })
        break
      case 'tract':
        data.forEach(item => {
          item.loadsByTract.forEach(lbt => {
            itemData[item[key]][lbt.tract ?? lbt.tractName] = lbt
          })
        })
        break
      case 'destination':
        data.forEach(item => {
          item.loadsByDestination.forEach(lbd => {
            itemData[item[key]][lbd.accountName] = lbd
          })
        })
        break
      default:
        throw new Error('Unsupported chart type.')
    }

    const dataSets = availableMiniBars.reduce((acc, miniBar, index) => {
      acc[index] = {
        label: miniBar,
        tonData: [],
        loadData: [],
        ticketIds: []
      }
      for (const item of Object.values(itemData)) {
        acc[index].tonData.push(item[miniBar].tons)
        acc[index].loadData.push(item[miniBar].loadCount)
        acc[index].ticketIds.push(item[miniBar].ticketIds)
      }
      return acc
    }, [])
    const labels = Object.keys(itemData)

    labels.forEach((label, index) => {
      if (label === 'null') {
        labels[index] = i18n.t('notAvailable')
      }
    })

    this.elementId = `${key}-${uuidv4()}`
    this.data = data
    this.key = key
    this.type = type
    this.dataSets = {
      labels: labels,
      sets: dataSets
    }
    this.options = options
    this.miniBarType = miniBarType
  }

  generateCSV (destinationName = '', includeSummary = true) {
    const headers = getHeaders(this.type, this.options.csv)

    const csvRows = []

    this.data.forEach(item => {
      if (includeSummary) {
        const firstRow = {
          product: (this.miniBarType === 'tract') ? i18n.t('allTracts') : this.miniBarType === 'destination' ? i18n.t('allDestinations') : i18n.t('allProducts'),
          loadCount: item.loadCount,
          pounds: Math.round(item.tons * 2000),
          tons: item.tons
        }
        csvRows.push(formatRowForType(this.type, firstRow, item, this.options.csv))
      }

      switch (this.miniBarType) {
        case 'product':
          item.loadsByProduct.forEach(product => {
            const row = {
              product: product.product ?? product.productName,
              loadCount: product.loadCount,
              pounds: Math.round(product.tons * 2000),
              tons: product.tons
            }
            csvRows.push(formatRowForType(this.type, row, item, this.options.csv))
          })
          break
        case 'tract':
          item.loadsByTract.forEach(tract => {
            const row = {
              tract: tract.tract ?? tract.tractName,
              loadCount: tract.loadCount,
              pounds: Math.round(tract.tons * 2000),
              tons: tract.tons
            }
            csvRows.push(formatRowForType(this.type, row, item, this.options.csv))
          })
          break
        case 'destination':
          item.loadsByDestination.forEach(destination => {
            const row = {
              destination: destination.accountName,
              loadCount: destination.loadCount,
              pounds: Math.round(destination.tons * 2000),
              tons: destination.tons
            }
            csvRows.push(formatRowForType(this.type, row, item, this.options.csv))
          })
          break
        default:
          throw new Error('Unsupported chart type.')
      }
    })

    const destFileNamePart = destinationName !== '' ? `-${destinationName.replaceAll(' ', '')}` : ''
    exportCSVFile(headers, csvRows, `Load-Info-By-${i18n.t(this.type).replace(' ', '-')}${destFileNamePart}`)
  }
}

export class StackedBarChartForLoadsInTransitByDestination {
  constructor (data, key, type) {
    data.forEach(d => {
      d.loadCountAndTract = d.loadCountAndTract.filter(lct => lct.tractName !== '')
    })
    const availableTracts = Object.keys(data.reduce((acc, i) => {
      i.loadCountAndTract.forEach((item) => {
        acc[item.tractName] = true
      })
      return acc
    }, {}))

    const itemData = data.reduce((acc, item) => {
      acc[item[key]] = {}
      availableTracts.forEach((tract) => {
        acc[item[key]][tract] = {
          tract: tract,
          loadCount: 0
        }
      })
      return acc
    }, {})

    data.forEach(item => {
      item.loadCountAndTract.forEach(lbp => {
        itemData[item[key]][lbp.tractName] = lbp
      })
    })

    const dataSets = availableTracts.reduce((acc, tract, index) => {
      acc[index] = {
        label: tract,
        loadData: [],
        ticketIds: []
      }
      for (const item of Object.values(itemData)) {
        acc[index].loadData.push(item[tract].loadCount)
        acc[index].ticketIds.push(item[tract].ticketIds)
      }
      return acc
    }, [])
    const labels = Object.keys(itemData)
    this.elementId = `${key}-${uuidv4()}`
    this.data = data
    this.key = key
    this.type = type
    this.dataSets = {
      labels: labels,
      sets: dataSets
    }
    this.miniBarType = 'tract'
  }

  generateCSV (destinationName = '') {
    const headers = getHeaders(this.type)

    const csvRows = []

    this.data.forEach((item) => {
      const destination = item.destinationName
      item.loadCountAndTract.forEach(countAndTract => {
        const row = {
          destination: destination,
          tractName: countAndTract.tractName,
          loadCount: countAndTract.loadCount
        }

        csvRows.push(formatRowForType(this.type, row, item))
      })
    })

    const destFileNamePart = destinationName !== '' ? `-${destinationName.replaceAll(' ', '')}` : ''
    exportCSVFile(headers, csvRows, `Load-Info-By-${this.type}${destFileNamePart}`)
  }
}

export class StackedBarChartForTicketsReadyForPickup {
  constructor (data, key, type) {
    const products = [...new Set(data.map(d => d.product))]
    const byTract = groupBy(data, d => d.tract)
    const labels = Object.keys(byTract)
    this.elementId = `${key}-${uuidv4()}`
    this.data = data
    this.key = key
    this.type = type
    this.miniBarType = 'product'
    this.dataSets = {
      labels,
      sets: products.map(product => {
        return {
          ticketIds: labels.map(tract => byTract[tract].filter(d => d.product === product).reduce((p, c) => ({
            ready: p.ready.concat(c.ticketIds),
            paused: p.paused.concat(c.ticketIdsPaused)
          }), {
            ready: [],
            paused: []
          })),
          label: product,
          data: labels.map(tract => byTract[tract].filter(d => d.product === product).reduce((p, c) => ({
            readyLoads: p.readyLoads + c.count,
            pausedLoads: p.pausedLoads + c.paused
          }), {
            readyLoads: 0,
            pausedLoads: 0
          }))
        }
      })
    }
  }

  generateCSV (destinationName = '', includeSummary = true) {
    throw new Error('cannot generate CSV for operational charts')
  }
}

export class StackedBarChartForForecastedDaysRemaining {
  constructor (data, key, type) {
    const availableTracts = Object.keys(data.reduce((acc, i) => {
      i.tractAndForcastedDays.forEach((item) => {
        acc[item.tractName] = true
      })
      return acc
    }, {}))

    const itemData = data.reduce((acc, item) => {
      acc[item[key]] = {}
      availableTracts.forEach((tract) => {
        acc[item[key]][tract] = {
          tract: tract,
          daysRemaining: 0
        }
      })
      return acc
    }, {})

    data.forEach(item => {
      item.tractAndForcastedDays.forEach(tfd => {
        itemData[item[key]][tfd.tractName] = tfd
      })
    })

    const dataSets = availableTracts.reduce((acc, tract, index) => {
      acc[index] = {
        label: tract,
        loadData: []
      }
      for (const item of Object.values(itemData)) {
        acc[index].loadData.push(item[tract].forecastedDaysRemaining ?? 0)
      }
      return acc
    }, [])
    const labels = Object.keys(itemData)

    this.elementId = `${key}-${uuidv4()}`
    this.data = data
    this.key = key
    this.type = type
    this.dataSets = {
      labels: labels,
      sets: dataSets
    }
  }
}

export class StackedBarChartForDefectsByAccount {
  constructor (data, key, type) {
    const byLogger = data.itemizedDefectsByLogger
    const byAccount = data.itemizedDefectsByContractAccount
    const totalDefectWeight = data.totalItemizedDefectPounds
    const totalDefectCount = data.totalItemizedDefectCount

    this.toolTipTextFields = [
      'defectName',
      'quantity',
      'totalPounds',
      'countTotalsByAccount',
      'weightTotalsByAccount'
    ]
    const toolTipTextFieldCount = this.toolTipTextFields.length

    const availableDefectsByAccount = this.getAvailableDefects(byAccount)
    const availableDefectsByLogger = this.getAvailableDefects(byLogger)

    const itemAccountData = this.getItemGroupData(byAccount, availableDefectsByAccount, key)
    const itemLoggerData = this.getItemGroupData(byLogger, availableDefectsByLogger, key)

    this.countTotalsByAccount = this.getCountTotals(byAccount)
    this.weightTotalsByAccount = this.getWeightTotals(byAccount)
    this.countTotalsByLogger = this.getCountTotals(byLogger)
    this.weightTotalsByLogger = this.getWeightTotals(byLogger)

    const accountDataSets = this.getDataSetsForGroup(byAccount, availableDefectsByAccount, itemAccountData, this.weightTotalsByAccount, this.countTotalsByAccount)
    const loggerDataSets = this.getDataSetsForGroup(byLogger, availableDefectsByLogger, itemLoggerData, this.weightTotalsByLogger, this.countTotalsByLogger)

    // Mismanufacture Only (MMO)
    const byAccountMMO = this.getMismanufactureOnly(byAccount)
    const byLoggerMMO = this.getMismanufactureOnly(byLogger)

    const availableDefectsByAccountMMO = this.getAvailableDefects(byAccountMMO)
    const availableDefectsByLoggerMMO = this.getAvailableDefects(byLoggerMMO)

    const itemAccountDataMMO = this.getItemGroupData(byAccountMMO, availableDefectsByAccountMMO, key)
    const itemLoggerDataMMO = this.getItemGroupData(byLoggerMMO, availableDefectsByLoggerMMO, key)

    this.countTotalsByAccountMMO = this.getCountTotals(byAccountMMO)
    this.weightTotalsByAccountMMO = this.getWeightTotals(byAccountMMO)
    this.countTotalsByLoggerMMO = this.getCountTotals(byLoggerMMO)
    this.weightTotalsByLoggerMMO = this.getWeightTotals(byLoggerMMO)

    const accountDataSetsMMO = this.getDataSetsForGroup(byAccountMMO, availableDefectsByAccountMMO, itemAccountDataMMO, this.weightTotalsByAccountMMO, this.countTotalsByAccountMMO)
    const loggerDataSetsMMO = this.getDataSetsForGroup(byLoggerMMO, availableDefectsByLoggerMMO, itemLoggerDataMMO, this.weightTotalsByLoggerMMO, this.countTotalsByLoggerMMO)

    const loggerLabels = Object.keys(itemLoggerData)
    const accountLabels = Object.keys(itemAccountData)
    const loggerLabelsMMO = Object.keys(itemLoggerDataMMO)
    const accountLabelsMMO = Object.keys(itemAccountDataMMO)
    this.elementId = `${key}-${uuidv4()}`
    this.data = [byLogger, byAccount]
    this.dataMMO = [byLoggerMMO, byAccountMMO]
    this.totalDefectWeightPounds = totalDefectWeight
    this.totalDefectCount = totalDefectCount
    this.key = key
    this.type = type
    this.dataSets = {
      accountLabels: accountLabels,
      loggerLabels: loggerLabels,
      sets: [loggerDataSets, accountDataSets],
      setLength: toolTipTextFieldCount
    }
    this.dataSetsMMO = {
      accountLabels: accountLabelsMMO,
      loggerLabels: loggerLabelsMMO,
      sets: [loggerDataSetsMMO, accountDataSetsMMO],
      setLength: toolTipTextFieldCount
    }
  }

  getAvailableDefects (defectsByGroup) {
    return Object.values(defectsByGroup.reduce((acc, i) => {
      i.itemizedDefects.forEach((item) => {
        acc[item.defectName] = item
      })
      return acc
    }, {}))
  }

  getItemGroupData (defectsByGroup, availableDefects, key) {
    const itemGroupData = defectsByGroup.reduce((acc, item) => {
      acc[item[key]] = {}
      availableDefects.forEach((defectInfo) => {
        acc[item[key]][defectInfo.defectName] = {
          defect: defectInfo.defectName,
          loadCount: 0
        }
      })
      return acc
    }, {})
    defectsByGroup.forEach(item => {
      item.itemizedDefects.forEach(itd => {
        itemGroupData[item[key]][itd.defectName] = itd
      })
    })
    return itemGroupData
  }

  getCountTotals (defectsByGroup) {
    return defectsByGroup.map(account =>
      account.itemizedDefects.reduce((acc, defect) => acc + defect.quantity, 0)
    )
  }

  getWeightTotals (defectsByGroup) {
    return defectsByGroup.map(account =>
      account.itemizedDefects.reduce((acc, defect) => acc + defect.totalPounds, 0)
    )
  }

  getMismanufactureOnly (defectsByGroup) {
    return defectsByGroup
      .map(account => ({
        ...account,
        itemizedDefects: account.itemizedDefects
          .filter(d => d.defectCategory === DefectCategory.Mismanufactured.value)
      }))
      .filter(account => account.itemizedDefects.length > 0)
  }

  getDataSetsForGroup (defectsByGroup, availableDefects, itemGroupData, weightTotalsByGroup, countTotalsByGroup) {
    return availableDefects.reduce((acc, defectInfoObject, index) => {
      acc[index] = {
        label: [],
        loadData: [],
        countData: [],
        defectPercentData: []
      }
      Object.values(itemGroupData).forEach((item, accountIndex) => {
        acc[index].label.push(
          ...this.toolTipTextFields.map(field => {
            switch (field) {
              case 'defectName': return item[defectInfoObject.defectName].defectName
              case 'quantity': return item[defectInfoObject.defectName].quantity
              case 'totalPounds': return item[defectInfoObject.defectName].totalPounds
              case 'countTotalsByAccount': return countTotalsByGroup[accountIndex]
              case 'weightTotalsByAccount': return weightTotalsByGroup[accountIndex]
            }
          })
        )
        const totalTons = weightTotalsByGroup[accountIndex] !== 0
          ? item[defectInfoObject.defectName].totalPounds / 2000
          : 0
        acc[index].loadData.push(totalTons)
        const defectPercentage = weightTotalsByGroup[accountIndex] !== 0
          ? item[defectInfoObject.defectName].totalPounds / defectsByGroup[accountIndex].grossWeight * 100
          : 0
        acc[index].defectPercentData.push(defectPercentage)
      })
      return acc
    }, [])
  }

  generateCSVForLoggerData (destinationName = '', groupByDefect) {
    const headers = getHeaders(`${this.type}${!groupByDefect ? 'NoDefectGrouping' : ''}`)
    const csvRows = []
    const loggerData = this.data[0]
    loggerData.forEach((defectInfo, loggerIndex) => {
      const name = defectInfo.accountName
      if (groupByDefect) {
        defectInfo.itemizedDefects.forEach(itemizedDefectSummary => {
          const row = {
            accountName: name,
            defectName: itemizedDefectSummary.defectName,
            defectCategory: DefectCategory.fromInt(itemizedDefectSummary.defectCategory),
            defectWeight: itemizedDefectSummary.totalPounds / 2000,
            percentWeight: this.weightTotalsByLogger[loggerIndex] !== 0
              ? ((itemizedDefectSummary.totalPounds / this.weightTotalsByLogger[loggerIndex]) * 100).toFixed(2)
              : 0,
            defectCount: itemizedDefectSummary.quantity,
            percentCount: this.countTotalsByLogger[loggerIndex] !== 0
              ? Math.round(itemizedDefectSummary.quantity / this.countTotalsByLogger[loggerIndex] * 100)
              : 0
          }
          csvRows.push(formatRowForType(this.type, row, defectInfo))
        })
      } else {
        const totalPounds = defectInfo.itemizedDefects.reduce((acc, curr) => {
          return acc + curr.totalPounds
        }, 0)
        csvRows.push({
          accountName: name,
          defectWeight: totalPounds / 2000,
          defectPercent: (totalPounds / loggerData.find(i => i.accountName === name).grossWeight * 100).toFixed(3),
          grossTons: loggerData.find(i => i.accountName === name).grossWeight / 2000
        })
      }
    })
    const destFileNamePart = destinationName !== '' ? `-${destinationName.replaceAll(' ', '')}` : ''
    exportCSVFile(headers, csvRows, `Defect-Info-By-Logger-For${destFileNamePart}`)
  }

  generateCSVForAccountData (destinationName = '', groupByDefect) {
    const headers = getHeaders(`${this.type}${!groupByDefect ? 'NoDefectGrouping' : ''}`)

    const csvRows = []

    const accountData = this.data[1]

    accountData.forEach((defectInfo, accountIndex) => {
      const accountName = defectInfo.accountName
      if (groupByDefect) {
        defectInfo.itemizedDefects.forEach(itemizedDefectSummary => {
          const row = {
            accountName: accountName,
            defectName: itemizedDefectSummary.defectName,
            defectCategory: DefectCategory.fromInt(itemizedDefectSummary.defectCategory),
            defectWeight: itemizedDefectSummary.totalPounds / 2000,
            percentWeight: this.weightTotalsByAccount[accountIndex] !== 0
              ? ((itemizedDefectSummary.totalPounds / this.weightTotalsByAccount[accountIndex]) * 100).toFixed(2)
              : 0,
            defectCount: itemizedDefectSummary.quantity,
            percentCount: this.countTotalsByAccount[accountIndex] !== 0
              ? Math.round(itemizedDefectSummary.quantity / this.countTotalsByAccount[accountIndex] * 100)
              : 0
          }
          csvRows.push(formatRowForType(this.type, row, defectInfo))
        })
      } else {
        const totalPounds = defectInfo.itemizedDefects.reduce((acc, curr) => {
          return acc + curr.totalPounds
        }, 0)
        csvRows.push({
          accountName: accountName,
          defectWeight: totalPounds / 2000,
          defectPercent: (totalPounds / accountData.find(i => i.accountName === accountName).grossWeight * 100).toFixed(3),
          grossTons: accountData.find(i => i.accountName === accountName).grossWeight / 2000
        })
      }
    })
    const destFileNamePart = destinationName !== '' ? `-${destinationName.replaceAll(' ', '')}` : ''
    exportCSVFile(headers, csvRows, `Defect-Info-By-Account-For${destFileNamePart}`)
  }
}

export class StackedBarChartForItemizedDefectSummary {
  constructor (data, key, type, _totalWeight = 0, _totalInstances = 0) {
    const chartArray = this.getChartArray(data)
    const dataSets = this.getDataSets(chartArray)
    this.pushDataToChartArray(dataSets, chartArray)

    // Mismanufactured Only (MMO)
    const dataMMO = data.filter(d => d.defectCategory === DefectCategory.Mismanufactured.value)
    const chartArrayMMO = this.getChartArray(dataMMO)
    const dataSetsMMO = this.getDataSets(chartArrayMMO)
    this.pushDataToChartArray(dataSetsMMO, chartArrayMMO)

    this.elementId = `${key}-${uuidv4()}`
    this.data = data
    this.dataMMO = dataMMO
    this.key = key
    this.type = type
    this.dataSets = {
      labels: dataSets.map(i => i.label[0]),
      sets: dataSets
    }
    this.dataSetsMMO = {
      labels: dataSetsMMO.map(i => i.label[0]),
      sets: dataSetsMMO
    }
  }

  pushDataToChartArray (dataSets, chartArray) {
    chartArray.forEach((dataPoint, index) => {
      dataSets[index].tonData.push(dataPoint.weightPercentage * 100)
      dataSets[index].loadData.push(dataPoint.quantityPercentage * 100)
      dataSets[index].ticketIds.push(dataPoint.ticketIds)
    })
  }

  getDataSets (chartArray) {
    return chartArray.reduce((acc, defect, index) => {
      acc[index] = {
        label: [
          defect.defectName,
          Math.round(defect.weightPercentage * 100) + '%',
          defect.totalPounds / 2000,
          defect.defectCount,
          Math.round(defect.quantityPercentage * 100) + '%'
        ],
        loadData: [],
        tonData: [],
        ticketIds: []
      }
      return acc
    }, [])
  }

  getChartArray (data) {
    const chartArray = []
    let totalWeightPounds = 0
    let totalQuantity = 0
    data.forEach((dataPoint, index) => {
      totalWeightPounds = totalWeightPounds + dataPoint.totalPounds
      totalQuantity = totalQuantity + dataPoint.quantity
      chartArray[index] = true
    })
    data.forEach((dataPoint, index) => {
      chartArray[index] = {
        defectName: dataPoint.defectName,
        totalPounds: dataPoint.totalPounds,
        weightPercentage: dataPoint.totalPounds / totalWeightPounds,
        defectCount: dataPoint.quantity,
        quantityPercentage: dataPoint.quantity / totalQuantity,
        ticketIds: dataPoint.ticketIds
      }
    })
    return chartArray
  }

  generateCSV (destinationName = '') {
    const totalWeight = sumBy(this.data, 'totalPounds')
    const totalInstances = sumBy(this.data, 'quantity')

    const headers = getHeaders(this.type)

    const csvRows = []

    this.data.forEach((item) => {
      const defectName = item.defectName
      const weightPercentage = (item.totalPounds / 2000) / (totalWeight / 2000)
      const row = {
        defectName: defectName,
        defectCategory: DefectCategory.fromInt(item.defectCategory),
        weight: item.totalPounds / 2000,
        weightPercentage: weightPercentage * 100,
        count: item.quantity,
        percentageByCount: (item.quantity / totalInstances) * 100
      }

      csvRows.push(formatRowForType(this.type, row, item))
    })
    const destFileNamePart = destinationName !== '' ? `-${destinationName.replaceAll(' ', '')}` : ''
    exportCSVFile(headers, csvRows, `Defect-Info-By-Defect-For${destFileNamePart}`)
  }
}

const formatRowForType = (type, row, parentItem, options = {
  includeLoadCount: true,
  includeWeight: true
}) => {
  if (options.includeLoadCount === false) {
    row.loadCount = undefined
  }

  if (options.includeWeight === false) {
    row.pounds = undefined
    row.tons = undefined
  }

  switch (type) {
    case 'account':
      row = {
        account: parentItem.accountName,
        ...row
      }
      break
    case 'tract':
      row = {
        tract: parentItem.tractName,
        tractType: parentItem.tractType,
        ...row
      }
      break
    case 'tractType':
      row = {
        tractType: parentItem.tractType,
        ...row
      }
      break
    case 'destination':
      row = {
        account: parentItem.accountName,
        ...row
      }
      break
    case 'landOwner':
      row = {
        account: parentItem.landOwnerName ?? i18n.t('notAvailable'),
        ...row
      }
      break
    case 'region':
      const region = parentItem.region.trim()
      const state = region.slice(region.length - 2).toUpperCase()
      const county = region.slice(0, region.length - 2).trim()
      row = {
        county: county,
        state: state,
        ...row
      }
      break
    case 'driver':
      row = {
        account: parentItem.accountName,
        ...row
      }
      break
    case 'LoadsInTransitByDestination':
      row = {
        ...row
      }
      break
    case 'CreatedLoadsByDestination':
      row = {
        account: parentItem.accountName,
        ...row,
        tons: undefined,
        pounds: undefined
      }
      break
    case 'logger':
      row = {
        account: parentItem.accountName ?? i18n.t('notAvailable'),
        ...row
      }
      break
    case 'defectSummary':
      row = {
        ...row
      }
      break
    case 'itemizedDefect':
      row = {
        ...row
      }
      break
    default:
      throw new Error('Unsupported chart type.' + this.type)
  }

  return row
}

const getHeaders = (type, csvOptions = {
  includeLoadCount: true,
  includeWeight: true
}) => {
  if (type === 'CreatedLoadsByDestination') return [i18n.t('destination'), i18n.t('product'), i18n.t('loadCount')]
  let headers = [i18n.t(type === 'driver' ? 'destination' : 'product')]
  if (csvOptions.includeLoadCount) headers.push(i18n.t('loadCount'))
  if (csvOptions.includeWeight) {
    headers.push(i18n.t('pounds'))
    headers.push(i18n.t('tons'))
  }

  switch (type) {
    case 'account':
      headers.unshift(i18n.t('account'))
      break
    case 'tract':
      headers.unshift(i18n.t('tract'), i18n.t('tractType'))
      break
    case 'destination':
      headers.unshift(i18n.t('destination'))
      break
    case 'region':
      headers.unshift(i18n.t('county'), i18n.t('state'))
      break
    case 'LoadsInTransitByDestination':
      headers = [i18n.t('destination'), i18n.t('tract'), i18n.t('loadCount')]
      break
    case 'logger':
      headers.unshift(i18n.t('logger'))
      break
    case 'defectSummary':
      headers = [i18n.t('account'), i18n.t('defect'), i18n.t('category'), i18n.t('tons'), '% ' + i18n.t('tons'), i18n.t('quantity'), '% ' + i18n.t('quantity')]
      break
    case 'defectSummaryNoDefectGrouping':
      headers = [i18n.t('account'), i18n.t('defectTons'), '% ' + i18n.t('defectTons'), i18n.t('grossTons')]
      break
    case 'itemizedDefect':
      headers = [i18n.t('defect'), i18n.t('category'), i18n.t('tons'), '% ' + i18n.t('tons'), i18n.t('quantity'), '% ' + i18n.t('quantity')]
      break
    case 'tractType':
      headers.unshift(i18n.t('tractType'))
      break
    case 'landOwner':
      headers = [i18n.t('landOwner'), i18n.t('tract'), i18n.t('loadCount'), i18n.t('pounds'), i18n.t('tons')]
      break
    case 'driver':
      headers.unshift(i18n.t('driver'))
      break
    default:
      throw new Error('Unsupported chart type.' + type)
  }
  return headers
}
