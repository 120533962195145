<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <span class="subtitle-1">
            {{ $t('insurancePolicies') }}
          </span>
          <Icon
          icon="mdi-plus"
          :small="false"
          v-if="insurancePolicies.length < 3"
          :tooltipText="$t('addInsurancePolicy')"
          tabindex="0"
          @icon-clicked="addInsurancePolicy()"
          elementId="add-insurance-policy"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="(insurancePolicy, index) in orderedInsurancePolicies" :key="`policy-${index}`" cols="12" sm="6" md="4">
          <InsuranceCard
          :insurancePolicy="insurancePolicy"
          @deletePolicy="deletePolicy"
          @editPolicy="editPolicy"
          :file="getFileByPolicy(insurancePolicy)"/>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="insuranceFormDialog" max-width="60%">
      <InsuranceForm
      v-if="insuranceFormDialog"
      :propInsurance="focusedPolicy"
      :file="getFileByPolicy(focusedPolicy)"
      @close="closeForm()"
      @policy-created="policyCreated"
      @policy-edited="policyEdited"
      @file-edited="fileEdited"
      :isAccountCreation="!this.accountId"/>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment'
import { accountFilesClient } from '@/utils/AccountFiles'

export default {
  name: 'InsurancePolicies',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    InsuranceForm: () => import('@/components/account/Insurance/InsuranceForm.vue'),
    InsuranceCard: () => import('@/components/account/Insurance/InsuranceCard.vue')
  },

  props: {
    insurancePolicies: {
      type: Array,
      required: true
    },
    files: {
      type: Array,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    insuranceFormDialog: false,
    focusedPolicy: undefined,
    accountId: undefined
  }),

  created () {
    this.accountId = this.insurancePolicies?.find(p => p.accountId != null)?.accountId
  },

  watch: {
    insuranceFormDialog (val) {
      if (val === false) {
        this.focusedPolicy = undefined
      }
    }
  },

  computed: {
    orderedInsurancePolicies () {
      return [...this.insurancePolicies].sort((a, b) => {
        const aDate = moment.utc(a.expirationDate)
        const bDate = moment.utc(b.expirationDate)
        return (moment.duration(aDate.diff(bDate)).asDays())
      })
    }
  },

  methods: {
    addInsurancePolicy () {
      this.insuranceFormDialog = true
    },

    policyCreated (policy) {
      this.closeForm()
      this.$emit('policy-created', policy)
    },

    async deletePolicy (policy) {
      const onSuccessfulDelete = async () => {
        const fileRef = this.getFileByPolicy(policy)?.ref
        if (fileRef) {
          await accountFilesClient.deleteFile(fileRef)
        }
      }
      this.$emit('policy-deleted', policy, onSuccessfulDelete)
    },

    editPolicy (policy) {
      this.insuranceFormDialog = true
      this.focusedPolicy = policy
    },

    policyEdited (policy) {
      const index = this.findPolicyWithInfo(this.focusedPolicy)
      this.$emit('policy-edited', {
        policy: policy,
        index: index
      })
      this.closeForm()
    },

    findPolicyWithInfo (policy) {
      return this.insurancePolicies.findIndex(p => {
        return p.name === policy.name &&
        p.expirationDate === policy.expirationDate
      })
    },

    closeForm () {
      this.insuranceFormDialog = false
      this.focusedPolicy = undefined
    },

    fileEdited () {
      this.$emit('file-edited')
    },

    getFileByPolicy (policy) {
      return this.files?.find(f => Number(f?.policyId) === policy?.accountInsurancePolicyId)
    }
  }
}
</script>
