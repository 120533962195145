<template>
  <div>
    <v-container fluid v-if="tractDestinations.length > 0">
      <v-row>
        <v-col cols="auto">
          <span class="title">
            {{$t('tractDestinations')}}
          </span>
        </v-col>
        <v-col cols="auto">
          <Icon
          :small="false"
          icon="mdi-plus"
          iconColor="black"
          dataTestId="tract-destination-add"
          :tooltipText="$t('newTractDestination')"
          :disabled="!userAssignedClaim(UserClaims.ContractManager)"
          @icon-clicked="openTDForm(undefined)"
          />
        </v-col>
      </v-row>
      <v-row dense >
        <v-col
        v-for="tractDestination in tractDestinations"
        :key="tractDestination.tractDestinationId"
        cols="12" xs="12" sm="12" md="6" lg="4">
          <DestinationCard
          :tractDestination="tractDestination"
          @edit-destination="openTDForm(tractDestination)"
          @delete-destination="confirmDeleteDestination(tractDestination)"
          @new-contract-pressed="openContractForm(tractDestination)"
          />
        </v-col>
      </v-row>
      <v-dialog v-model="confirmDeleteDialog" width="400">
        <ConfirmDialog
        v-if="focusedTractDestination"
        :title="$t('deleteTractDestination', { name: focusedTractDestination.destinationAccountName })"
        :body="$t('deleteTractDestinationBody')"
        @confirm="deleteConmfirmed"
        @cancel="confirmDeleteDialog = false"/>
      </v-dialog>
    </v-container>
    <v-container fluid v-else>
      <v-row align="center" justify="center" class="mt-6">
        <p class="title">
          {{$t('noTractDestinations')}}
        </p>
      </v-row>
      <v-row align="center" justify="center">
        <v-btn text color="primary" @click="openTDForm(undefined)" :disabled="!userAssignedClaim(UserClaims.ContractManager)">
          {{$t('addNew')}}
        </v-btn>
      </v-row>
    </v-container>
    <Dialog
    :stateId="dialogId"
    hideRefresh>
      <TractDestinationForm
      v-if="tractDestinationForm"
      :propTractDestination="focusedTractDestination"
      :allTractDestinations="tractDestinations"
      :tract="tract"
      @close="closeForm(true)"
      @create-contract="createContractFromDestination"
      />
      <TractContractForm
      v-if="newContractDialog && focusedTractDestination"
      :tract="tract"
      :tractDestination="focusedTractDestination"
      :landowners="landowners"
      @close="closeForm(true)"/>
    </Dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { UserClaims, TractTypeCategory } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
export default {
  name: 'Operational',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    TractDestinationForm: () => import('@/components/tract/tract-form/TractDestinationForm.vue'),
    DestinationCard: () => import('@/components/tract/tract-form/DestinationCard'),
    TractContractForm: () => import('@/components/tract/tract-form/TractContractForm.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    Dialog: () => import('@/components/Dialog.vue')
  },

  props: {
    tract: {
      type: Object,
      required: true
    },
    landowners: {
      type: Array,
      default: () => []
    },
    refreshTractDestinations: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    loading: false,
    tractDestinationForm: false,
    dialogId: 'tract-operational-dialog',
    confirmDeleteDialog: false,
    newContractDialog: false,
    tractDestinations: [],
    focusedTractDestination: undefined,
    UserClaims
  }),

  computed: {
    tractId () {
      return this.tract.tractId
    },

    productIds () {
      if (this.tract.type.category === TractTypeCategory.Stumpage.value) {
        return this.tract.tractContents.map(cd => cd.productId)
      }
      return null
    }
  },

  watch: {
    refreshTractDestinations (val) {
      if (val) {
        this.refresh(this.tractId)
      }
    }
  },

  created () {
    this.refresh(this.tractId)
  },

  methods: {
    ...mapActions('tract-destination', ['fetchTractDestinations', 'deleteTractDestination']),
    ...mapActions('tract', ['fetchContractsForTract']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    userAssignedClaim,

    async refresh (tractId) {
      this.loading = true
      try {
        this.tractDestinations = await this.fetchTractDestinations(tractId)
      } finally {
        this.loading = false
        this.$emit('tract-destinations-refreshed')
      }
    },

    openTDForm (tractDestination) {
      if (this.tract.type.category === TractTypeCategory.Stumpage.value && this.productIds?.length === 0) {
        this.setSnack(this.$t('tractMustHaveProductsToDefineDestination'))
        this.$emit('open-cruise-tab')
        return
      }
      this.closeForm()
      const harvestStartDateNotEntered = this.tract.harvestStartDate === null || this.tract.harvestStartDate === undefined

      if (harvestStartDateNotEntered) {
        this.setSnackError(this.$t('harvestStartDateNotSpecifiedDest'))
        return
      }

      this.focusedTractDestination = tractDestination
      this.tractDestinationForm = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '50vw' })
    },

    confirmDeleteDestination (tractDestination) {
      if (tractDestination.contractCount > 0) {
        this.setSnackError(this.$t('cannotDeleteDestination'))
        return
      }
      this.focusedTractDestination = tractDestination
      this.confirmDeleteDialog = true
    },

    async deleteConmfirmed () {
      await this.deleteTractDestination({ tractId: this.tractId, tractDestinationId: this.focusedTractDestination.tractDestinationId })
      this.confirmDeleteDialog = false
      this.focusedTractDestination = null
      this.refresh(this.tractId)
    },

    closeForm (refresh) {
      this.focusedTractDestination = undefined
      this.focusedTractDestination = undefined
      this.tractDestinationForm = false
      this.newContractDialog = false
      this.closeDialogsAtOrAbove(this.dialogId)

      if (refresh) {
        this.refresh(this.tractId)
        this.$emit('refresh-tract-contracts')
      }
    },

    openContractForm (tractDestination) {
      this.closeForm()
      const harvestStartDateNotEntered = this.tract.harvestStartDate === null || this.tract.harvestStartDate === undefined

      if (this.tract.type.category === 0 && (this.tract.loggers.length === 0 || this.tract.loggers.length === undefined)) {
        this.setSnackError(this.$t('mustSpecifyLoggersOnTract'))
        return
      }

      if (harvestStartDateNotEntered) {
        this.setSnackError(this.$t('harvestStartDateNotSpecified'))
        return
      }

      this.focusedTractDestination = tractDestination
      this.openOrUpdateDialog({ id: this.dialogId, fullscreen: true })
      this.newContractDialog = true
    },

    createContractFromDestination (tractDestination) {
      this.closeForm(true)
      this.tractDestinationForm = false
      this.$nextTick(() => {
        this.openContractForm(tractDestination)
      })
    }
  }
}
</script>
