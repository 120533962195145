<template>
  <span>
    <Icon
    v-for="(action, index) of actionIcons"
    :key="index"
    :icon="action.icon"
    :iconColor="action.color"
    :tooltipText="$t(action.tooltip)"
    :disabled="action.disabled"
    @icon-clicked="action.click()"
    />
    <Dialog :stateId="dialogId"
    :enforcePersistence="editContractDialog && companyInfo.requireApprovalForContractModifications"
    @dialog-closing="close">
      <ContractDetail
      v-if="contractDetailDialog"
      :contractId="contract.contractId"
      @edit-contract="editContract"
      @edit-activities="editContract"
      />
      <EditContract
      v-if="editContractDialog"
      :contractId="contract.contractId"
      shouldAddActivity
      @contract-changed="refreshAndClose('edited')"
      @close="close"
      :companyRequiresEditApproval="companyInfo.requireApprovalForContractModifications"
      :contractMode="contractMode"
      />
    </Dialog>
    <v-dialog v-model="unpostTicketsDialog" max-width="400px">
      <ConfirmDialog
        v-if="unpostTicketsDialog"
        :title="$t('unpostTickets')"
        :confirmText="$t('unpostTickets')"
        :body="unpostTicketsBody"
        @cancel="close"
        @confirm="unpostTicketsFromContract"
        >
        <template #progress-bar>
          <v-row justify="center">
            <v-progress-circular
            :rotate="90"
            :size="100"
            :width="15"
            :value="progressUnposting"
            v-if="totalToUnpost > 0"
            color="primary"
            >
             {{totalUnposted}}/{{totalToUnpost}}
            </v-progress-circular>
          </v-row>
        </template>
      </ConfirmDialog>
    </v-dialog>
  </span>
</template>

<script>
import { uniqueDialogId } from '@/utils/componentHelpers'
import { UserClaims, ContractMode, ContractType } from '../../utils/Enumerations'
import { userAssignedClaim } from '../../utils/ClaimUtility'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SettlementsContractActions',

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    ContractDetail: () => import('@/components/contract/contract-detail/ContractDetail.vue'),
    EditContract: () => import('@/components/contract/ContractEditor.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  props: {
    contract: {
      type: Object,
      required: true
    },
    tickets: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    dialogId: uniqueDialogId('contract-card-actions'),
    contractDetailDialog: false,
    editContractDialog: false,
    unpostTicketsDialog: false,
    totalUnposted: 0,
    totalToUnpost: 0
  }),

  computed: {
    ...mapGetters('user', ['companyInfo']),
    ...mapGetters('settlements', ['dateConfiguration']),
    actionIcons () {
      return [
        {
          icon: 'mdi-altimeter',
          tooltip: 'viewContract',
          color: 'primary',
          testId: 'sbc-contract',
          click: this.viewContractDetail
        },
        {
          icon: 'mdi-pencil',
          tooltip: 'edit',
          color: 'success',
          testId: 'sbc-edit-contract',
          click: this.editContract,
          disabled: !userAssignedClaim(UserClaims.ContractManager)
        },
        {
          icon: 'mdi-briefcase',
          tooltip: 'unpostTickets',
          color: 'error',
          testId: 'unpost-ticket-icon',
          click: this.showUnpostDialog
        }
      ]
    },

    contractMode () {
      switch (this.contract.type) {
        case ContractType.Production.value:
        case ContractType.WoodsSale.value:
        case ContractType.LogYardSale.value:
          return ContractMode.LogYardSale
        case ContractType.ByproductSale.value:
        case ContractType.ByproductPurchase.value:
          return ContractMode.Byproducts
        case ContractType.Transfer.value:
          return ContractMode.Transfers
        default:
          return ContractMode.Logs
      }
    },

    progressUnposting () {
      if (this.totalToUnpost === 0) { return 0 }
      return Math.ceil((this.totalUnposted / this.totalToUnpost) * 100)
    },

    unpostTicketsBody () {
      return this.tickets.length === 1 ? this.$t('unpostOneTicket') : this.$t('unpostXTickets', { x: this.tickets.length })
    }
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('posting', ['unpostTicket']),
    viewContractDetail () {
      this.close()
      this.contractDetailDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80%' })
    },

    editContract () {
      this.close()
      this.editContractDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80%' })
    },

    showUnpostDialog () {
      if (this.dateConfiguration?.exportBatch?.exportBatchId !== undefined) {
        this.setSnackError(this.$t('ticketsCannotBeUnpostedInExportBatch'))
        return
      }
      this.close()
      this.unpostTicketsDialog = true
    },

    async unpostTicketsFromContract () {
      this.totalUnposted = 0
      this.totalToUnpost = this.tickets.length
      for (const ticket of this.tickets) {
        const result = await this.unpostTicket(ticket)
        if (result) this.totalUnposted++
      }

      this.setSnack(this.$t('ticketsUnposted', { successes: this.totalUnposted, failures: this.totalToUnpost - this.totalUnposted }))

      this.totalUnposted = 0
      this.totalToUnpost = 0
      this.refreshAndClose('unposted')
    },

    close () {
      this.closeSubdialogs()
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    closeSubdialogs () {
      this.contractDetailDialog = false
      this.editContractDialog = false
      this.unpostTicketsDialog = false
    },

    refreshAndClose (emission) {
      this.$emit(emission)
      this.close()
    }
  }
}
</script>
