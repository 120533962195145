import { NumericConstants } from './constants'
import i18n from '@/i18n'

export function tonStringFromPounds (x, numberOfDecimals, isMetric = false) {
  const asFloat = parseFloat(x)
  if (isNaN(asFloat) || typeof (asFloat) !== 'number') {
    return '0'
  }

  if (!numberOfDecimals) {
    numberOfDecimals = 3
  }

  const divisor = isMetric ? NumericConstants.MetricTonDivisor : NumericConstants.ShortTonDivisor
  const tons = (x / divisor).toFixed(numberOfDecimals)

  return numberWithCommas(tons, 3)
}

export function numberWithCommas (x, numberOfDecimals) {
  const asFloat = parseFloat(x)
  if (isNaN(asFloat) || typeof (asFloat) !== 'number') {
    return '0'
  }

  const toFixed = asFloat.toFixed(numberOfDecimals || 0)

  return toFixed.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatMoney (value) {
  if (isNaN(value) || typeof (value) !== 'number') {
    return '$0.00'
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencySign: 'accounting',
    minimumFractionDigits: 2
  })

  return formatter.format(value)
}

export function getFormattedTons (weight) {
  const tons = (weight / 2000).toFixed(3)
  return numberWithCommas(tons, 3)
}

export const uuidv4 = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))

export function getFormattedCoord (coordinate) {
  return parseFloat(getFormattedCoordWithTrailingZeroes(coordinate))
}

export function getFormattedCoordWithTrailingZeroes (coordinate) {
  return parseFloat(coordinate).toFixed(6)
}

export function formatPercentage (percent) {
  const asFloat = parseFloat(percent)
  if (isNaN(asFloat) || typeof (asFloat) !== 'number') {
    return '0%'
  }
  return `${asFloat.toFixed(2)}%`
}

export function convertToPercentage (quotient, decimalPlaces, floor = false) {
  const asFloat = parseFloat(quotient)
  if (isNaN(asFloat) || typeof (asFloat) !== 'number') {
    return i18n.$t('notApplicable')
  }

  return `${floor ? Math.floor(asFloat * 100) : (asFloat * 100).toFixed(decimalPlaces)}%`
}
