<template>
  <v-dialog
    v-if="beginApprovalBasedEditDialog"
    v-model="beginApprovalBasedEditDialog"
    persistent
    width="400px"
  >
    <ConfirmDialog
      :title="confirmDialogConfig.title"
      :body="confirmDialogConfig.body"
      color="primary"
      :confirmText="confirmDialogConfig.confirmText"
      :canCancel="confirmDialogConfig.canCancel"
      @confirm="confirmDialogConfig.confirmAction"
      @cancel="confirmDialogConfig.cancelAction"
    />
  </v-dialog>
  <v-card v-else-if="beginApprovalBasedEditDialog === false">
    <input
      ref="uploader"
      class="d-none"
      type="file"
      @change="fileChanged"/>
    <v-card-title :class="contractColor">
      <span class="headline mr-4 white--text">{{title}}</span>
      <ActivityFormContractHeader v-if="activeTab === 1" :contract="contract" />
      <v-spacer />
      <v-menu bottom left v-if="destinationAccount?.isExternal">
        <template #activator="{on}">
          <v-icon v-on="on" color="tertiary">mdi-dots-vertical</v-icon>
        </template>
        <v-list>
          <v-list-item v-for="(ha, index) in headerActions" :key="`${ha.text}-${index}`" @click="ha.action()" :disabled="ha.disabled" :id="`contract-edit-context-menu-${kebabCase(ha.text)}`">
            <v-avatar left>
              <v-icon color="black" :disabled="ha.disabled">{{ ha.icon }}</v-icon>
            </v-avatar>
            <v-list-item-title class="subtitle-1">{{ $t(ha.text) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <BaseDialogActions
        :hideCancel="contractRequiresEditApproval"
        hideRefresh
      />
    </v-card-title>
    <v-card-text>
      <v-container fill-height fluid v-if="loading">
        <v-row align="center" justify="center">
          <v-progress-circular
          indeterminate
          color="primary"
          />
        </v-row>
      </v-container>
      <v-tabs
        v-if="!loading"
        v-model="activeTab"
        class="mt-4"
      >
        <v-tab data-testid="contract-info-tab">{{ $t("contractInfo") }}</v-tab>
        <v-tab data-testid="activities-tab" :key="activitiesTabKey">
          <span>
            {{ $t("activities") }}
            <Icon
            v-if="activityIssue"
            icon="mdi-alert-circle"
            iconColor="error"
            :small="true"
            :tooltipText="activityIssue"/>
          </span>
        </v-tab>
        <v-tab-item disabled>
        <FormWrapper
          class="pb-1"
          v-show="activeTab === 0"
          testId="save-contract"
          strongValidation
          :buttonText="contractRequiresEditApproval ? $t('finishEditingContract') : undefined"
          :disabled="isButtonDisabled"
          :buttonColor="contractColor"
          @submit="trySave"
        >
          <v-container
            fluid
            class="mt-10"
            data-testid="contract-form"
          >
            <v-row dense>
              <v-col v-if="contractHasTract" cols="12" lg="6">
                <TractAutocomplete
                  :propTractId="contract.tractId"
                  @tract-chosen="tractChosen"
                  data-testid="contract-tract"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <AccountAutocomplete
                  userSetting="contractDestinationAccount"
                  :accountId="contract.destinationAccountId"
                  :title="$t('destinationAccount')"
                  @account-chosen="destinationChosen"
                  :onlyInternal="contractDestinationMustBeInternal"
                  :onlyExternal="contractDestinationMustBeExternal"
                  data-testid="contract-destination"
                  :fetchTagsAndAccounts="false"
                  :propAccounts="tractDestinations ?? undefined"
                  :propLoading="tractDestinationsLoading"
                  :disabled="tractDestinations?.length === 0"
                  :hint="tractDestinations?.length === 0 ? $t('noTractDestinations') : undefined"
                  :noSelection="tractDestinations?.length === 0"
                /> <!-- "No tract destinations" is a safeguard, it shouldn't happen in practice -->
              </v-col>
              <v-col cols="12" lg="6">
                <AccountAutocomplete
                  :accountId="contract.accountId"
                  @account-chosen="accountChosen"
                  userSetting="contractAccount"
                  data-testid="contract-account"
                  showCertified
                  :fetchTagsAndAccounts="false"
                />
              </v-col>
              <v-col v-if="contractHasFromAccount" cols="12" lg="6">
                <AccountAutocomplete
                  :onlyInternal="!isByproductPurchase"
                  :onlyExternal="isByproductPurchase"
                  :accountId="contract.fromAccountId"
                  :title="$t('fromAccount')"
                  @account-chosen="fromAccountChosen"
                  data-testid="contract-from-account"
                  :fetchTagsAndAccounts="false"
                />
              </v-col>
              <v-col v-if="contractMode.value === ContractMode.Logs.value" cols="12" lg="6">
                <SettingAutocomplete
                  :settingId="contract.settingId"
                  @setting-chosen="settingChosen"
                  data-testid="contract-setting"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col lg="12">
                <ContractInfo
                  :propContractInfo="contractInformation"
                  :editing="true"
                  :contractMode="contractMode"
                  :hideDraft="!currentDraftMode && companyRequiresEditApproval"
                  @contract-info-changed="contractInfoChanged"
                  @is-button-disabled="(val) => isButtonDisabled = val"
                  :contractType="currentContractType"
                />
              </v-col>
            </v-row>
          </v-container>
          <template #left-action>
            <v-btn text color="black" @click="refreshContract(contractId)">{{$t('reset')}}</v-btn>
          </template>
          </FormWrapper>
        </v-tab-item>
        <v-tab-item disabled>
          <Activities
            :contract="contract"
            :showFinishButton="contractRequiresEditApproval"
            @refreshed="triggerActivitiesTabChange"
            @finish-editing="trySave"
          />
        </v-tab-item>
        <v-tab-item disabled>
          <GatePass/>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <v-dialog
      v-if="confirmSubmitDialog"
      v-model="confirmSubmitDialog"
      width="400px"
    >
      <ConfirmDialog
        :title="$t('confirmSubmitContractTitle')"
        :body="this.currentDraftMode ? $t('confirmSubmitDraftContractMessage') : $t('confirmSubmitContractMessage')"
        color="primary"
        :confirmText="$t('submit')"
        @confirm="save"
        @cancel="confirmSubmitDialog = false"
      >
        <template #custom-body>
          <v-textarea
          v-model="contractApprovalNote"
          :label="$t('note')"
          outlined
          auto-grow
          counter
          maxlength="1024"
          clearable
          dense
          color="black"
          />
        </template>
      </ConfirmDialog>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { utcToLocalDate, localToUTC } from '@/utils/DateFormatter.js'
import { gatePassClient } from '@/utils/GatePass'
import { saveAs } from 'file-saver'
import { colorClassForContractType } from '@/utils/componentHelpers.js'
import { ContractApprovalStatus, ContractType, ContractMode, TemplateSpecialization } from '@/utils/Enumerations.js'
import { AccountingCategory } from '@/utils/Enumerations'
import { kebabCase } from 'lodash'

export default {
  name: 'ContractEditor',

  components: {
    ContractInfo: () => import('./ContractInfo'),
    Activities: () => import('../activity/Activities'),
    TractAutocomplete: () => import('../autocomplete/TractAutocomplete.vue'),
    SettingAutocomplete: () => import('../autocomplete/ContractSettingAutocomplete.vue'),
    AccountAutocomplete: () => import('../autocomplete/AccountAutocomplete.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    ActivityFormContractHeader: () => import('@/components/activity/ActivityFormContractHeader.vue'),
    Icon: () => import('../helper/Icon.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  props: {
    contractId: { type: Number, required: true },
    shouldAddActivity: { type: Boolean, default: false },
    isNested: { type: Boolean, default: false },
    contractMode: { type: Object, required: true },
    companyRequiresEditApproval: { type: Boolean, required: true },
    currentDraftMode: { type: Boolean, required: false, default: false }
  },

  data: () => ({
    ContractMode,
    loading: true,
    activeTab: 0,
    isButtonDisabled: false,
    activitiesTabKey: 0,
    destinationAccount: null,
    contractInformation: {
      effectiveDate: '',
      expirationDate: '',
      acceptsAnyLoad: false,
      requiresLogger: false,
      requiresExt1: false,
      requiresExt2: false,
      isDraft: false,
      paused: false,
      type: '',
      distance: 0,
      tareWeight: 0,
      allowPickupLoads: false
    },
    contract: undefined,
    updatedContract: {
      accountId: null,
      fromAccountId: null,
      tractId: null,
      settingId: null,
      destinationAccountId: null,
      acceptsAnyLoad: false,
      requiresExt1: false,
      requiresExt2: false,
      isDraft: false,
      paused: false,
      type: '',
      distance: 0,
      expirationDate: '',
      effectiveDate: '',
      allowPickupLoads: false
    },
    initContractType: undefined,
    gatePass: undefined,
    isSelecting: false,
    allowedGatePassMimes: undefined,
    approval: undefined,
    confirmSubmitDialog: false,
    contractApprovalId: null,
    beginApprovalBasedEditDialog: null,
    contractApprovalNote: '',
    tractDestinations: undefined,
    tractDestinationsLoading: false
  }),

  computed: {
    ...mapGetters('activity', ['allActivities']),
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('user-settings', ['mutatedUserSettings']),
    ...mapGetters('account', ['allAccounts']),

    contractHasFromAccount () {
      const modesWithFromAccount = [ContractMode.Transfers.value, ContractMode.Byproducts.value, ContractMode.LogYardSale.value]
      return this.isByproductPurchase
        ? !!this.contract.fromAccount
        : modesWithFromAccount.includes(this.contractMode.value)
    },

    contractHasTract () {
      return this.contract.tractId && [
        ContractType.WoodsSale.value,
        ContractType.Production.value,
        ContractType.ByproductPurchase.value
      ].includes(this.currentContractType.value)
    },

    isByproductPurchase () {
      return this.contract.type === ContractType.ByproductPurchase.value
    },

    contractDestinationMustBeInternal () {
      return this.contractMode.value === ContractMode.Transfers.value || this.isByproductPurchase
    },

    contractDestinationMustBeExternal () {
      return this.contractMode.value === ContractMode.LogYardSale.value || this.contract.type === ContractType.ByproductSale.value
    },

    title () {
      switch (this.contractMode.value) {
        case ContractMode.Logs.value:
          return this.$t('editingLogsContract')
        case ContractMode.Byproducts.value:
          return this.$t('editingByproductContract')
        case ContractMode.Transfers.value:
          return this.$t('editingTransferContract')
        case ContractMode.LogYardSale.value:
          return this.$t('editingLogYardSaleContract')
      }

      return ''
    },

    contractColor () {
      return colorClassForContractType(this.contractInformation.type, false)
    },

    headerActions () {
      return [
        {
          text: 'uploadGatePass',
          icon: 'mdi-file-upload-outline',
          action: this.uploadGatePass,
          disabled: this.gatePass !== undefined
        },
        {
          text: 'downloadGatePass',
          icon: 'mdi-file-download-outline',
          action: this.downloadGatePass,
          disabled: this.gatePass === undefined
        },
        {
          text: 'deleteGatePass',
          icon: 'mdi-file-document-remove-outline',
          action: this.deleteGatePass,
          disabled: this.gatePass === undefined
        }
      ]
    },

    someActivityDetailExists () {
      return this.allActivities.some(c => c.activityDetails.length > 0)
    },

    someTransferActivityExists () {
      return this.allActivities.some(a => a.activityTemplate?.specialization === TemplateSpecialization.Transfer.value)
    },

    somePayableActivityExists () {
      return this.allActivities.some(a => a.activityTemplate?.category === AccountingCategory.Payable.value)
    },

    someReceivableActivityExists () {
      return this.allActivities.some(a => a.activityTemplate?.category === AccountingCategory.Receivable.value)
    },

    activityIssue () {
      if (this.contract.type === ContractType.ByproductPurchase.value && !this.somePayableActivityExists) return this.$t('missingAccountsPayableActivity')
      if ((this.contract.type === ContractType.WoodsSale.value || this.contract.type === ContractType.LogYardSale.value) && !this.someReceivableActivityExists) return this.$t('missingAccountsReceivableActivity')
      if (!this.contract.acceptsAnyLoad && !this.someActivityDetailExists) return this.$t('noDetails')
      if (this.contract.type === ContractType.Transfer.value && !this.someTransferActivityExists) return this.$t('missingTransferActivity')
      return ''
    },

    canEditContract () {
      return [ContractApprovalStatus.Approved.value, ContractApprovalStatus.Returned.value, null].includes(this.contract.approvalStatus)
    },

    resumingPreviousModification () {
      return this.contract.approvalStatus === ContractApprovalStatus.PendingModification.value
        ? this.approval.requestedByUserId === this.userInfo.applicationUserId
        : false
    },

    contractRequiresEditApproval () {
      return this.companyRequiresEditApproval && !this.currentDraftMode
    },

    confirmDialogConfig () {
      if (this.resumingPreviousModification) {
        return {
          title: this.$t('resumeEditContractConfirmTitle'),
          body: this.$t('resumeEditContractConfirmMessage'),
          confirmText: this.$t('edit'),
          canCancel: true,
          confirmAction: this.resumeApprovalBasedEdit,
          cancelAction: () => this.$emit('close')
        }
      } else if (this.canEditContract) {
        return {
          title: this.$t('contractEditConfirmTitle'),
          body: this.$t('contractEditConfirmMessage'),
          confirmText: this.$t('edit'),
          canCancel: true,
          confirmAction: this.beginApprovalBasedEdit,
          cancelAction: () => this.$emit('close')
        }
      } else {
        return {
          title: this.$t('cannotEditContractTitle'),
          body: this.$t('cannotEditContractMessage', { status: ContractApprovalStatus.fromInt(this.contract.approvalStatus) }),
          confirmText: this.$t('okay'),
          canCancel: false,
          confirmAction: () => this.$emit('close'),
          cancelAction: () => undefined
        }
      }
    },

    currentContractType () {
      return ContractType.forInt(this.contract?.type)
    }
  },

  async created () {
    await this.refreshContract(this.contractId)
    this.getGatePass()
    this.allowedGatePassMimes = new Set([
      'application/pdf',
      'image/png',
      'image/jpeg',
      'image/heic'
    ])
    if (this.contract.approvalStatus === ContractApprovalStatus.PendingModification.value) {
      this.approval = await this.getMostRecentContractApproval(this.contractId)
    }
    this.getAccounts()
    this.getAccountTags()
    this.beginApprovalBasedEditDialog = this.contractRequiresEditApproval
  },

  beforeDestroy () {
    this.updateUserSettings(this.mutatedUserSettings)
    this.$emit('close')
  },

  methods: {
    kebabCase,
    ...mapActions('activity', ['fetchActivitiesByEntity']),
    ...mapActions('contract', ['updateContract', 'getContract']),
    ...mapActions('contract-approvals', ['createContractApproval', 'updateContractApproval', 'getMostRecentContractApproval']),
    ...mapActions('user-settings', ['updateUserSettings']),
    ...mapMutations('snackbar', ['setSnack']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('tags', ['fetchAccountTags']),
    ...mapActions('tract-destination', ['fetchTractDestinations']),

    async refreshContract (contractId) {
      this.loading = true
      try {
        const res = await this.getContract(contractId)
        this.contract = JSON.parse(JSON.stringify(res))
        this.contractInformation = {
          effectiveDate: utcToLocalDate(this.contract.effectiveDate),
          expirationDate: utcToLocalDate(this.contract.expirationDate),
          acceptsAnyLoad: this.contract.acceptsAnyLoad,
          requiresLogger: this.contract.requiresLogger,
          requiresExt1: this.contract.requiresExt1,
          requiresExt2: this.contract.requiresExt2,
          isDraft: this.contract.isDraft,
          paused: this.contract.paused,
          type: this.contract.type,
          distance: this.contract.distance ? this.contract.distance : 0,
          tareWeight: this.contract.tareWeight ? this.contract.tareWeight : 0,
          allowPickupLoads: this.contract.allowPickupLoads
        }
        this.tractChosen(this.contract)
        this.initContractType = this.selectedContractType
        this.fetchActivitiesByEntity(this.contract.contractId)
        if (this.shouldAddActivity) {
          this.activeTab = 1
        }
      } finally {
        this.loading = false
      }
    },

    async getAccounts () {
      await this.fetchAccounts()
    },

    async getAccountTags () {
      await this.fetchAccountTags()
    },

    contractInfoChanged (contractInfo) {
      this.contractInformation = { ...contractInfo }
    },

    async tractChosen (val) {
      this.updatedContract.tractId = val?.tractId
      if (this.updatedContract.tractId) {
        this.tractDestinationsLoading = true
        const response = await this.fetchTractDestinations(val?.tractId)
        this.tractDestinations = response.map(td => JSON.parse(JSON.stringify(this.allAccounts.find(a => a.accountId === td.destinationAccountId))))
        this.tractDestinationsLoading = false
      }
    },

    accountChosen (val) { this.updatedContract.accountId = val?.accountId },
    fromAccountChosen (val) { this.updatedContract.fromAccountId = val?.accountId },
    settingChosen (val) { this.updatedContract.settingId = val?.settingId },

    destinationChosen (val) {
      this.destinationAccount = val
      this.updatedContract.destinationAccountId = val?.accountId
    },

    trySave () {
      if (this.companyRequiresEditApproval && !this.contractInformation.isDraft) {
        this.confirmSubmitDialog = true
      } else {
        this.save()
      }
    },

    updateApproval () {
      const requestObj = {
        contractApprovalId: this.contractApprovalId,
        contractId: this.contractId,
        status: ContractApprovalStatus.PendingReview.value,
        note: this.contractApprovalNote.trim()?.length > 0 ? { content: this.contractApprovalNote } : null
      }
      this.updateContractApproval(requestObj)
    },

    validateContract (contract) {
      if (!contract.accountId) {
        this.setSnackError(this.$t('accountRequired'))
        return false
      }
      if (!contract.destinationAccountId) {
        this.setSnackError(this.$t('destinationRequired'))
        return false
      }
      if (contract.type === ContractType.ByproductSale.value && !this.destinationAccount?.isExternal) {
        this.setSnackError(this.$t('byproductSaleContractCannotBeInternal'))
        return
      }
      if (this.isByproductPurchase && this.destionationAccount?.isExternal) {
        this.setSnackError(this.$t('byproductPurchaseContractCannotBeInternal'))
        return
      }
      if (this.contractHasFromAccount && !contract.fromAccountId) {
        this.setSnackError(this.$t('fromAccountRequired'))
        return false
      }
      if (this.contractHasTract && !contract.tractId) {
        this.setSnackError(this.$t('tractRequired'))
        return false
      }
      if (this.contractMode.value === ContractMode.Logs.value && !contract.settingId) {
        this.setSnackError(this.$t('settingRequired'))
        return false
      }
      if (contract.distance < 0) {
        this.setSnackError(this.$t('distanceCannotBeNegative'))
        return false
      }
      if (contract.tareWeight < 0) {
        this.setSnackError(this.$t('tareWeightCannotBeNegative'))
        return false
      }
      return true
    },

    async save () {
      const updatedContract = this.getUpdatedContract()

      if (!this.validateContract(updatedContract)) return

      let response = {}
      if (this.updatedContract.accountId && this.updatedContract.destinationAccountId) {
        if (!this.destinationAccount.isExternal && this.gatePass) this.deleteGatePass(this.gatePass)
        response = await this.updateContract(updatedContract)
        this.updatedContract = updatedContract
      } // else we're just editing activities and never opened the contract info tab

      if (response === undefined) {
        this.confirmSubmitDialog = false
        return
      }

      if (this.companyRequiresEditApproval && !this.contractInformation.isDraft && !this.currentDraftMode) {
        this.updateApproval()
      }

      this.$emit('contract-changed')
    },

    getUpdatedContract () {
      const updatedContract = {
        accountId: this.updatedContract.accountId,
        destinationAccountId: this.updatedContract.destinationAccountId,
        fromAccountId: this.updatedContract.fromAccountId,
        effectiveDate: localToUTC(this.contractInformation.effectiveDate),
        expirationDate: localToUTC(this.contractInformation.expirationDate),
        requiresLogger: this.contractInformation.requiresLogger,
        acceptsAnyLoad: this.contractInformation.acceptsAnyLoad,
        requiresExt1: this.contractInformation.requiresExt1,
        requiresExt2: this.contractInformation.requiresExt2,
        isDraft: this.contractInformation.isDraft,
        paused: this.contractInformation.paused,
        type: this.contractInformation.type,
        distance: parseInt(this.contractInformation.distance),
        tractId: this.updatedContract.tractId,
        settingId: this.updatedContract.settingId,
        tareWeight: this.updatedContract.tareWeight,
        contractId: this.contract.contractId,
        allowPickupLoads: this.contractInformation.allowPickupLoads
      }

      if (!this.contractHasTract) updatedContract.tractId = null
      if (this.contractMode.value !== ContractMode.Logs.value) {
        updatedContract.settingId = null
        updatedContract.tareWeight = parseInt(this.contractInformation.tareWeight)
      } else updatedContract.tareWeight = null

      return updatedContract
    },

    triggerActivitiesTabChange () {
      this.activitiesTabKey += (this.activitiesTabKey % 2 !== 0) ? -1 : 1
    },

    uploadGatePass () {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },

    fileChanged (e) {
      this.gatePass = e.target.files[0]
      this.uploadToBlob()
      this.isSelecting = false
      this.$refs.uploader.value = ''
    },

    async uploadToBlob () {
      try {
        if (!this.gatePass) return
        if (!this.validateGatePass(this.gatePass)) return
        await gatePassClient.uploadGatePass(this.contract, this.gatePass)
        this.setSnack(this.$t('gatepassUploadedSuccess'))
      } catch (e) {
        this.setSnackError(this.$t('gatepassUploadError'))
        this.gatePass = undefined
      }
    },

    async downloadGatePass () {
      if (!this.gatePass) return
      if (typeof this.gatePass !== 'object') {
        const fetchedFile = await fetch(this.gatePass)
        const blob = await fetchedFile.blob()
        saveAs(blob, decodeURIComponent(fetchedFile.url.split('?')[0].split('/').pop()))
      } else {
        saveAs(this.gatePass, this.gatePass.name)
      }
    },

    async deleteGatePass () {
      try {
        await this.getGatePass()
        await gatePassClient.deleteGatePass(this.gatePass)
        this.gatePass = undefined
        this.setSnack(this.$t('gatepassDeleteSuccess'))
      } catch (e) {
        this.setSnackError(this.$t('gatepassDeleteError'))
      }
    },

    async getGatePass () {
      try {
        this.gatePass = await gatePassClient.getGatePass(this.contractId)
      } catch (e) {
        console.error(this.$t('gatepassFetchError'))
      }
    },

    validateGatePass (gatePass) {
      const limit = 1000 * 1000 // 1MB
      if (gatePass.size > limit) {
        this.setSnackError(this.$t('fileTooLargeSnack', { limit: '1MB' }))
        this.gatePass = undefined
        return false
      }
      if (!this.allowedGatePassMimes.has(gatePass.type)) {
        this.setSnackError(this.$t('fileMimeNotAllowedSnack'))
        this.gatePass = undefined
        return false
      }
      return true
    },

    async beginApprovalBasedEdit () {
      const response = await this.createContractApproval({ contractId: this.contractId })
      if (!response) return
      this.contractApprovalId = response.contractApprovalId
      this.beginApprovalBasedEditDialog = false
    },

    resumeApprovalBasedEdit () {
      this.contractApprovalId = this.approval.contractApprovalId
      this.beginApprovalBasedEditDialog = false
    }
  }
}
</script>
