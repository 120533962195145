import moment from 'moment'
import store from '@/store'
import i18n from '@/i18n'
const defaultDateFormat = 'MM/DD/YYYY'

export function localToUTC (dateString, exactTime) {
  dateString = dateString.slice(0, 19)

  const timeZoneOffset = store.getters['user/companyInfo'].timeZoneOffset

  const date = moment.utc(dateString)
  if (!exactTime) {
    date.hour(0).minutes(0).seconds(0).milliseconds(0)
  }

  const dateOffset = moment(dateString).utcOffset()
  const diff = exactTime ? (timeZoneOffset - (dateOffset / 60)) : 0
  return date.utc().add(diff + (timeZoneOffset * -1), 'h').format()
}

export function localToCompanyOffsetEndOfDay (dateString) {
  const timeZoneOffset = store.getters['user/companyInfo'].timeZoneOffset
  const date = moment.utc(dateString.slice(0, 19)).endOf('day')
  return date.add(timeZoneOffset * -1, 'h').format()
}

export function utcToLocalDate (dateString, dateFormat) {
  const timeZoneOffset = store.getters['user/companyInfo'].timeZoneOffset

  const date = moment.utc(dateString)

  date.add(timeZoneOffset, 'h')
  const dateOffset = moment(dateString).utcOffset()

  if (dateFormat && (dateFormat.includes('L') || dateFormat.includes('mm'))) {
    const diff = timeZoneOffset - (dateOffset / 60)
    date.add(diff * -1, 'h')
  }

  dateFormat = dateFormat === undefined ? defaultDateFormat : dateFormat

  const formattedDate = dateFormat === 'raw' ? date : date.format(dateFormat)
  if (formattedDate === 'Invalid date') {
    return 'N/A'
  } else {
    return formattedDate
  }
}

export function formatDate (dateString, dateFormat) {
  if (!dateString) return i18n.t('notApplicable')
  dateFormat = dateFormat === undefined ? defaultDateFormat : dateFormat
  const formattedDate = moment(dateString).format(dateFormat)
  if (formattedDate === 'Invalid date') {
    return i18n.t('notApplicable')
  } else {
    return formattedDate
  }
}

export function companyOffsetToLocalDate (dateString, dateFormat) {
  const timeZoneOffset = store.getters['user/companyInfo'].timeZoneOffset

  const date = moment.utc(dateString)
  date.add(timeZoneOffset, 'h')
  const formattedDate = date.format(dateFormat ?? defaultDateFormat)
  return formattedDate === 'Invalid date'
    ? 'N/A'
    : formattedDate
}

export function timeBetween (start, end) {
  if (!start || !end) {
    return 'N/A'
  }
  const startDate = moment(start)
  const endDate = moment(end)
  const delta = moment.duration(endDate.diff(startDate))

  const days = Math.floor(delta.asDays())

  if (days >= 1) {
    return days === 1 ? i18n.t('oneDay') : i18n.t('nDays', { n: days })
  }

  const hoursMinsSecs = [delta.asHours() % 24, delta.asMinutes() % 60, delta.asSeconds() % 60]
  hoursMinsSecs.forEach((it, index) => {
    if (it < 10) {
      hoursMinsSecs[index] = `0${it.toFixed(0)}`
    } else {
      hoursMinsSecs[index] = it.toFixed(0)
    }
  })

  return `${hoursMinsSecs[0]}:${hoursMinsSecs[1]}:${hoursMinsSecs[2]}`
}

export function secondsFormatter (seconds) {
  const hrs = Math.floor(seconds / 3600)
  const mins = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds) % 60
  let ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

const clampToInterval = (input, step, mod) => {
  // clamp input to step and divide by modulo
  // ex. (7, 3, 12) -> 6
  // ex. (13, 3, 12) -> 1
  return (step * Math.floor(input / step)) % mod
}

const today = () => ({
  sinceTime: moment().hours(0).minutes(0).seconds(0).milliseconds(0),
  untilTime: moment().hours(23).minutes(59).seconds(59).milliseconds(999)
})

export const timeframeToUtcIsoString = (tf) => ({
  ...tf,
  resolve: () => {
    const r = tf.resolve()
    return {
      sinceTime: localToUTC(r.sinceTime?.format()),
      untilTime: r.untilTime
        ? localToCompanyOffsetEndOfDay(r.untilTime?.format())
        : moment.utc().format()
    }
  }
})

export const timeframesInLocalTime = {
  today: {
    label: 'today',
    resolve: () => ({
      sinceTime: today().sinceTime
    })
  },
  yesterday: {
    label: 'yesterday',
    resolve: () => {
      const t = today()
      return {
        sinceTime: t.sinceTime.subtract(1, 'days'),
        untilTime: t.untilTime.subtract(1, 'days')
      }
    }
  },
  last7Days: {
    label: 'last7Days',
    resolve: () => {
      const t = today()
      return {
        sinceTime: t.sinceTime.subtract(7, 'days')
      }
    }
  },
  last14Days: {
    label: 'last14Days',
    resolve: () => {
      const t = today()
      return {
        sinceTime: t.sinceTime.subtract(14, 'days')
      }
    }
  },
  last30Days: {
    label: 'last30Days',
    resolve: () => {
      const t = today()
      return {
        sinceTime: t.sinceTime.subtract(30, 'days')
      }
    }
  },
  thisWeek: {
    label: 'thisWeek',
    resolve: () => {
      const t = today()
      return {
        sinceTime: t.sinceTime.subtract(t.sinceTime.day(), 'days')
      }
    }
  },
  sinceMonday: {
    label: 'thisWeekSinceMonday',
    resolve: () => {
      return {
        sinceTime: today().sinceTime.startOf('week').add(1, 'days')
      }
    }
  },
  lastWeek: {
    label: 'lastWeek',
    resolve: () => ({
      sinceTime: moment().startOf('week').subtract(1, 'weeks'),
      untilTime: moment().startOf('week').subtract(1, 'days')
    })
  },
  thisMonth: {
    label: 'thisMonth',
    resolve: () => ({
      sinceTime: today().sinceTime.startOf('month')
    })
  },
  lastMonth: {
    label: 'lastMonth',
    resolve: () => ({
      sinceTime: moment().startOf('month').subtract(1, 'months'),
      untilTime: moment().startOf('month').subtract(1, 'days')
    })
  },
  thisQuarter: {
    label: 'thisQuarter',
    resolve: () => {
      const startMonth = clampToInterval(moment().startOf('month').month(), 3, 12)
      return {
        sinceTime: moment().startOf('month').month(startMonth)
      }
    }
  },
  lastQuarter: {
    label: 'lastQuarter',
    resolve: () => {
      const startMonth = clampToInterval(moment().startOf('month').month(), 3, 12)
      return {
        sinceTime: moment().startOf('month').month(startMonth).subtract(3, 'months'),
        untilTime: moment().startOf('month').month(startMonth).subtract(1, 'days')
      }
    }
  }
}

// Returns date range object formatted as:
// {
//   sinceTime: String,
//   untilTime: String? // Can be undefined.
// }
export function getDateRangeForPeriod (dateSelection) {
  const resolver = Object.values(timeframesInLocalTime).find(tf => tf.label === dateSelection)
  const out = (resolver?.resolve)
    ? timeframeToUtcIsoString(resolver).resolve()
    : timeframeToUtcIsoString(timeframesInLocalTime.today).resolve()
  return out
}

export function dateIsUnderCountUnitsAway (endDate, count, unitOfTime) {
  const deltaMs = Date.parse(endDate) - Date.now()
  return (deltaMs > 0)
    ? deltaMs < moment.duration(count, unitOfTime).asMilliseconds()
    : false
}
