<template>
  <v-container fluid>
    <Dialog :stateId="dialogId" @dialog-closing="close()" maxWidth="80vw">
      <ContractDetail v-if="contractDetail" :contractId="focusedPayable.contractId"/>
      <TicketDetails v-if="ticketDetail" :ticketId="focusedPayable.ticketId"></TicketDetails>
    </Dialog>
    <DataTable
    :items.sync="mappedPayables"
    :headers="headers"
    :hideHeader="true"
    :dense.sync="dense"
    :customCells="customPayableCells"
    :actions=[]
    >
      <template #in-weight="slotProps">{{
        getFormattedTons(slotProps.item.inWeight)
      }}</template>
      <template #out-weight="slotProps">{{
        getFormattedTons(slotProps.item.outWeight)
      }}</template>
      <template #defect-weight="slotProps">{{
        getFormattedTons(slotProps.item.defectWeight)
      }}</template>
      <template #net-weight="slotProps">{{
        getFormattedTons(slotProps.item.netWeight)
      }}</template>
      <template #product="slotProps">{{
        slotProps.item.product ? slotProps.item.product : 'Other'
      }}</template>
      <template #pay-on="slotProps">
        {{payOn(slotProps.item.computedAtPayOn)}}
      </template>
      <template #pay-by="slotProps">
        {{payBy(slotProps.item.computedAtPayBy)}}
      </template>
      <template #amount="slotProps">{{
        `${formatMoney(slotProps.item.amount)} (${formatMoney(slotProps.item.grossAmount)})`
      }}</template>
      <template #computedAtRate="slotProps">
        <span>{{ formatMoney(slotProps.item.computedAtRate) }}</span>
      </template>
      <template #activityWithSplit="{item}">
        <span>
          {{ item.activityName }}
        </span>
        <span v-if="item.computedAtSplit">
        ({{ formatPercentage(item.computedAtSplit) }})</span>
      </template>
      <template #actions="slotProps">
        <Icon
        v-if="!hideJumps"
        icon="mdi-clipboard-outline"
        iconColor="grey"
        dataTestId="payable-ticket-button"
        :tooltipText="$t('viewTicket')"
        @icon-clicked="viewTicket(slotProps.item)"/>
        <Icon
        v-if="!hideJumps"
        icon="mdi-altimeter"
        dataTestId="payable-contract-button"
        :tooltipText="$t('viewContract')"
        @icon-clicked="viewContract(slotProps.item)"/>
      </template>
    </DataTable>
  </v-container>
</template>

<script>
import { formatMoney, numberWithCommas, getFormattedTons, formatPercentage } from '@/utils/NumericMutations'
import RouterJump from '@/model/RouterJump.js'
import { allPayableHeaders } from '@/headers/Payable.js'
import { PayOn, PayBy } from '@/utils/Enumerations.js'
import { mapActions, mapMutations } from 'vuex'
export default {
  name: 'PayableTable',

  data: () => ({
    dialogId: 'payable-table',
    contractDetail: false,
    ticketDetail: false,
    dialog: false,
    focusedPayable: undefined,
    customPayableCells: [
      {
        slotName: 'net-weight',
        value: 'netWeight'
      },
      {
        slotName: 'defect-weight',
        value: 'defectWeight'
      },
      {
        slotName: 'gross-weight',
        value: 'gross'
      },
      {
        slotName: 'pay-on',
        value: 'computedAtPayOn'
      },
      {
        slotName: 'pay-by',
        value: 'computedAtPayBy'
      },
      {
        slotName: 'product',
        value: 'product'
      },
      {
        slotName: 'computed-at',
        value: 'computedAt',
        dateFormat: ('MM/DD/YYYY')
      },
      {
        slotName: 'computedAtRate',
        value: 'computedAtRate'
      },
      {
        slotName: 'amount',
        value: 'amount'
      },
      {
        slotName: 'actions',
        value: 'actions'
      },
      {
        slotName: 'activityWithSplit',
        value: 'activityWithSplit'
      }
    ]
  }),

  props: {
    payables: Array,
    hideFooter: { Boolean, default: false },
    hideJumps: { Boolean, default: false },
    dense: { Boolean, default: true }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    ContractDetail: () => import('@/components/contract/contract-detail/ContractDetail.vue'),
    TicketDetails: () => import('@/components/ticket/ticket-details/TicketDetails.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Dialog: () => import('@/components/Dialog.vue')
  },

  computed: {
    headers () { return allPayableHeaders(this.$i18n.locale) },
    mappedPayables () {
      return this.payables.map(p => ({
        ...p,
        activityWithSplit: {
          name: p.activityName,
          split: p.computedAtSplit
        }
      }))
    }
  },

  methods: {
    ...mapMutations('global', ['setRouterJump']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    formatMoney,
    numberWithCommas,
    getFormattedTons,
    formatPercentage,
    payOn: (x) => PayOn.fromInt(x),
    payBy: (x) => PayBy.fromInt(x),
    jumpToContract (payable) {
      const routerJump = new RouterJump('PayablesByTract', 'Contracts', { contractId: payable.contractId })
      this.setRouterJump(routerJump)
      this.$router.push('contracts')
    },

    closeSubDialogs () {
      this.dialog = false
      this.contractDetail = false
      this.ticketDetail = false
    },

    viewContract (payable) {
      this.focusedPayable = payable
      this.closeSubDialogs()
      this.contractDetail = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    viewTicket (payable) {
      this.focusedPayable = payable
      this.closeSubDialogs()
      this.ticketDetail = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    close () {
      this.closeSubDialogs()
      this.focusedPayable = undefined
      this.closeDialogsAtOrAbove(this.dialogId)
    }
  }
}
</script>
