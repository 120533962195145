<template>
  <v-autocomplete
  :items="tags"
  return-object
  item-text="value"
  multiple
  chips
  :label="label ? $t('tags') : undefined"
  v-model="selectedTags"/>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'TagSelector',

  props: {
    propTags: {
      type: Array
    },
    label: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    selectedTags: [],
    tags: [],
    loading: false
  }),

  watch: {
    selectedTags: {
      deep: true,
      handler (val) {
        const ids = val.map(t => t.tagId)
        this.$emit('tags-chosen', ids)
      }
    }
  },

  created () {
    this.getTags()
  },

  methods: {
    ...mapActions('tags', ['fetchTags']),
    async getTags () {
      this.loading = true
      try {
        const tags = await this.fetchTags(0)
        this.tags = tags
        this.addTagsFromProps()
      } finally {
        this.loading = false
      }
    },

    addTagsFromProps () {
      if (this.propTags) {
        this.selectedTags = this.propTags
      }
    }
  }
}
</script>
