<template>
  <v-card min-height="300">
    <v-card-title :class="`${colorClassForContractType(contract.type)} white--text headline`">
      <span>
        {{ title }}
      </span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <v-text-field v-model="search" :placeholder="$t('searchProducts')" color="black"></v-text-field>
      <v-list two-line subheader>
        <v-list-item v-if="profitByProduct">
          <v-list-item-content>
            <v-list-item-title>
              <v-row>
                <v-col cols="8">
                  <span style="text-decoration: underline;">
                    {{ $t('product') }}
                  </span>
                </v-col>
                <v-col cols="4" align="right" style="text-decoration: underline;">
                  {{ $t('grossProfit')}}
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
        v-for="(product, i) in filteredProducts"
        :key="i"
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-row>
                <v-col cols="8">
                  <span style="overflow: hidden; text-overflow: ellipsis; display: block">
                    {{ product.name }}
                  </span>
                </v-col>
                <v-col cols="4" align="right" v-if="profitByProduct">
                  {{ `${formatMoney(profitByProduct.get(product.productId) ?? 0)}/t` }}
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
import { colorClassForContractType } from '@/utils/componentHelpers.js'
export default {
  name: 'ProductDialog',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    products: Array,
    profitByProduct: {
      type: Map,
      default: undefined,
      required: false
    },
    contract: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    search: ''
  }),

  computed: {
    title () {
      return this.$t('productDialogTitle')
    },

    filteredProducts () {
      return this.products.filter((p) => {
        return p.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },

  methods: {
    formatMoney,
    colorClassForContractType
  }
}
</script>
