<template>
  <v-autocomplete
  :items="filteredCertifications"
  return-object
  item-text="name"
  multiple
  chips
  :label="(label) ? selectLabel : undefined"
  v-model="selectedCertifications"/>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Certifications',

  props: {
    propCertifications: {
      type: Array[Object],
      default: () => []
    },
    useIdOnly: {
      type: Boolean,
      default: false
    },
    category: {
      type: Number,
      required: true
    },
    label: {
      type: [Boolean, String],
      required: false,
      default: false
    },
    dataTestid: String,
    propFetchCertifications: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  data: () => ({
    selectedCertifications: []
  }),

  computed: {
    ...mapGetters('certification', ['allCertifications']),
    filteredCertifications () {
      return this.allCertifications.filter(c => c.category === this.category)
    },
    selectedCertificationsLength () {
      return this.selectedCertifications?.length ?? 0
    },
    selectLabel () {
      if (typeof this.label === 'string') return this.label
      switch (this.category) {
        case 0:
          return this.$t('tractCertifications')
        case 1:
          return this.$t('accountCertifications')
        default:
          return this.$t('certifications')
      }
    }
  },

  watch: {
    propCertifications () {
      this.selectedCertifications = this.filteredCertifications.filter(cert => this.propCertifications.some(pc =>
        ((this.useIdOnly) ? pc : pc.certificationId) === cert.certificationId))
    },

    selectedCertificationsLength () {
      this.$emit('certifications', (this.useIdOnly) ? this.selectedCertifications.map(c => c.certificationId) : this.selectedCertifications)
    }
  },

  async created () {
    await this.getCertifications()
  },

  methods: {
    ...mapActions('certification', ['fetchCertifications']),

    async getCertifications () {
      if (this.propFetchCertifications) await this.fetchCertifications()
      this.selectedCertifications = this.filteredCertifications.filter(cert => this.propCertifications.some(pc =>
        ((this.useIdOnly) ? pc : pc.certificationId) === cert.certificationId))
    }
  }
}
</script>
