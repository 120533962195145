<template>
  <v-card>
    <v-card-title :class="colorClassForContractMode(contractMode)">
      <span class="headline white--text mr-2">{{$t('downloadInventorySummary')}}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <FormWrapper :buttonColor="colorClassForContractMode(contractMode)" :buttonText="$t('download')" @submit="downloadCsv" :disabled="dateRangeError !== null">
        <v-container fluid class="my-6">
          <v-row>
            <v-col>
              <DatePicker
              :dateLabel="$t('startDate')"
              :startDate="selectedArchiveSinceTime"
              @valid-date="(val) => {isSinceTimeValid = val}"
              @date-picked="(val) => { selectedArchiveSinceTime = val }"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <DatePicker
              :dateLabel="$t('endDate')"
              :startDate="selectedArchiveUntilTime"
              @valid-date="(val) => {isUntilTimeValid = val}"
              @date-picked="(val) => { selectedArchiveUntilTime = val }"
              />
            </v-col>
          </v-row>
          <v-row>
            <span class="error--text" v-if="dateRangeError !== null">
              {{ dateRangeError }}
            </span>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import { generateCsvString } from '../../utils/CSVUtility'
import { DeckContentType, ContractMode } from '../../utils/Enumerations'
import { saveAs } from 'file-saver'
import { mapActions } from 'vuex'
import { localToUTC } from '../../utils/DateFormatter'
import { colorClassForContractMode } from '@/utils/componentHelpers.js'

export default {
  name: 'InventoryCsvDownload',
  components: {
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    selectedLocation: {
      type: Object
    },
    selectedDeck: {
      type: Object
    },
    contentType: {
      type: Number,
      required: false,
      default: DeckContentType.Logs.value
    }
  },

  data: () => ({
    selectedArchiveSinceTime: moment().add(-30, 'days').format(),
    selectedArchiveUntilTime: moment().add(-1, 'days').format(),
    isSinceTimeValid: false,
    isUntilTimeValid: false
  }),

  computed: {
    dateRangeError () {
      const sinceTime = moment(this.selectedArchiveSinceTime)
      const untilTime = moment(this.selectedArchiveUntilTime)
      const now = moment()

      if (sinceTime.isAfter(untilTime)) return this.$t('startDateAfterEndDate')

      if (untilTime.diff(sinceTime, 'days') > 92) return this.$t('dateRangeTooLarge', { maxDays: 92 })

      if (!this.isSinceTimeValid) return this.$t('invalidStartDate')
      if (!this.isUntilTimeValid) return this.$t('invalidEndDate')

      if (sinceTime.isAfter(now) || untilTime.isAfter(now)) return this.$t('invalidFutureDate')

      return null
    },

    contractMode () {
      return this.contentType === DeckContentType.Logs.value ? ContractMode.Logs.value : ContractMode.Byproducts.value
    }
  },

  methods: {
    ...mapActions('decks', ['getArchivedDeckHistory']),
    colorClassForContractMode,
    generateCsvString,
    async downloadCsv () {
      const data = await this.getArchivedCsvDataFromServer(
        this.selectedLocation,
        this.selectedDeck,
        this.selectedArchiveSinceTime,
        this.selectedArchiveUntilTime,
        this.contentType
      )

      if (data.length === 0) {
        this.setSnackError(this.$t('noInventoryDataAvailable'))
        return
      }

      const csv = this.generateCsvString(data, [
        { label: 'date', value: d => moment(d.recordedOn).add(-1, 'days').format('MM/DD/YYYY') },
        { label: 'location', value: d => d.locationName },
        { label: 'deck', value: d => d.deckName },
        { label: 'tons', value: d => d.weight },
        { label: 'pounds', value: d => d.onDeck },
        { label: 'value', value: d => parseFloat(d.value.toFixed(2)) }
      ])

      const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' })
      const fileName = `Inventory-summary-${moment(this.selectedArchiveSinceTime).format('YYYY-MM-DD')}.csv`
      saveAs(blob, fileName)
      this.$emit('close')
    },

    async getArchivedCsvDataFromServer (location, deck, sinceTime, untilTime, contentType) {
      const requestObject = {
        locationId: location.locationId !== -1 ? location.locationId : undefined,
        deckId: deck.deckId !== -1 ? deck.deckId : undefined,
        sinceTime: localToUTC(moment(sinceTime).add(1, 'days').format()),
        untilTime: localToUTC(moment(untilTime).add(2, 'days').format()),
        contentType: contentType
      }
      return await this.getArchivedDeckHistory(requestObject)
    }
  }
}
</script>
