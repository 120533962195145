import i18n from '../../i18n'
import { deepMapIf, flatMapObjectIf } from '../base'

class CsvStringReader {
  constructor (s) {
    this.s = s
    this.index = 0
  }

  get done () {
    return this.index >= this.s.length
  }

  charAt (i) {
    return (this.index + i < this.s.length)
      ? this.s[this.index + i]
      : undefined
  }

  get previousChar () {
    return (this.index - 1 < this.s.length)
      ? this.s[this.index - 1]
      : undefined
  }

  get currentChar () {
    return (this.index < this.s.length)
      ? this.s[this.index]
      : undefined
  }

  get nextChar () {
    return this.charAt(1)
  }

  get isQuote () {
    return this.currentChar === '"'
  }

  get isOnEscapedQuote () {
    return this.currentChar === '"' && this.nextChar === '"'
  }

  get isOnUnescapedQuote () {
    return this.currentChar === '"' && this.nextChar !== '"' && this.previousChar !== '"'
  }

  get isUnitSeparator () {
    return this.currentChar === ','
  }

  get nextIsUnitSeparator () {
    return this.nextChar === ','
  }

  get isRecordSeparator () {
    return this.currentChar === '\n' || (this.currentChar === '\r' && this.nextChar === '\n')
  }

  advance () {
    this.index += 1
  }

  advanceBy (n) {
    this.index += n
  }

  advancePastWhitespace () {
    while (this.currentChar === ' ' || this.currentChar === '\t') this.advance()
  }

  advanceTillUnescapedQuote () {
    while (!this.isOnUnescapedQuote && !this.done) {
      this.advance()
    }
  }

  advanceTillUnitSeparator () {
    while (!this.isUnitSeparator && !this.done) {
      this.advance()
    }
  }

  advanceTillSeparator () {
    while (!this.isUnitSeparator && !this.isRecordSeparator && !this.done) {
      this.advance()
    }
  }

  sliceFrom (n) {
    const start = Math.max(n, 0)
    const end = this.index
    return this.s.slice(start, end)
  }
}

function * yieldCsvLines (csvString, { skipEmptyLines = true } = {}) {
  const reader = new CsvStringReader(csvString)
  while (!reader.done) {
    const line = [...yieldCsvLine(yieldCsvTokens(reader))]
    if (!skipEmptyLines || line.length > 0) yield line
    reader.advancePastWhitespace()

    if (reader.done) break
    if (reader.isRecordSeparator) {
      if (reader.currentChar === '\r' && reader.nextChar === '\n') reader.advanceBy(2)
      else if (reader.currentChar === '\n') reader.advanceBy(1)
    }
  }
}

const csvParsingSymbols = {
  unitSeparator: Symbol.for('csv_unit_separator_token'),
  data: Symbol.for('csv_data_token')
}

function * yieldCsvTokens (reader) {
  while (!reader.isRecordSeparator && !reader.done) {
    reader.advancePastWhitespace()
    if (reader.isRecordSeparator || reader.done) {
      break
    } else if (reader.isUnitSeparator) {
      yield { type: csvParsingSymbols.unitSeparator }
      reader.advance()
      continue
    }
    reader.advancePastWhitespace()

    if (reader.isQuote) {
      reader.advance()
      if (reader.currentChar === '"' && reader.nextChar !== '"') {
        yield {
          type: csvParsingSymbols.data,
          value: ''
        }
        reader.advanceTillSeparator()
        continue
      }
      const startIdx = reader.index
      reader.advanceTillUnescapedQuote()

      if (reader.done) throw new Error(i18n.t('csvUnterminatedString'))

      yield {
        type: csvParsingSymbols.data,
        value: reader.sliceFrom(startIdx).replaceAll('""', '"')
      }

      reader.advance()
      reader.advancePastWhitespace()
    } else {
      const startIdx = reader.index
      reader.advanceTillSeparator()
      yield {
        type: csvParsingSymbols.data,
        value: reader.sliceFrom(startIdx).trimEnd()
      }
    }
  }
}

function * yieldCsvLine (csvTokens) {
  const first = csvTokens.next()
  if (first.done) {
    return
  }
  if (first.value.type === csvParsingSymbols.data) yield first.value.value
  else yield ''

  let lastType = first.value.type

  while (true) {
    const token = csvTokens.next()
    if (token.done) break

    if (token.value.type === csvParsingSymbols.unitSeparator && lastType === csvParsingSymbols.unitSeparator) {
      yield ''
    }
    if (token.value.type === csvParsingSymbols.data) yield token.value.value
    lastType = token.value.type
  }

  if (lastType === csvParsingSymbols.unitSeparator) yield ''
}

const parseCsvDefaultOptions = {
  missingColumnBehavior: 'throw',
  strictRecordCount: true,
  throwOnUnusedColumns: true,
  maxLineErrors: 10
}

class ParsedCsv {
  constructor (data, errors, lineErrors) {
    this.data = data
    this.errors = (errors === undefined)
      ? []
      : Array.isArray(errors)
        ? errors
        : [errors]
    this.lineErrors = lineErrors ?? {}
  }

  static fromData (data) {
    return new ParsedCsv(data, undefined, undefined)
  }

  static fromErrors (errors) {
    return new ParsedCsv(undefined, errors, undefined)
  }

  static fromLineErrors (lineErrors) {
    return new ParsedCsv(undefined, undefined, lineErrors)
  }

  get isSuccess () {
    return !this.hasLineError && !this.hasError
  }

  get isError () {
    return this.hasLineError || this.hasError
  }

  get errorLineIndices () {
    return Object.keys(this.lineErrors)
  }

  get hasLineError () {
    return this.errorLineIndices.length > 0
  }

  get hasError () {
    return this.errors.length > 0
  }

  get errorCount () {
    return (this.errors?.length ?? 0) + (this.lineErrors?.length ?? 0)
  }

  async mapAsync (mapAsync, context = undefined) {
    if (this.data === undefined) return this

    const lineErrors = {}
    let index = 0
    const newData = []
    const mapArgs = { index, data: this.data, context }
    for (const d of this.data) {
      try {
        newData.push(await mapAsync(d, mapArgs))
      } catch (error) {
        console.error(error)
        lineErrors[index] ??= []
        lineErrors[index].push(csvParseErrors.invalidRow({
          index,
          details: i18n.t('unknownError')
        }))
      }
      index += 1
      mapArgs.index += 1
    }
    return (Object.keys(lineErrors).length > 0)
      ? ParsedCsv.fromLineErrors(lineErrors)
      : ParsedCsv.fromData(newData)
  }

  async validateAsync (validateAsync, context = undefined) {
    if (this.data === undefined) return this

    const lineErrors = {}
    let index = 0
    const validateArgs = { index, data: this.data, context }
    for (const d of this.data) {
      try {
        const valid = await validateAsync(d, validateArgs)

        if (Array.isArray(valid)) {
          if (valid.length > 0) {
            lineErrors[index] = valid.map(details => csvParseErrors.invalidRow({
              index,
              details
            }))
          }
        } else if (valid[Symbol.iterator] && valid[Symbol.iterator]() === valid) {
          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Iterators_and_Generators#iterables
          for (const details of valid) {
            lineErrors[index] ??= []
            lineErrors[index].push(csvParseErrors.invalidRow({
              index,
              details
            }))
          }
        } else if (valid[Symbol.asyncIterator] && valid[Symbol.asyncIterator]() === valid) {
          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/AsyncIterator
          for await (const details of valid) {
            lineErrors[index] ??= []
            lineErrors[index].push(csvParseErrors.invalidRow({
              index,
              details
            }))
          }
        } else {
          if (valid !== true) {
            lineErrors[index] ??= []
            lineErrors[index].push(csvParseErrors.invalidRow({
              index,
              details: typeof valid === 'string' ? valid : i18n.t('unknownError')
            }))
          }
        }
      } catch (error) {
        console.error('Uncaught error on validating CSV data row', d, error)
        lineErrors[index] ??= []
        lineErrors[index].push(csvParseErrors.invalidRow({
          index,
          details: i18n.t('unknownError')
        }))
      }
      index += 1
      validateArgs.index += 1
    }
    return (Object.keys(lineErrors).length > 0) ? ParsedCsv.fromLineErrors(lineErrors) : this
  }
}

const csvParseErrors = {
  missingHeaderRow: () => ({
    errorType: 'missingHeaderRow',
    get message () { return i18n.t('missingHeaderRow') }
  }),

  unknownHeader: ({ header }) => ({
    errorType: 'unknownHeader',
    header,
    get message () { return i18n.t('csvUnknownHeader', { header }) }
  }),

  missingHeader: ({ header }) => ({
    errorType: 'missingHeader',
    header,
    get message () { return i18n.t('csvMissingRequiredHeader', { header }) }
  }),

  duplicateHeader: ({ header }) => ({
    errorType: 'duplicateHeader',
    header,
    get message () { return i18n.t('csvDuplicateHeader', { header }) }
  }),

  invalidRowLength: ({ index, rowLength, columnCount }) => ({
    errorType: 'invalidRowLength',
    index,
    lineNumber: index + 2,
    rowLength,
    columnCount,
    get message () { return i18n.t('csvInvalidRowLength', { lineNumber: index + 2, columnCount, itemCount: rowLength }) }
  }),

  invalidValue: ({ column, index, rawValue, details }) => ({
    errorType: 'invalidValue',
    index,
    lineNumber: index + 2,
    column,
    rawValue,
    details,
    get message () {
      const msg = i18n.t('csvInvalidValue', { rawValue, column, lineNumber: index + 2 })
      return (typeof details === 'string' && details !== '')
        ? `${msg}: ${details}`
        : msg
    }
  }),

  invalidRow: ({ index, details }) => ({
    errorType: 'invalidRow',
    index,
    lineNumber: index + 2,
    details,
    get message () {
      const msg = i18n.t('csvInvalidRow', { lineNumber: index + 2 })
      return (typeof details === 'string' && details !== '')
        ? `${msg}: ${details}`
        : msg
    }
  })
}

export function parseCsv (csvString, schemaObject, options = parseCsvDefaultOptions) {
  options = {
    ...parseCsvDefaultOptions,
    ...options
  }

  const lineGenerator = yieldCsvLines(csvString)

  let headerLine
  try {
    headerLine = lineGenerator.next()
  } catch (e) {
    return ParsedCsv.fromErrors(e.message)
  }
  if (headerLine.done) return ParsedCsv.fromErrors(csvParseErrors.missingHeaderRow())
  const headers = headerLine.value

  const rows = [...lineGenerator].filter(l => options.skipEmptyLines ? l.length > 0 : true)
  const columnCount = headers.length

  const headerCounts = new Map()
  headers.map(header => {
    headerCounts.set(header, headerCounts.has(header) ? headerCounts.get(header) + 1 : 1)
  })
  for (const h in headerCounts.keys()) {
    if (headerCounts.get(h) <= 1) headerCounts.delete(h)
  }

  let missingKeys
  const hh = flatMapObjectIf(schemaObject, p => p instanceof CsvColumn, (column, key) => {
    const headerName = column.name || key
    const headerIndex = headers.findIndex(h => h === headerName)
    if (headerIndex === -1 && column.required) {
      missingKeys ??= []
      missingKeys.push(csvParseErrors.missingHeader({ header: headerName }))
    }
    return { name: headerName, index: headerIndex }
  })

  const identifiedHeaders = new Set(hh.map(h => h.name))
  const unknownHeaders = headers.filter(h => !identifiedHeaders.has(h))
  if (unknownHeaders.length > 0 || missingKeys !== undefined) {
    return ParsedCsv.fromErrors([
      ...(missingKeys ?? []),
      ...(unknownHeaders ?? []).map(header => csvParseErrors.unknownHeader({ header }))
    ])
  }

  const headerKeyIndices = {}
  hh.forEach(({ name, index }) => {
    headerKeyIndices[name] = index
  })

  const rowErrs = {}
  let errorCount = 0

  const parsedRows = rows.map((row, i) => {
    if (options.maxLineErrors !== undefined && options.maxLineErrors > 0 && errorCount > options.maxLineErrors) {
      return undefined
    }

    if (options.strictRecordCount && row.length !== columnCount) {
      const err = csvParseErrors.invalidRowLength({ index: i, rowLength: row.length, columnCount })
      rowErrs[i] ??= []
      rowErrs[i].push(err)
      return undefined
    }

    row = (options.strictRecordCount)
      ? row
      : (row.length < columnCount)
        ? [...row, Array(columnCount - row.length).fill('')]
        : row.slice(0, columnCount)

    return deepMapIf(schemaObject, p => p instanceof CsvColumn, (column, key) => {
      if (options.maxLineErrors !== undefined && options.maxLineErrors > 0 && errorCount > options.maxLineErrors) {
        return undefined
      }

      const headerName = column.name || key
      const rowIndex = headerKeyIndices[headerName]
      const rowValue = row[rowIndex]
      try {
        const transformed = column.type.transform(rowValue)
        const validity = (column.type.validate) ? column.type.validate(transformed, rowValue) : true
        if (validity !== true) {
          const err = csvParseErrors.invalidValue({
            index: i,
            column: column.name,
            rawValue: rowValue,
            details: (typeof validity === 'string') ? validity : undefined
          })
          rowErrs[i] ??= []
          rowErrs[i].push(err)
          errorCount += 1
        } else {
          return transformed
        }
      } catch (e) {
        console.error(e)
        rowErrs[i] ??= []
        rowErrs[i].push(csvParseErrors.invalidValue({
          index: i,
          column: column.name,
          rawValue: rowValue
        }))
        errorCount += 1
        return undefined
      }
    })
  })

  if (Object.keys(rowErrs).length > 0) return ParsedCsv.fromLineErrors(rowErrs)
  return ParsedCsv.fromData(parsedRows)
}

export class CsvTypeTransformation {
  constructor (transform, validate) {
    this.transform = transform ?? (s => s)
    this.validate = validate
  }

  static from ({ transform, validate }) {
    const v = Array.isArray(validate)
      ? value => {
        for (const validateFn of validate) {
          const isValid = validateFn(value)
          if (isValid !== true) return isValid
        }
        return true
      } : validate
    return new CsvTypeTransformation(transform, v)
  }

  validateWith (validateFn) {
    return new CsvTypeTransformation(this.transform, v => {
      const o = this.validate(v)
      if (o !== true) return o
      return validateFn(v)
    })
  }
}

export const typeConstructors = {
  integer: new CsvTypeTransformation(parseInt, n => Number.isNaN(n) ? i18n.t('numberIsNotAnInteger') : true),
  float: new CsvTypeTransformation(parseFloat, n => Number.isNaN(n) ? i18n.t('numberIsNotAFloat') : true),
  floatOptional: new CsvTypeTransformation(s => s === '' ? undefined : parseFloat(s),
    n => (!undefined && Number.isNaN(n)) ? i18n.t('numberIsNotAFloat') : true),
  string: new CsvTypeTransformation(s => s),
  enumNameToInt (enumClass, enumClassName) {
    return CsvTypeTransformation.from({
      transform: s => enumClass.toInt(s),
      validate: (i, original) => i !== -1 ? true : i18n.t('invalidEnumValue', { enum: enumClassName ?? enumClass.prototype?.constructor?.name, original }) + '. ' + i18n.t('expectedOneOf', { options: enumClass.names.join(',') })
    })
  },
  enumNameToIntOptional (enumClass, enumClassName) {
    return CsvTypeTransformation.from({
      transform: s => s === '' ? undefined : enumClass.toInt(s),
      validate: (i, original) => (i === undefined || i !== -1) ? true : i18n.t('invalidEnumValue', { enum: enumClassName ?? enumClass.prototype?.constructor?.name, original }) + '. ' + i18n.t('expectedOneOf', { options: enumClass.names.join(',') })
    })
  }
}

export class CsvColumn {
  constructor (name, type, required = true) {
    this.name = name
    this.type = type
    this.required = required
  }

  static build (buildFn) {
    const { name, type } = buildFn(typeConstructors)
    return new CsvColumn(name, type ?? typeConstructors.string)
  }

  static from (columnArg) {
    if (typeof columnArg === 'string') return new CsvColumn(columnArg, typeConstructors.string)
    else {
      const { name, type, required = true } = columnArg
      return new CsvColumn(name, type ?? typeConstructors.string, required)
    }
  }
}
