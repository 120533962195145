<template>
  <v-row>
    <v-col>
      <v-row class="mt-1">
        <v-col cols="12">
          <v-container fluid>
            <v-expansion-panels popout multiple accordion tile v-model="openPanels">
              <v-expansion-panel v-if="!noticketsPayables" value="ticketPayables">
                <v-expansion-panel-header>
                  {{$t('ticketPayables')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span v-if="paymentsEntries.length > 0">
                    <p class="subtitle-1 mt-3 mb-n2">{{$t('contractPayments')}}</p>
                    <TicketFinancialsTable
                    :entries="paymentsEntries"
                    @aggregateSelected="contractPaymentSelected"/>
                  </span>
                  <span v-if="recoveriesEntries.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{ $t('recoveries') }}</p>
                    <RecoveriesAggregations
                    :recoveries="recoveriesEntries"/>
                  </span>
                  <span v-if="correctionEntries.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="correctionEntries"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && transferPayablesEntries.length > 0" value="transferPayables">
                <v-expansion-panel-header>
                  {{$t('transferPayables')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <TicketFinancialsTable
                  :entries="transferPayablesEntries"
                  @aggregateSelected="transferPayableSelected"/>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && logYardSalePayableEntries.length > 0" value="logYardSalePayables">
                <v-expansion-panel-header>
                  {{$t('logYardSalePayables')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <TicketFinancialsTable
                  :entries="logYardSalePayableEntries"
                  @aggregateSelected="logYardSalePayableSelected"/>

                  <span v-if="logYardSaleCorrectionEntries.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="logYardSaleCorrectionEntries"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && tractPaymentsEntries.length > 0" value="tractPayments">
                <v-expansion-panel-header>
                  {{$t('tractPayments')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <MiscPaymentsAggregation
                  :miscPayments="tractPaymentsEntries"
                  :miscPaymentType="MiscPaymentType.TractPayment"/>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && advancePayableEntries.length > 0" value="advances">
                <v-expansion-panel-header>
                  {{$t('advances')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <AdvancePayablesAggregations
                  :advancePayables="advancePayableEntries"/>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && accountPaymentEntries.length > 0" value="accountPayments">
                <v-expansion-panel-header>
                  {{ $t('accountPayments') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <MiscPaymentsAggregation
                  :miscPayments="accountPaymentEntries"
                  :miscPaymentType="MiscPaymentType.AccountPayment"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-col>
      </v-row>
      <NoData v-if="showNoData" class="mb-4">
        {{ $t('noDataForSelection') }}
      </NoData>
    </v-col>
    <v-dialog v-model="detailDialogOpen" width="900px">
      <TicketwiseFinancialBreakdown
      v-if="detailDialogOpen"
      :isCorrection="focusedContractPaymentIsCorrection"
      :payableSummary="focusedContractPayment"
      :includeRecoveries="!focusedPayableIsTransfer"
      :isByproducts="isByproducts"
      @close="closeDialogs"/>
    </v-dialog>
  </v-row>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
import AdjustingEntriesHeaders from '@/headers/AdjustingEntries.js'
import { MiscPaymentType } from '../../../../utils/Enumerations'
export default {
  name: 'PayablesAggregations',

  components: {
    TicketwiseFinancialBreakdown: () => import('@/components/accounting/adjusting-entries/v1/TicketwiseFinancialBreakdown.vue'),
    TicketFinancialsTable: () => import('@/components/accounting/adjusting-entries/v1/TicketFinancialsTable.vue'),
    MiscPaymentsAggregation: () => import('@/components/accounting/adjusting-entries/v1/MiscPaymentsAggregation.vue'),
    AdvancePayablesAggregations: () => import('@/components/accounting/adjusting-entries/v1/AdvancePayablesAggregations.vue'),
    RecoveriesAggregations: () => import('@/components/accounting/adjusting-entries/v1/RecoveriesAggregations.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  props: {
    payables: {
      type: Object,
      required: true
    },
    isByproducts: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    detailDialogOpen: false,
    focusedContractPayment: undefined,
    focusedContractPaymentIsCorrection: false,
    focusedPayableIsTransfer: false,
    openPanels: [],
    MiscPaymentType
  }),

  computed: {
    contractPaymentHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    correctionsHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    paymentsEntries () {
      return Array.from(this.payables?.ticketPayables?.contractPayments?.values() ?? [])
    },
    correctionEntries () {
      return Array.from(this.payables?.ticketPayables?.corrections?.values() ?? [])
    },
    logYardSaleCorrectionEntries () {
      return Array.from(this.payables?.ticketPayables?.logYardSaleCorrections?.values() ?? [])
    },
    transferPayablesEntries () {
      return Array.from(this.payables?.transferPayables?.values() ?? [])
    },
    logYardSalePayableEntries () {
      return Array.from(this.payables?.logYardSalePayables?.values() ?? [])
    },
    tractPaymentsEntries () {
      return Array.from(this.payables?.tractPayments?.values() ?? [])
    },
    advancePayableEntries () {
      return Array.from(this.payables?.advances?.values() ?? [])
    },
    recoveriesEntries () {
      return Array.from(this.payables?.ticketPayables?.recoveries?.values() ?? []).filter(r => r.amount !== 0)
    },
    accountPaymentEntries () {
      return Array.from(this.payables?.accountPayments?.values() ?? [])
    },
    noticketsPayables () {
      return this.paymentsEntries.length === 0 && this.correctionEntries.length === 0 && this.recoveriesEntries.length === 0
    },
    showNoData () {
      return this.noticketsPayables &&
        this.transferPayablesEntries.length === 0 &&
        this.tractPaymentsEntries.length === 0 &&
        this.recoveriesEntries.length === 0 &&
        this.advancePayableEntries.length === 0 &&
        this.logYardSalePayableEntries.length === 0 &&
        this.logYardSaleCorrectionEntries.length === 0 &&
        this.accountPaymentEntries.length === 0
    },
    contractColor () {
      return this.isByproducts ? 'byproduct' : 'primary'
    },
    borderStyle () {
      return `border: 3px solid ${this.isByproducts ? '#363636' : '#D15F27'}`
    }
  },

  methods: {
    formatMoney,

    contractPaymentSelected (contractPayment) {
      this.detailDialogOpen = true
      this.focusedContractPayment = contractPayment
    },

    correctionSelected (correction) {
      this.focusedContractPayment = correction
      this.focusedContractPaymentIsCorrection = true
      this.detailDialogOpen = true
    },

    transferPayableSelected (transferPayable) {
      this.focusedContractPayment = transferPayable
      this.focusedPayableIsTransfer = true
      this.detailDialogOpen = true
    },

    logYardSalePayableSelected (logYardSalePayable) {
      this.focusedContractPayment = logYardSalePayable
      this.focusedPayableIsLogYardSale = true
      this.detailDialogOpen = true
    },

    closeDialogs () {
      this.detailDialogOpen = false
      this.focusedContractPayment = undefined
      this.focusedContractPaymentIsCorrection = false
      this.focusedPayableIsTransfer = false
      this.focusedPayableIsLogYardSale = false
    }
  }
}
</script>
