<template>
  <v-container fluid grid-list-xl>
    <v-row dense class="mb-n10" align="center">
      <v-col cols="12" md="6">
        <PayPeriodSelector
        selectPeriod
        entireFiscalYearSelectable
        @period-selected="periodSelected">
        <template #append>
          <v-col cols="12" md="6" lg="4">
            <v-select
            class="ml-5"
            v-model="selectedTractType"
            :items="availableTractTypes"
            item-text="tractTypeName"
            :label="$t('tractType')"
            return-object/>
          </v-col>
        </template>
        </PayPeriodSelector>
      </v-col>
      <v-spacer/>
      <v-col cols="12" md="5" align="right">
        <v-row>
          <v-col cols="6">
            <span class="title">
              {{ $t('totalRemainingBalance') }}:
            </span>
          </v-col>
          <v-spacer/>
          <v-col cols="6" align="right">
            <span class="title">
              {{ formatMoney(remainingBalance) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="balanceSummaries.length === 0">
      <v-col>
        <NoData>
          {{ $t('noDataForSelection') }}
        </NoData>
      </v-col>
    </v-row>
    <v-row v-else class="mt-7">
      <v-col>
        <v-expansion-panels popout v-model="openPanels" accordion tile multiple>
          <v-expansion-panel v-for="(key) in Object.keys(groupedDepletionSummaries)" :key="key">
            <v-expansion-panel-header>
              <v-row>
                <v-col>
                  {{ $t('currentTractStatus') }}: {{ TractStatus.find(ts => ts.value === Number(key)).name }}
                </v-col>
                <v-col align="right">
                  {{ remainingDepletionTotal(groupedDepletionSummaries[key]) }}
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DataTable
              :items="groupedDepletionSummaries[key]"
              :headers="headers"
              :showSearch="false"
              :showDense="false"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { TractStatus } from '@/utils/Enumerations.js'
import { mapActions } from 'vuex'
import TractHeaders from '@/headers/Tract.js'
export default {
  name: 'Depletion',

  components: {
    PayPeriodSelector: () => import('@/components/settlements/PayPeriodSelector.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  data: () => ({
    TractStatus,
    selectedPeriod: undefined,
    balanceSummaries: [],
    openPanels: [0],
    selectedTractType: undefined
  }),

  computed: {
    groupedDepletionSummaries () {
      return Object.groupBy(this.filteredSummaries, s => s.tractStatus)
    },

    filteredSummaries () {
      return this.selectedTractType?.tractTypeId !== undefined
        ? this.balanceSummaries.filter(s => s.tractTypeId === this.selectedTractType?.tractTypeId)
        : this.balanceSummaries
    },

    headers () {
      return TractHeaders.liveDepletionHeaders({ isPeriod: this.isPeriod }).columns
    },

    isPeriod () {
      return this.selectedPeriod?.payPeriodId !== undefined
    },

    remainingBalance () {
      return this.filteredSummaries.reduce((acc, cur) => acc + cur.remaining, 0)
    },

    availableTractTypes () {
      const uniqueTypes = this.balanceSummaries.filter((s, i) => this.balanceSummaries.findIndex(sr => s.tractTypeId === sr.tractTypeId) === i) // Take unique
        .map(s => ({
          tractTypeId: s.tractTypeId,
          tractTypeName: s.tractTypeName
        }))

      uniqueTypes.unshift({
        tractTypeName: this.$t('allTractTypes')
      })

      return uniqueTypes
    }
  },

  created () {
    this.selectedTractType = this.availableTractTypes[0]
  },

  methods: {
    formatMoney,
    utcToLocalDate,
    ...mapActions('tract', ['fetchDepletion']),
    async periodSelected (p) {
      this.selectedPeriod = p
      this.balanceSummaries = await this.fetchDepletion(p)
    },

    remainingDepletionTotal (items) {
      const unformattedAmount = items.reduce((remaining, curr) => remaining + curr.remaining, 0)
      const amount = formatMoney(unformattedAmount)
      return `${this.$t('remainingBalance')}: ${amount}`
    }
  }
}
</script>
