<template>
  <v-container fluid grid-list-lg :data-testid="dataTestId">
    <v-row dense>
      <v-col md="12">
        <material-card
          :color="color"
          full-width
        >
          <template #header>
            <v-row dense>
              <v-col style="min-width:50%">
                <LoadableComponent :loading="!contractMode && !hideNonTract" type="fill">
                  <p class="headline font-weight-regular mb-2" v-text="title" data-testid="bp-card-heading"/>
                  <p class="category font-weight-thin" v-text="subtitle"/>
                </LoadableComponent>
              </v-col>
              <v-col cols="auto" class="mt-3">
                <LoadableComponent :loading="!contractMode && !hideNonTract" :width="200">
                  <v-select
                  v-if="mode !== null"
                  v-model="mode"
                  :items="contractModes"
                  :menu-props="{ offsetY: true }"
                  dense
                  id="select"
                  data-testid="contract-mode-select"
                  item-text="text"
                  outlined
                  return-object
                  class="mb-n6"
                  style="width:180px;"
                  ref="BpCardSelector"
                  @change="onSelection"
                  @keyup="onSelection"
                  >
                    <template #selection="{item}">
                      <v-icon small color="white" class="mr-2">{{item.details.icon}}</v-icon>
                      <span>{{item.name}}</span>
                    </template>
                    <template #item="{item}">
                      <v-row @click="onSelection($event, item, true)" no-gutters class="py-2 my-n2 px-4 mx-n4">
                        <v-icon small :color="item.details.color" class="mr-2">{{item.details.icon}}</v-icon>
                        <span>{{item.name}}</span>
                      </v-row>
                    </template>
                  </v-select>
                </LoadableComponent>
              </v-col>
            </v-row>
          </template>
          <LoadableComponent :loading="!contractMode && !hideNonTract">
            <slot/>
          </LoadableComponent>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ContractMode } from '@/utils/Enumerations.js'
export default {
  name: 'BPCard',

  props: {
    headerConfig: {
      type: Object
    },
    contractMode: {
      type: Object
    },
    hideNonTract: {
      type: Boolean,
      default: false
    },
    dataTestId: {
      type: String,
      required: false,
      default: 'bp-card'
    }
  },

  components: {
    LoadableComponent: () => import('@/components/helper/LoadableComponent.vue')
  },

  computed: {
    title () {
      return this.headerConfig?.title ?? ''
    },

    contractModes () {
      return Object.values(ContractMode).filter(mode => ![ContractMode.Transfers.value, ContractMode.LogYardSale.value].includes(mode.value) || !this.hideNonTract)
    },

    subtitle () {
      return this.headerConfig?.subtitle ?? ''
    },

    color () {
      return this.contractMode?.details?.color ?? 'primary'
    },

    mode: {
      get () { return this.contractMode },
      set (value) { this.$emit('update:contractMode', value) }
    },

    selectorKeys () {
      return ['enter', ...Object.values(ContractMode)
        .map(cm => cm.label.charAt(0).toLowerCase())]
    }
  },

  methods: {
    onSelection (e = undefined, selection, manualSelection) {
      if (this.selectorKeys.includes(e?.key?.toLowerCase()) || !e?.key) {
        this.$refs.BpCardSelector.blur()
      }
      if (manualSelection && selection.value !== this.contractMode.value) {
        this.$emit('update:contractMode-manual')
      }
    }
  }

}
</script>

<style scoped>
.v-select {
  width: 200px;
}
</style>
