<template>
  <v-autocomplete
    color="black"
    item-color="primary"
    ref="productAutocomplete"
    v-model="chosenProduct"
    :label="$t('product')"
    item-text="name"
    item-value="name"
    return-object
    clearable
    :data-testid="dataTestId"
    :loading="productsLoading"
    :items="products"
    :disabled="disabled"
    :filter="(item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
  >
    <template #append-outer>
      <v-icon :disabled="disabled" class="ml-n1 mr-1" @click="getProducts(true)" color="primary" tabindex="-1">mdi-refresh</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EnumValue, ContractType } from '../../utils/Enumerations'

export default {
  name: 'ProductAutocomplete',

  props: {
    productId: { type: Number, default: undefined },
    disabled: { type: Boolean, default: false },
    contract: { type: Object, default: undefined },
    isDetailForm: { type: Boolean, default: false },
    woodType: {
      type: EnumValue,
      required: false,
      default: undefined
    },
    excludedProducts: { type: Array, default: function () { return [] } },
    dataTestId: { type: String, default: 'product-autocomplete' },
    includePreexistingProduct: { type: Boolean, default: true }, // Only keep preexisting product if contract hasn't changed (on a ticket)
    propFetchProducts: { type: Boolean, default: true },
    defaultToFirstProduct: { type: Boolean, default: false }
  },

  data: () => ({
    chosenProduct: undefined
  }),

  watch: {
    chosenProduct: {
      handler (val) {
        this.$emit('product-chosen', val)
      },
      deep: true
    },

    products (products) {
      if (this.chosenProduct && products.every(product => product?.productId !== this.chosenProduct?.productId)) this.chosenProduct = null
    }
  },

  computed: {
    ...mapGetters('product', ['allProducts', 'productsLoading']),
    productRequestObj () {
      const isByproducts = this.contract?.type === ContractType.ByproductSale.value || this.contract?.type === ContractType.ByproductPurchase.value
      return { includeByProducts: isByproducts, includeProduction: !isByproducts }
    },

    products () {
      if (!this.allProducts) return []

      const productMeetsContractRequirement = (contract, targetProductId) => { // Product is either on contract or no contract products were supplied
        if (!contract?.productIds && !contract?.products) return true
        if (!this.getContractId()) return true
        return (contract.products?.map(p => p.productId) ?? contract.productIds).includes(targetProductId)
      }
      const products = this.allProducts
        .filter(p => this.woodType === undefined || p.woodType === this.woodType.value)
        .filter(product => !(this.excludedProducts.includes(product.name) && this.productId !== product.productId))
        .filter(p => productMeetsContractRequirement(this.contract, p.productId))

      if (this.productId !== -1 &&
        !!this.productId &&
        !products.some(p => p.productId === this.productId) &&
        this.includePreexistingProduct
      ) {
        products.push(this.allProducts.find(p => p.productId === this.productId))
      }

      return products
    }
  },

  async created () {
    await this.getProducts()
    const chosenProduct = this.allProducts.find(p => p.productId === this.productId)
    if (chosenProduct) {
      this.chosenProduct = chosenProduct
    } else if (this.defaultToFirstProduct) {
      this.chosenProduct = this.products[0]
    }
  },

  methods: {
    ...mapActions('product', ['fetchProducts']),

    getContractId () {
      if (this.contract === undefined) { return }
      if (this.isDetailForm === true) { return }
      if (this.contract.acceptsAnyLoad) { return }
      return this.contract.contractId
    },

    async getProducts (forceFetch = false) {
      if (forceFetch || this.propFetchProducts) await this.fetchProducts({ ...this.productRequestObj, contractId: this.getContractId() })
    }
  }
}
</script>
