<template>
  <v-row>
    <v-col cols="12" class="mb-n8">
      <v-row dense>
        <v-col v-if="contractHasFromAccount" cols="12" lg="4">
          <v-tooltip :disabled="!contract.fromAccountCode?.length > 0 " bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-text-field data-testid="contract-detail-from-account" v-on="on" :label="$t('fromAccount')" outlined readonly :value="contract.fromAccount"/>
            </template>
            <span class="white--text subtitle-1">{{ contract.fromAccountCode }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" :lg="!isLogsContract ? 4 : 3">
          <v-tooltip :disabled="!contract.accountCode.length > 0 " bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-text-field
              data-testid="contract-detail-account"
              :label="$t('account')"
              outlined
              color="primary"
              readonly
              :value="contract.account"
              v-on="on"
              :append-icon="isCertified ? 'mdi-certificate-outline' : ''"/>
            </template>
            <span class="white--text subtitle-1">
              {{ contract.accountCode }}
              <span v-if="isCertified">
                <br>
                {{$t('accountIsCertified')}}
              </span>
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" :lg="!isLogsContract ? 4 : 3">
          <v-text-field data-testid="contract-detail-destination" :label="$t('destination')" outlined readonly :value="contract.destination"/>
        </v-col>
        <v-col v-if="contractHasTract" cols="12" :lg="contractTypeIsByproductPurchase ? 4 : 3">
          <v-tooltip :disabled="!contract.tractIsCertified" bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-text-field
              data-testid="contract-detail-tract"
              :label="$t('tract')"
              outlined
              color="primary"
              readonly
              :value="contract.tract"
              :append-icon="contract.tractIsCertified ? 'mdi-certificate-outline' : ''"
              v-on="on"
              />
            </template>
            <span class="white--text subtitle-1">
              {{$t('tractIsCertified')}}
            </span>
          </v-tooltip>
        </v-col>
        <v-col v-if="isLogsContract" cols="12" lg="3">
          <v-text-field data-testid="contract-detail-setting" :label="$t('setting')" outlined readonly :value="contract.setting"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6" :lg="cardColumnWidth">
      <DetailCard :title="$t('properties')">
        <template #title.append>
          <v-row no-gutters>
            <v-col cols="auto" v-for="attribute in attributes" :key="attribute.text">
              <Icon
              dataTestId="contract-attribute"
              :key="attribute.text"
              :icon="attribute.icon"
              :tooltipText="attribute.text"
              :iconColor="attribute.color"
              :tooltipColor="attribute.color"
              />
            </v-col>
          </v-row>
        </template>
        <v-row dense data-testid="contract-details-prop-table">
          <v-col cols="12">
            <v-row no-gutters>
              <v-col> {{$t('type')}}: </v-col>
              <v-col cols="auto"> {{contractTypeFromInt(contract.type)}} </v-col>
            </v-row>
          </v-col>
          <v-col v-if="contractHasTract" cols="12">
            <v-row no-gutters>
              <v-col> {{$t('tractType')}}: </v-col>
              <v-col cols="auto"> {{contract.tractType}} </v-col>
            </v-row>
          </v-col>
          <v-col v-if="contractHasTract" cols="12">
            <v-row no-gutters>
              <v-col> {{$t('logger')}}: </v-col>
              <v-col cols="auto"> {{contract.tractDefaultLogger ?? $t('notAvailable')}} </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="contract.distance > 0">
            <v-row no-gutters>
              <v-col> {{$t('distance')}}: </v-col>
              <v-col cols="auto"> {{ contract.distance }} </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="contract.tareWeight">
            <v-row no-gutters>
              <v-col> {{$t('tareWeight')}}: </v-col>
              <v-col cols="auto"> {{ numberWithCommas(contract.tareWeight) }} {{ $t('lbsUpper') }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="contractTypeIsByproduct">
            <span>
              {{ $t('allowsLoadPickup')}}:
              <Icon
              :tooltipText="contract.allowPickupLoads ? $t('allowsLoadPickup') : $t('notAllowsLoadPickup')"
              :icon="contract.allowPickupLoads ? 'mdi-checkbox-marked-circle' : 'mdi-close'"
              :iconColor="contract.allowPickupLoads ? 'success' : 'error'"/>
            </span>
          </v-col>
          <v-col cols="12">
            <span>{{utcToLocalDate(contract.effectiveDate)}}</span> -
            <span>{{utcToLocalDate(contract.expirationDate)}}</span>
          </v-col>
        </v-row>
      </DetailCard>
    </v-col>
    <v-col cols="12" md="6" :lg="cardColumnWidth" v-if="tractLandowners.length > 0 && tractLandowners.length < 6">
      <DetailCard :title="$t('tractLandowners')">
        <v-row dense v-for="(landowner, index) in tractLandowners" :key="index">
          <v-col cols="9">
            {{ landowner.accountName }}
          </v-col>
          <v-col cols="3" align="right">
            {{ formatPercentage(landowner.ownership) }}
          </v-col>
        </v-row>
      </DetailCard>
    </v-col>
    <v-col cols="12" md="6" :lg="cardColumnWidth">
      <DetailCard :title="$t('products')">
        <template #title.append>
          <Icon
          dataTestId="contract-load-icon"
          iconColor="grey"
          :tooltipText="contract.acceptsAnyLoad ? $t('acceptsAnyProduct') : $t('restrictedProducts')"
          :icon="contract.acceptsAnyLoad ? 'mdi-lock-open-variant-outline' : 'mdi-lock-outline'"
          />
        </template>
        <v-row dense v-if="contract.products.length > 0">
          <v-col cols="12">
            <v-row v-if="contractTypeIsWoodsSale" class="mb-n7">
              <v-col cols="6">
                <span style="font-weight: bold;">{{ $t('product') }}</span>
              </v-col>
              <v-col cols="6" align="right">
                <span style="font-weight: bold;">
                  <Icon
                  iconColor="info"
                  v-if="showPayByLoadWarning"
                  icon="mdi-information-outline"
                  :tooltipText="$t('profitPerTonCannotBeCalculatedForPayByLoadEditing')"/>
                  <Icon
                  iconColor="info"
                  v-if="showNonInventoryWarning"
                  icon="mdi-information-outline"
                  :tooltipText="$t('nonInventoryCostsExcluded')"/>
                  {{ $t('grossProfit') }}
                </span>
              </v-col>
            </v-row>
            <v-row v-for="n in (contractTypeIsWoodsSale ? 3 : 4)" :key="n" class="mb-n7">
              <v-col cols="8">
                <span>{{ getProductAtIndex(n) }}</span>
              </v-col>
              <v-spacer/>
              <v-col cols="4" align="right" v-if="contractTypeIsWoodsSale && (n <= contract.products.length) && (!showPayByLoadWarning)">
                <span>{{ `${formatMoney(getProductProfitRateAtIndex(n))}/t` }}</span>
              </v-col>
            </v-row>
            <v-row v-if="contract.products.length > 3">
              <v-col cols="12">
                <span class="mt-2" style="text-decoration: underline; cursor: pointer;" @click="openProductDialog">
                  {{$t('viewAll')}}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else dense>
          <v-col cols="12">
            <span>{{$t('noProductsSpecified')}}</span>
          </v-col>
        </v-row>
      </DetailCard>
    </v-col>
    <v-col cols="12" v-if="tractLandowners.length > 5">
      <v-expansion-panels popout tile>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row dense class="subtitle-1 mt-n1" style="flex-wrap: nowrap; overflow: hidden; text-wrap: nowrap;">
              <v-col>
                <span> {{$t('tractLandowners')}} </span>
              </v-col>
              <v-col cols="auto">
                <span> {{ landownersPanelString }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
            mobile-breakpoint="396"
            class="mt-4"
            hide-default-footer
            :items-per-page="-1"
            :items="tractLandowners"
            :headers="landownerHeaders"
            sort-by="ownership"
            sort-desc
            dense>
              <template #item.name="{item}">
                <v-row no-gutters align="center">
                  {{item.accountName}}
                </v-row>
              </template>
              <template #item.ownership="{item}">
                <span>{{formatPercentage(item.ownership)}}</span>
              </template>
              <template #item.isDefault="{item}">
                <v-icon color="black" v-if="item.isDefault">
                  mdi-checkbox-marked-circle
                </v-icon>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <Dialog :stateId="dialogId" @dialog-closing="closeDialog">
      <ProductDialog
      :products="contract.products"
      :profitByProduct="profitByProduct"
      :contract="contract"/>
    </Dialog>
  </v-row>
</template>

<script>
import ProductHeaders from '@/headers/Product'
import { numberWithCommas, formatMoney, formatPercentage } from '@/utils/NumericMutations'
import { ContractType, TemplateAccountingCategory, CostType, ActivityModifier, PayBy, TemplateCostType, CertificationStatus } from '@/utils/Enumerations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { uniqueDialogId } from '@/utils/componentHelpers'
import { mapActions } from 'vuex'

export default {
  name: 'ContractInformation',

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    ProductDialog: () => import('@/components/contract/contract-detail/ProductDialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    DetailCard: () => import('@/components/core/DetailCard.vue')
  },

  props: {
    contract: {
      required: true,
      type: Object
    },
    allActivities: {
      type: Array,
      default: undefined,
      required: true
    },
    tractLandowners: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    productDialog: false,
    profitByProduct: undefined,
    dialogId: uniqueDialogId('product-dialog')
  }),

  computed: {
    contractTypeIsWoodsSale () {
      return this.contract.type === ContractType.WoodsSale.value
    },

    contractTypeIsByproduct () {
      return this.contract.type === ContractType.ByproductSale.value
    },

    contractTypeIsByproductPurchase () {
      return this.contract.type === ContractType.ByproductPurchase.value
    },

    productHeaders () { return ProductHeaders.productHeaders(this.$i18n.locale) },

    attributes () {
      const attributes = []

      if (!this.contract.isExternal) {
        attributes.push({
          text: this.$t('internal'),
          icon: 'mdi-home',
          color: 'black'
        })
      }

      if (this.contract.isDraft) {
        attributes.push({
          text: this.contract.hasPendingTickets ? this.$t('hasPendingTickets') : this.$t('draftContract'),
          icon: 'mdi-file',
          color: this.contract.hasPendingTickets ? 'error' : 'black'
        })
      }

      if (this.contract.requiresLogger) {
        attributes.push({
          text: this.$t('requiresLogger'),
          icon: 'mdi-axe',
          color: 'brown'
        })
      }

      if (this.contract.requiresExt1) {
        attributes.push({
          text: this.$t('requiresExt1'),
          icon: 'mdi-pound',
          color: 'info'
        })
      }

      if (this.contract.requiresExt2) {
        attributes.push({
          text: this.$t('requiresExt2'),
          icon: 'mdi-pound',
          color: 'info'
        })
      }

      return attributes
    },

    isLogsContract () {
      return this.contract?.type === ContractType.Production.value || this.contract?.type === ContractType.WoodsSale.value
    },

    contractHasTract () {
      return this.isLogsContract ||
        (this.contract?.type === ContractType.ByproductPurchase.value && this.contract.tract)
    },

    contractHasFromAccount () {
      return !this.isLogsContract && this.contract.fromAccountId
    },

    requiresLoggerText () { return this.contract.requiresLogger ? this.$t('requiresLogger') : this.$t('noLoggerRequired') },

    minCardHeightForPropertiesAndProducts () { if (this.contract.distance > 0 || this.contract.products.length > 4) { return '240px' } else { return '210px' } },

    showPayByLoadWarning () {
      return this.allActivities?.flatMap(a => a.activityDetails).some(ad => ad.cost.payBy === PayBy.Load.value)
    },

    showNonInventoryWarning () {
      return this.allActivities?.some(a => a.activityTemplate.costType === TemplateCostType.nonInventoryCost.value)
    },

    isCertified () {
      return CertificationStatus.isCertified(this.contract.accountCertificationStatus)
    },

    cardColumnWidth () {
      return this.tractLandowners.length > 0 && this.tractLandowners.length < 6 ? 4 : 6
    },

    landownersPanelString () {
      return `${this.tractLandowners.reduce((a, lo) => { return lo.ownership > a.ownership ? lo : a }).accountName}${this.tractLandowners?.length > 1 ? ', ' + this.$t('nMore', { n: this.tractLandowners.length - 1 }) : ''}`
    },

    landownerHeaders () {
      return [
        {
          text: this.$t('name'),
          value: 'accountName'
        },
        {
          text: this.$t('ownership'),
          value: 'ownership',
          align: 'right'
        }
      ]
    }
  },

  created () {
    if (this.contractTypeIsWoodsSale) this.initializeProfitByProductsMap()
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    utcToLocalDate,
    numberWithCommas,
    formatMoney,
    formatPercentage,
    contractTypeFromInt: (type) => ContractType.fromInt(type),

    getProductAtIndex (index) {
      if (this.contract.products.length - 1 >= index - 1) {
        return this.contract.products[index - 1].name
      } else {
        return ''
      }
    },

    getProductProfitRateAtIndex (index) {
      if (this.contract.products.length - 1 >= index - 1) {
        return this.profitByProduct.get(this.contract.products[index - 1].productId)
      }
    },

    initializeProfitByProductsMap () {
      if (this.showPayByLoadWarning) return undefined
      this.profitByProduct = this.allActivities
        .filter(a => a.activityTemplate.costType !== CostType.NonInventory.value)
        .reduce((productMap, activity) => {
          const factor = activity.activityTemplate.category === TemplateAccountingCategory.AccountsReceivable.value ? 1 : -1
          activity.activityDetails.forEach(ad => {
            const amount = activity.activityTemplate.modifier === ActivityModifier.DistanceTraveled.value
              ? ad.cost.rate * this.contract.distance * factor
              : ad.cost.rate * factor

            const existingAmount = productMap.get(ad.product.productId) ?? 0
            const updatedAmount = existingAmount + amount

            productMap.set(ad.product.productId, updatedAmount)
          })
          return productMap
        }, new Map())
    },

    openProductDialog () {
      this.productDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '400' })
    },

    closeDialog () {
      this.productDialog = false
      this.closeDialogsAtOrAbove(this.dialogId)
    }
  }
}
</script>
