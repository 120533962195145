import i18n from '../i18n'

const filterTypes = [
  {
    name: i18n.t('destination'),
    key: 'destination'
  },
  {
    name: i18n.t('tract'),
    key: 'tract'
  },
  {
    name: i18n.t('logger'),
    key: 'logger'
  },
  {
    name: i18n.t('account'),
    key: 'account'
  },
  {
    name: i18n.t('fromAccount'),
    key: 'fromAccount'
  },
  {
    name: i18n.t('product'),
    key: 'product'
  },
  {
    name: i18n.t('defect'),
    key: 'defect'
  },
  {
    name: i18n.t('tractType'),
    key: 'tractType'
  },
  {
    name: i18n.t('region'),
    key: 'region'
  },
  {
    name: i18n.t('landOwner'),
    key: 'landOwner'
  },
  {
    name: i18n.t('certification'),
    key: 'certification'
  },
  {
    name: i18n.t('driver'),
    key: 'driver'
  }
]

export function ticketFiltersForPosting (logsMode) {
  return filterTypes.filter(ft =>
    logsMode
      ? [
        'destination',
        'tract',
        'logger',
        'account'
      ].includes(ft.key)
      : [
        'destination',
        'account',
        'fromAccount'
      ].includes(ft.key)
  )
}

export function ticketFiltersForExport (logsMode) {
  return filterTypes.filter(ft =>
    logsMode
      ? [
        'destination',
        'tract',
        'account'
      ].includes(ft.key)
      : [
        'destination',
        'account',
        'fromAccount'
      ].includes(ft.key)
  )
}

export function ticketFiltersForDashboard (logsMode, includeDestination, additionalFilters = null) {
  var options = [
    'account',
    'product'
  ]
  if (logsMode) {
    options.push(
      'tract',
      'logger'
    )
  } else {
    options.push('fromAccount')
  }
  if (includeDestination) {
    options.push('destination')
  }
  if (additionalFilters) {
    options = options.concat(additionalFilters)
  }
  return filterTypes.filter(ft => options.includes(ft.key))
}
