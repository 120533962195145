import store from '@/store/index.js'
import { SETTING_KEYS, SETTING_TYPES } from '@/utils/UserSettings.js'
import { WidgetType } from '@/utils/Enumerations'

export class DashboardWidget {
  id
  text
  visible
  widgetType
  group
  constructor ({
    id,
    text,
    visible,
    widgetType,
    group
  }) {
    this.id = id
    this.text = text
    this.visible = visible
    this.widgetType = widgetType
    this.group = group
  }
}

const logsWidgets = () => [
  new DashboardWidget({
    id: 11,
    text: 'completedLoadsThisPeriod',
    visible: true,
    widgetType: WidgetType.StatsCard,
    group: 'general'
  }),
  new DashboardWidget({
    id: 18,
    text: 'createdLoadsThisPeriod',
    visible: true,
    widgetType: WidgetType.StatsCard,
    group: 'supplier'
  }),
  new DashboardWidget({
    id: 12,
    text: 'totalTonsThisPeriod',
    visible: true,
    widgetType: WidgetType.StatsCard,
    group: 'general'
  }),
  new DashboardWidget({
    id: 13,
    text: 'percentageTractCertified',
    visible: true,
    widgetType: WidgetType.StatsCard,
    group: 'general'
  }),
  new DashboardWidget({
    id: 14,
    text: 'percentageAccountCertified',
    visible: true,
    widgetType: WidgetType.StatsCard,
    group: 'general'
  }),
  new DashboardWidget({
    id: 15,
    text: 'transferLoadsThisPeriod',
    visible: true,
    widgetType: WidgetType.StatsCard,
    group: 'mill'
  }),
  new DashboardWidget({
    id: 16,
    text: 'trucksInYard',
    visible: true,
    widgetType: WidgetType.StatsCard,
    group: 'mill'
  }),
  new DashboardWidget({
    id: 0,
    text: 'completedLoadsByAccount',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'general'
  }),
  new DashboardWidget({
    id: 1,
    text: 'completedLoadsByLogger',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'general'
  }),
  new DashboardWidget({
    id: 2,
    text: 'completedLoadsByTract',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'general'
  }),
  new DashboardWidget({
    id: 17,
    text: 'completedLoadsByTractType',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'general'
  }),
  new DashboardWidget({
    id: 3,
    text: 'completedLoadsByProduct',
    visible: true,
    widgetType: WidgetType.BarChart,
    group: 'general'
  }),
  new DashboardWidget({
    id: 4,
    text: 'completedLoadsByRegion',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'general'
  }),
  new DashboardWidget({
    id: 25,
    text: 'completedLoadsByDestination',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'general'
  }),
  new DashboardWidget({
    id: 27,
    text: 'completedLoadsByLandOwner',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'general'
  }),
  new DashboardWidget({
    id: 19,
    text: 'createdLoadsByAccount',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'supplier'
  }),
  new DashboardWidget({
    id: 20,
    text: 'createdLoadsByLogger',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'supplier'
  }),
  new DashboardWidget({
    id: 21,
    text: 'createdLoadsByTract',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'supplier'
  }),
  new DashboardWidget({
    id: 22,
    text: 'createdLoadsByTractType',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'supplier'
  }),
  new DashboardWidget({
    id: 23,
    text: 'createdLoadsByProduct',
    visible: true,
    widgetType: WidgetType.BarChart,
    group: 'supplier'
  }),
  new DashboardWidget({
    id: 24,
    text: 'createdLoadsByRegion',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'supplier'
  }),
  new DashboardWidget({
    id: 26,
    text: 'createdLoadsByDestination',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'supplier'
  }),
  new DashboardWidget({
    id: 5,
    text: 'dailyInventoryCost',
    visible: true,
    widgetType: WidgetType.LineChart,
    group: 'mill'
  }),
  new DashboardWidget({
    id: 7,
    text: 'itemizedDefectSummary',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'mill'
  }),
  new DashboardWidget({
    id: 8,
    text: 'defectByAccountSummary',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'mill'
  }),
  new DashboardWidget({
    id: 9,
    text: 'logQualityAnalysis',
    visible: true,
    widgetType: WidgetType.LineChart,
    group: 'mill'
  }),
  new DashboardWidget({
    id: 10,
    text: 'tonsByTractCertification',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'general'
  }),
  new DashboardWidget({
    id: 28,
    text: 'loadsByDriver',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'supplier'
  })
]

const byproductWidgets = () => [
  new DashboardWidget({
    id: 1,
    text: 'completedLoadsThisPeriod',
    visible: true,
    widgetType: WidgetType.StatsCard,
    group: 'general'
  }),
  new DashboardWidget({
    id: 2,
    text: 'completedTonsThisPeriod',
    visible: true,
    widgetType: WidgetType.StatsCard,
    group: 'general'
  }),
  new DashboardWidget({
    id: 3,
    text: 'createdLoadsThisPeriod',
    visible: true,
    widgetType: WidgetType.StatsCard,
    group: 'supplier'
  }),
  new DashboardWidget({
    id: 4,
    text: 'completedLoadsByDestination',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'general'
  }),
  new DashboardWidget({
    id: 5,
    text: 'completedLoadsByProduct',
    visible: true,
    widgetType: WidgetType.BarChart,
    group: 'general'
  }),
  new DashboardWidget({
    id: 8,
    text: 'completedLoadsByAccount',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'general'
  }),
  new DashboardWidget({
    id: 6,
    text: 'createdLoadsByDestination',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'supplier'
  }),
  new DashboardWidget({
    id: 7,
    text: 'createdLoadsByProduct',
    visible: true,
    widgetType: WidgetType.BarChart,
    group: 'supplier'
  }),
  new DashboardWidget({
    id: 9,
    text: 'createdLoadsByAccount',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'supplier'
  }),
  new DashboardWidget({
    id: 10,
    text: 'loadsByDriver',
    visible: true,
    widgetType: WidgetType.StackedBarChart,
    group: 'supplier'
  })
]

const operationalWidgets = () => [
  new DashboardWidget({
    id: 3,
    text: 'incompleteTickets',
    visible: true,
    widgetType: WidgetType.StatsCard
  }),
  new DashboardWidget({
    id: 0,
    text: 'loadsInTransitByDestination',
    visible: true,
    widgetType: WidgetType.StackedBarChart
  }),
  new DashboardWidget({
    id: 1,
    text: 'loadsReadyForPickup',
    visible: true,
    widgetType: WidgetType.StackedBarChart
  }),
  new DashboardWidget({
    id: 2,
    text: 'forecastedDaysRemainingByLogger',
    visible: false,
    widgetType: WidgetType.StackedBarChart
  })
]

export class BaseDashboardWidgetSet {
  widgets = []
  constructor (widgets, settingKey) {
    this.defaultWidgets = [...widgets]
    this.widgets = widgets
    this.settingKey = settingKey
    this.fetchStoredWidgetData()
  }

  fetchStoredWidgetData () {
    let userWidgets = store.getters['user-settings/mutatedUserSettings'][SETTING_TYPES.TABLE][this.settingKey] ?? []
    if (userWidgets.length === 0) userWidgets = this.widgets.map(w => ({ id: w.id, vis: w.visible }))
    if (userWidgets.length < this.widgets.length) {
      const newWidgets = this.widgets.filter(w => !userWidgets.map(uw => uw.id).includes(w.id))
      newWidgets.forEach(w => (w.widgetType === WidgetType.StatsCard)
        ? userWidgets.unshift({ id: w.id, vis: w.visible })
        : userWidgets.push({ id: w.id, vis: w.visible }))
    }
    this.widgets.sort((a, b) => userWidgets.findIndex(w => w.id === a.id) - userWidgets.findIndex(w => w.id === b.id))
    this.widgets.forEach((w, i) => { w.visible = userWidgets[i]?.vis ?? true })
    this.saveWidgetInfo()
  }

  restoreDefaultOrder () {
    this.widgets.sort((a, b) => this.defaultWidgets.findIndex(w => w.id === a.id) - this.defaultWidgets.findIndex(w => w.id === b.id))
  }

  someWidgetShowing (ids = this.widgets.map(widget => widget.id)) {
    if (!Array.isArray(ids)) ids = [ids]
    return ids.some(id => this.widgets.find(w => w.id === id)?.visible)
  }

  saveWidgetInfo () {
    store.commit('user-settings/mutateUserSetting', {
      type: SETTING_TYPES.TABLE,
      key: this.settingKey,
      value: this.widgets.map(w => ({ id: w.id, vis: w.visible }))
    })
  }
}

export class LogsDashboardWidgetSet extends BaseDashboardWidgetSet {
  constructor () {
    super(logsWidgets(), SETTING_KEYS.DASHBOARD_LOG_CHARTS)
  }

  clone () {
    return new LogsDashboardWidgetSet()
  }
}

export class ByproductDashboardWidgetSet extends BaseDashboardWidgetSet {
  constructor () {
    super(byproductWidgets(), SETTING_KEYS.DASHBOARD_BP_CHARTS)
  }

  clone () {
    return new ByproductDashboardWidgetSet()
  }
}

export class OperationalDashboardWidgetSet extends BaseDashboardWidgetSet {
  constructor () {
    super(operationalWidgets(), SETTING_KEYS.DASHBOARD_OPERATIONAL_CHARTS)
  }

  clone () {
    return new OperationalDashboardWidgetSet()
  }
}
