<template>
  <v-container fluid>
    <ShortcutWrapper @n-shortcut="importUsersBtn">
      <v-row>
        <v-col cols="12">
          <v-row dense>
            <v-col>
              <span class="title">
              {{ $t('roleAssignments') }}
              </span>
            </v-col>
            <v-col cols="auto">
              <v-menu bottom left color="primary">
                <v-card class="py-2">
                  <v-list-item @click="generateUserPermissionsPDF" id="download-user-permissions-pdf">
                    <v-avatar><v-icon color="secondary">mdi-file-question</v-icon></v-avatar>
                    <v-list-item-title>{{$t('userPermissionsPDF')}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="generateUsersByRolePDF(claimsByUser)" id="download-users-by-role-pdf">
                    <v-avatar><v-icon color="secondary">mdi-file-account</v-icon></v-avatar>
                    <v-list-item-title>{{$t('usersByRoleAssignmentsPDF')}}</v-list-item-title>
                  </v-list-item>
                </v-card>
                <template #activator="{on}">
                  <v-icon v-on="on" color="primary">mdi-dots-vertical</v-icon>
                </template>
              </v-menu>
            </v-col>
            <v-col cols="auto">
              <Icon
              icon="mdi-plus"
              :small="false"
              :tooltipText="$t('importUsers')"
              @icon-clicked="importUsersBtn"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
              dense
              item-key="value"
              show-expand
              single-expand
              style="cursor: pointer"
              :items="allRoles"
              :headers="headers"
              :expanded.sync="expanded"
              @click:row="rowClicked"
              hide-default-footer>
                <template #expanded-item="{ headers, item}">
                  <td class="grey lighten-5" :colspan="headers.length+1">
                    <RolesUserBreakdown
                    :users="usersWithClaim(item)"
                    :role="item"
                    :allUsers="allUsers"
                    :claimsByUser="claimsByUser"
                    @assign-user="assignUserToRole"
                    @refresh-roles="$emit('refresh-roles')"/>
                  </td>
                </template>
                <template #item.name="{item}">
                  <v-row justify="space-between" dense :style="$vuetify.breakpoint.xs ? 'width:auto;' : 'width:200px;'">
                    <v-col cols="auto">
                      <span class="subtitle-1">
                        {{ $t(item.label) }}
                      </span>
                    </v-col>
                    <v-col cols="auto">
                      <Icon
                      iconColor="info"
                      v-if="!$vuetify.breakpoint.xs && !isTouchScreen"
                      icon="mdi-information-outline"
                      :small="false"
                      :tooltipText="$t(`${item.label}Description`)"/>
                      <v-icon v-if="$vuetify.breakpoint.xs || isTouchScreen" :id="`${item.label}-activator`" color="info">mdi-information-outline</v-icon>
                      <v-menu v-if="$vuetify.breakpoint.xs || isTouchScreen" :activator="`#${item.label}-activator`" :close-on-content-click="false" offset-y="12px" tile>
                        <v-card color="info" max-width="200" elevation="0">
                          <v-card-text class="white--text subtitle-1"> {{$t(`${item.label}Description`)}} </v-card-text>
                        </v-card>
                      </v-menu>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="importUsersDialog" width="1200px">
        <ImportUsers
        v-if="importUsersDialog"
        @close="closeImportUsersDialog"
        @usersImported="$emit('refresh-roles')"/>
      </v-dialog>
    </ShortcutWrapper>
  </v-container>
</template>

<script>
import { UserClaims, UserClaimStatus } from '@/utils/Enumerations.js'
import RolesHeaders from '@/headers/Roles.js'
import { generateUserPermissionsPDF } from '@/components/settings-components/user-permissions/UserPermissionsPDF.js'
import { generateUsersByRolePDF } from '@/components/settings-components/user-permissions/UsersByRolePDF.js'
export default {
  name: 'UsersByRole',

  components: {
    RolesUserBreakdown: () => import('@/components/settings-components/user-permissions/RolesUserBreakdown.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ImportUsers: () => import('@/components/settings-components/user-permissions/ImportUsers.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue')
  },

  props: {
    claimsByUser: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    expanded: [],
    allUsers: [],
    importUsersDialog: false,
    isTouchScreen: navigator.maxTouchPoints > 0
  }),

  computed: {
    allRoles () {
      return UserClaims.enums
    },

    headers () {
      return RolesHeaders.usersByRoleHeaders()
    }
  },

  methods: {
    generateUserPermissionsPDF,
    generateUsersByRolePDF,
    usersWithClaim (claim) {
      return this.claimsByUser.filter(u => u.userClaims?.some(c => {
        return (c.systemClaim === claim.value && c.value === UserClaimStatus.Allow.value)
      }))
    },

    rowClicked (item) {
      this.$nextTick(() => {
        if (this.expanded.includes(item)) {
          this.expanded.splice(this.expanded.indexOf(item))
        } else {
          this.expanded = [item]
        }
      })
    },

    assignUserToRole (role) {
      this.$emit('assign-user', role)
    },

    importUsersBtn () {
      this.importUsersDialog = true
    },

    closeImportUsersDialog () {
      this.importUsersDialog = false
    }
  }
}
</script>
