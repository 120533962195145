<template>
  <v-card data-testid="reciever-settings-detail" min-height="90vh">
    <v-card-title class="primary">
      <span class="tertiary--text headline">{{propReceiverSettings.name}}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh>
        <template #left>
          <Icon
            icon="mdi-pencil"
            :tooltipText="$t('modifyReceiverSettings', { modify: $t('edit')})"
            iconColor="white"
            :small="false"
            @icon-clicked="$emit('edit-receiver-settings', propReceiverSettings)"
            :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
          />
        </template>
      </BaseDialogActions>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-tabs
          v-model="activeTab">
          <v-tab>{{ $t('loadCreation') }}</v-tab>
          <v-tab>{{ $t('scale') }}</v-tab>
          <v-tab>{{ $t('printer') }}</v-tab>
          <v-tab>{{ $t('defectCollection') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab" class="mt-1" touchless>
          <v-tab-item>
            <v-container fluid class="cancel-icons">
              <LoadCreationSettings
                :propSettings="settings.loadCreationSetting"
                :displayDetails="displayDetails"
                :isEditing="false"
              />
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container fluid>
              <ScaleSettings
                :scales="settings.scaleSettings"
              />
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container fluid class="cancel-icons">
              <PrinterSettings
              :propPrinter="settings.printerSetting"
              :displayDetails="displayDetails"
              :isEditing="false"
              />
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container fluid>
              <DefectCollectionSettings
              :propDefectSettings="settings.defectCollectionSetting"
              :isEditing="false"
              />
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { UserClaims, ReceiverScaleConnectionTypes, ReceiverLoadCreationTypes, ReceiverByproductSaleContractFilterTypes, ReceiverLogContractFilterTypes, ReceiverByproductPurchaseContractFilterTypes, ReceiverLogContractLoadCountTimeFilter, ReceiverDefectCollectionBehavior, ReceiverDefectCollectionUnit, SystemDayOfWeek } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
export default {
  name: 'ReceiverSettingsForm',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    ScaleSettings: () => import('@/components/settings-components/company-config/receiver-settings/ScaleSettings.vue'),
    PrinterSettings: () => import('@/components/settings-components/company-config/receiver-settings/PrinterSettingsForm.vue'),
    LoadCreationSettings: () => import('@/components/settings-components/company-config/receiver-settings/LoadCreationSettingsForm.vue'),
    DefectCollectionSettings: () => import('@/components/settings-components/company-config/receiver-settings/DefectCollectionSettingsForm.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    propReceiverSettings: {
      type: Object,
      required: true
    }
  },

  created () {
    if (this.propReceiverSettings) {
      const set = JSON.parse(JSON.stringify(this.propReceiverSettings))
      set.loadCreationSetting.receiverLoadCreationType = ReceiverLoadCreationTypes.forInt(set.loadCreationSetting.receiverLoadCreationType)
      set.loadCreationSetting.logContractLoadCountTimeFilter = ReceiverLogContractLoadCountTimeFilter.forInt(set.loadCreationSetting.logContractLoadCountTimeFilter)
      set.loadCreationSetting.logContractFilterType = ReceiverLogContractFilterTypes.forInt(set.loadCreationSetting.logContractFilterType)
      set.loadCreationSetting.receiverByproductSaleContractFilterType = ReceiverByproductSaleContractFilterTypes.forInt(set.loadCreationSetting.byproductContractFilterType)
      set.loadCreationSetting.receiverByproductPurchaseContractFilterType = ReceiverByproductPurchaseContractFilterTypes.forInt(set.loadCreationSetting.byproductLoadCreationType)
      set.loadCreationSetting.loadCountDurationStartOfWeek = SystemDayOfWeek.forInt(set.loadCreationSetting.loadCountDurationStartOfWeek)

      set.defectCollectionSetting.collectionBehavior = ReceiverDefectCollectionBehavior.forInt(set.defectCollectionSetting.collectionBehavior)
      set.defectCollectionSetting.collectionUnit = ReceiverDefectCollectionUnit.forInt(set.defectCollectionSetting.collectionUnit)

      set.scaleSettings.forEach(scale => {
        scale.connectionType = ReceiverScaleConnectionTypes.forInt(scale.connectionType)
      })
      this.settings = set
    }
  },

  computed: {
    displayDetails () {
      return {
        logs: this.settings.loadCreationSetting.receiverLoadCreationType.value === ReceiverLoadCreationTypes.LogsOnly.value,
        both: this.settings.loadCreationSetting.receiverLoadCreationType.value === ReceiverLoadCreationTypes.LogsAndByproducts.value,
        byproducts: this.settings.loadCreationSetting.receiverLoadCreationType.value === ReceiverLoadCreationTypes.ByproductsOnly.value,
        byproductDelivery: this.settings.loadCreationSetting.createByproductDeliveryLoads,
        byproductPickup: this.settings.loadCreationSetting.createByproductPickupLoads,
        byproductPurchase: this.settings.loadCreationSetting.createByproductPurchaseLoads
      }
    }
  },

  data: () => ({
    activeTab: 0,
    settings: {},
    UserClaims
  }),

  methods: {
    userAssignedClaim
  }
}
</script>

<style scoped>
  .cancel-icons :deep(.v-icon.mdi-close) {
    color: inherit;
  }
</style>
