<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="auto">
        <Selector
        :label="$t('entities')"
        class="mr-4"
        :items="entities"
        @item-selected="entitySelected"
        minWidth="150px"
        style="max-width:360px"
        itemText="name"/>
      </v-col>
      <v-col class="fill-width">
        <v-row style="max-width: 650px; min-width: 320px;" align="center">
          <v-col>
            <DatePicker
            dataTestId="standingTimberDate"
            :dateLabel="$t('asOf')"
            @date-picked="dateChosen"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn @click="refreshTracts" :disabled="applyButtonDisabled" class="primary">
              {{$t('apply')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer style="max-width: 20px;"/>
      <v-col cols="auto" class="mb-6">
        <ColumnAdjuster
        :propColumnSet.sync="columnSet"
        @new-columns-selected="newColumnsSelected"
        />
        <Icon
        icon="mdi-file-delimited-outline"
        dataTestId="timber-download-button"
        :tooltipText="$t('downloadCSV')"
        @icon-clicked="downloadCSV"
        :small="false"/>
        <Icon
        icon="mdi-refresh"
        dataTestId="refresh-icon-standing-timber"
        :tooltipText="$t('refresh')"
        @icon-clicked="refreshTracts"
        :small="false"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="auto">
        <v-card flat :loading="tractsLoading || entitiesLoading" :style="totalsStyle">
          <v-card-title>
            {{$t('totals')}}
          </v-card-title>
          <v-card-text class="font-weight-bold">
            <v-row dense>
              <v-col>{{$t('expectedTons')}}</v-col>
              <v-col class="text-right">{{numberWithCommas(totals.expected, 3)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('harvestedTons')}}</v-col>
              <v-col class="text-right">{{numberWithCommas(totals.harvested, 3)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('remainingTons')}}</v-col>
              <v-col class="text-right">{{numberWithCommas(totals.remaining, 3)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('overcutTons')}}</v-col>
              <v-col class="text-right">{{numberWithCommas(totals.overcut, 3)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('cruisedValue')}}</v-col>
              <v-col class="text-right">{{formatMoney(totals.cruisedValue)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('cost')}}</v-col>
              <v-col class="text-right">{{formatMoney(totals.tractCost)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('stumpageValue')}}</v-col>
              <v-col class="text-right">{{formatMoney(totals.stumpageValue)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{$t('averageCutoutValue')}}</v-col>
              <v-col class="text-right">{{formatMoney(totals.cutoutValue)}}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{ $t('costBalance') }}</v-col>
              <v-col class="text-right">{{ formatMoney(totals.costBalance) }}</v-col>
            </v-row>
            <v-row dense>
              <v-col>{{ $t('depletionBalance') }}</v-col>
              <v-col class="text-right">{{ formatMoney(totals.depletionBalance) }}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="" class="fill-width" style="min-width: 0;">
        <DataTable
        :items="standingTimber"
        :customCells="customCells"
        :headers="headers"
        :loading="tractsLoading || entitiesLoading"
        @filtered-items="setTotals"
        resize
        defaultHeight="calc(100vh - 647px)">
          <template #custom-controls>
            <v-col cols="auto">
              <Icon
              @icon-clicked="myTractsClicked"
              :small="false"
              icon="mdi-account-circle"
              margin="mx-2"
              :tooltipText="standingTimberFilter.myTracts ? $t('allTracts') : $t('myTracts')"
              elementId="my-tracts-standing-timber"/>
            </v-col>
            <v-col cols="auto">
              <StandingTimberFilter
              @input="applyFilters"
              />
            </v-col>
          </template>
          <template #filters>
            <v-chip v-for="fk in activeFilters" :key="fk.key" color="secondary" class="white--text mr-1 mb-1" style="height: auto; min-height: 32px;" close @click:close="removeFilterField(fk.key)">
              <span style="font-weight: bold;">{{$t(fk.label)}}:</span>
              <span v-if="!!fk.text" style="white-space: pre-wrap" class="ml-1">{{fk.text}}</span>
            </v-chip>
          </template>
          <template #status="{item}">
            <TractStatusIcon :status="item.status"/>
            <Icon
            v-if="dateIsUnderCountUnitsAway(item.harvestEndDate, 1, 'week')"
            icon="mdi-alert-outline"
            iconColor="warning"
            :tooltipText="$t('tractHarvestWarning1w')"
            />
            <Icon
            v-if="item.paused"
            icon="mdi-pause-circle"
            iconColor="warning"
            :tooltipText="$t('tractPaused')"
            />
          </template>
          <template #actions="{item}">
            <Icon
            icon="mdi-information-outline"
            :dataTestId="`timber-${item.name}`"
            :small="false"
            :tooltipText="$t('viewDetailedBreakdown')"
            @icon-clicked="openDetailDialog(item)"/>
          </template>
          <template #balance="{item}">
            <v-tooltip
            color="primary"
            open-on-hover
            bottom
            v-if="item.balanceUpdatedOn">
              <template v-slot:activator="{on}">
                <span v-on="on">
                  {{ balanceTextForTract(item) }}
                </span>
              </template>
              <span class="subtitle-1 white--text" style="white-space: pre-line">
                {{ $t('balanceCaptured', { updatedOn: dateStringToLocal(item.balanceUpdatedOn) }) }}
              </span>
            </v-tooltip>
            <span v-else>
              {{ $t('notAvailable') }}
            </span>
          </template>
          <template #costBalance="{item}">
            <v-tooltip
            color="primary"
            open-on-hover
            bottom
            v-if="item.balanceUpdatedOn">
              <template v-slot:activator="{on}">
                <span v-on="on">
                  {{ costBalanceTextForTract(item) }}
                </span>
              </template>
              <span class="subtitle-1 white--text" style="white-space: pre-line">
                {{ $t('balanceCaptured', { updatedOn: dateStringToLocal(item.balanceUpdatedOn) }) }}
              </span>
            </v-tooltip>
            <span v-else>
              {{ $t('notAvailable') }}
            </span>
          </template>
          <template #team="{item}">
            <v-row no-gutters>
              <v-col cols="auto">
                <span>{{ item.teamName ?? $t('notAvailable') }}</span>
              </v-col>
              <v-col cols="auto">
                <Icon
                v-if="!!item.teamName"
                iconColor="black"
                icon="mdi-dots-horizontal"
                margin="ml-1"
                :tooltipText="getTeamTooltip(item.tractUserNames)"
                />
              </v-col>
            </v-row>
          </template>
        </DataTable>
      </v-col>
      <v-col cols="12">
        <v-row justify="end">
          <span class="font-italic">{{$t('tractHarvestTicketStatusDenotation')}}</span>
        </v-row>
      </v-col>
    </v-row>
    <Dialog :stateId="dialogId" @dialog-closing="resetDialogs">
      <TractDetail
      v-if="focusedTractId"
      :tractId="focusedTractId"
      @edit-tract="editTract"
      :asOfDate="localToUTC(asOfDate)"
      :tabToOpen="1"/>
    </Dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import RouterJump from '@/model/RouterJump.js'
import standingTimberHeaders from '@/headers/Tract.js'
import { formatMoney, numberWithCommas } from '@/utils/NumericMutations.js'
import { TractStatus } from '@/utils/Enumerations'
import { generateCsvString } from '@/utils/CSVUtility'
import { localToUTC } from '@/utils/DateFormatter.js'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { uniqueDialogId } from '@/utils/componentHelpers'
import { dateIsUnderCountUnitsAway } from '@/utils/DateFormatter'

export default {
  name: 'StandingTimber',

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    TractDetail: () => import('@/components/tract/tract-detail/TractDetail.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    Selector: () => import('@/components/core/Selector.vue'),
    StandingTimberFilter: () => import('./StandingTimberFilter.vue'),
    ColumnAdjuster: () => import('@/components/core/ColumnAdjuster.vue'),
    TractStatusIcon: () => import('@/components/tract/TractStatusIcon.vue')
  },

  data: () => ({
    dialogId: uniqueDialogId('standing-timber'),
    applyButtonDisabled: true,
    focusedTractId: null,
    selectedEntity: {
      name: 'All',
      businessEntityId: null
    },
    standingTimber: [],
    filters: {
      status: [],
      tractForester: [],
      tractType: [],
      team: [],
      myTracts: false
    },
    asOfDate: new Date().toISOString(),
    totals: {
      expected: 0,
      harvested: 0,
      overcut: 0,
      cruisedValue: 0,
      tractCost: 0,
      stumpageValue: 0,
      cutoutValue: 0,
      depletionBalance: 0
    },
    columnSet: null
  }),

  computed: {
    ...mapGetters('user', ['businessEntities', 'entitiesLoading']),
    ...mapGetters('tract', ['tractsLoading', 'standingTimberFilter']),
    ...mapGetters('tract-type', ['allTractTypes']),
    headers () {
      return this.columnSet?.getColumns() ?? []
    },

    customCells () {
      return [
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'balance',
          value: 'balance'
        },
        {
          slotName: 'team',
          value: 'teamName'
        },
        {
          slotName: 'status',
          value: 'status'
        },
        {
          slotName: 'costBalance',
          value: 'costBalance'
        }
      ]
    },

    entities () {
      if (!this.businessEntities) return []
      const businessEntities = JSON.parse(JSON.stringify(this.businessEntities))
      businessEntities.unshift({
        name: 'All',
        businessEntityId: null
      })
      return businessEntities
    },

    activeFilters () {
      const keys = Object.keys(this.standingTimberFilter)
      return keys.map(key => ({
        key,
        label: key === 'tractForester' ? 'forester' : key,
        filter: this.standingTimberFilter[key],
        text: this.getFilterChipText(key, this.standingTimberFilter[key])
      }))
        .filter(kf => {
          switch (kf.label) {
            case 'myTracts':
              return kf.filter === true
            case 'forester':
            case 'tractType':
            case 'team':
            case 'status':
            default:
              return kf.filter.length > 0
          }
        })
    },

    totalsStyle () {
      return {
        minWidth: '240px',
        maxWidth: '320px',
        width: this.$vuetify.breakpoint.mdAndDown ? '100vw' : '15vw'
      }
    }
  },

  watch: {
    asOfDate () {
      this.applyButtonDisabled = false
    },

    standingTimberFilter: {
      handler () {
        this.refreshTracts()
      },
      deep: true
    }
  },

  async created () {
    this.initializeHeaders()
    await this.fetchAllBusinessEntities()
    this.setTotals()
  },

  methods: {
    ...mapActions('tract', ['fetchStandingTimber', 'setStandingTimberFilter']),
    ...mapActions('user', ['fetchAllBusinessEntities']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapMutations('global', ['setRouterJump']),
    formatMoney,
    numberWithCommas,
    generateCsvString,
    localToUTC,
    dateIsUnderCountUnitsAway,
    async entitySelected (entity) {
      if (JSON.stringify(entity) === JSON.stringify(this.selectedEntity)) return
      this.selectedEntity = entity
      await this.refreshTracts()
    },

    initializeHeaders () {
      this.columnSet = standingTimberHeaders.standingTimberHeaders()
    },

    dateChosen (date) {
      this.asOfDate = date
    },

    newColumnsSelected (columnSet) {
      this.columnSet = columnSet
    },

    async refreshTracts () {
      this.standingTimber = await this.fetchStandingTimber({
        asOfDate: localToUTC(this.asOfDate),
        businessEntityId: this.selectedEntity.businessEntityId,
        statuses: this.standingTimberFilter.status,
        tractTypes: this.standingTimberFilter.tractType,
        tractForesters: this.standingTimberFilter.tractForester,
        teams: this.standingTimberFilter.team,
        myTracts: this.standingTimberFilter.myTracts
      })
      this.applyButtonDisabled = true
    },

    ignoreFilter (filter) {
      return (filter.tractType.length === 0 && filter.status.length === 0)
    },

    applyFilters (filters) {
      this.filters = filters
    },

    getFilterChipText (key, filter) {
      switch (key) {
        case 'status':
          return filter.map(f => TractStatus[f].name).join(', ')
        case 'tractType':
        case 'tractForester':
        case 'team':
          return filter.map(val => val.name).join(', ')
        case 'myTracts':
        default:
          return ''
      }
    },

    removeFilterField (fieldKey) {
      let removedValue = []
      switch (fieldKey) {
        case 'myTracts':
          removedValue = false
          break
        case 'status':
        case 'tractType':
        case 'tractForester':
        case 'team':
        default:
          removedValue = []
          break
      }

      const f = {
        ...this.standingTimberFilter,
        [fieldKey]: removedValue
      }

      this.setStandingTimberFilter(f)
    },

    downloadCSV () {
      const csvString = this.generateCsvString(this.standingTimber, [
        { label: 'tract', value: st => st.tractName },
        { label: 'status', value: st => TractStatus.find(ts => ts.value === st.status).name },
        { label: 'type', value: st => st.tractType },
        { label: 'team', value: st => st.teamName },
        { label: 'forester', value: st => st.tractForester },
        { label: 'cruisedValue', value: st => st.cruisedValue },
        { label: 'stumpageValue', value: st => st.stumpageValue },
        { label: 'cost', value: st => st.tractCost },
        { label: 'costBalance', value: st => Math.max(0, st.balance) },
        { label: 'depletionBalance', value: st => st.balance },
        { label: 'overcutTons', value: st => st.overcutTons },
        { label: 'averageCutoutValue', value: st => st.cutoutValue.toFixed(2) },
        { label: 'acres', value: st => st.tractAcres },
        { label: 'harvestedTons', value: st => st.harvestedTons },
        { label: 'expectedTons', value: st => st.expectedTons },
        { label: 'remainingTons', value: st => st.remainingTons }
      ])
      const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `Standing-Timber-${moment().format()}.csv`)
    },

    openDetailDialog (tract) {
      this.focusedTractId = tract.tractId
      this.openOrUpdateDialog({ id: this.dialogId, width: '90vw' })
    },

    editTract () {
      const routerJump = new RouterJump('StandingTimber', 'Tracts', {
        tractId: this.focusedTractId
      })
      this.setRouterJump(routerJump)
      this.$router.push('tracts')
    },

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.focusedTractId = null
    },

    setTotals (filteredTimber) {
      const timber = filteredTimber || this.standingTimber
      const totals = timber.reduce((totals, tract) => {
        totals.expected += tract.expectedTons
        totals.harvested += tract.harvestedTons
        totals.overcut += tract.overcutTons
        totals.cruisedValue += tract.cruisedValue
        totals.tractCost += tract.tractCost
        totals.stumpageValue += tract.stumpageValue
        totals.cutoutValue += tract.cutoutValue
        totals.depletionBalance += tract.balance ?? 0
        totals.costBalance += tract.costBalance ?? 0
        return totals
      }, {
        expected: 0,
        harvested: 0,
        overcut: 0,
        cruisedValue: 0,
        tractCost: 0,
        stumpageValue: 0,
        cutoutValue: 0,
        depletionBalance: 0,
        costBalance: 0
      })
      const remainingTons = totals.expected - totals.harvested
      totals.remaining = remainingTons < 0 ? 0 : remainingTons

      this.totals = totals
    },

    dateStringToLocal (field) {
      return moment.utc(field).local().format('L LT')
    },

    balanceTextForTract (tract) {
      return tract.balanceUpdatedOn ? formatMoney(tract.balance || 0) : this.$t('notAvailable')
    },

    costBalanceTextForTract (tract) {
      return tract.balanceUpdatedOn ? formatMoney(Math.max(tract.costBalance, 0)) : this.$t('notAvailable')
    },

    myTractsClicked () {
      this.setStandingTimberFilter({
        ...this.standingTimberFilter,
        myTracts: !this.standingTimberFilter.myTracts
      })
    },

    getTeamTooltip (tractUsers) {
      return tractUsers
        .toString()
        .replaceAll(',', '\n')
    }
  }

}
</script>
