<template>
  <v-card min-height="90vh" id="tract-form">
    <v-card-title class="primary white--text">
      <span class="mr-2">{{titleText}}</span>
      <TractStatusIcon
      v-if="tract"
      :status="tract.status"
      isHeader
      />
      <v-spacer></v-spacer>
      <v-btn
      v-if="isEditing"
      text
      dense
      color="tertiary"
      @click="editTractStatusDialog = true">
        <span>{{$t('editStatus')}}</span>
        <v-icon small right color="tertiary">mdi-pencil</v-icon>
      </v-btn>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row v-if="!tract" class="mt-6" justify="center" align="center">
          <v-progress-circular indeterminate color="primary"/>
        </v-row>
        <v-row v-else>
          <v-col :cols="12" :lg="isEditing && !hideNotes ? 10 : 12">
            <v-tabs v-model="currentTab" color="primary">
              <v-tab data-testid="tract-form-general-tab">{{$t('general')}}</v-tab>
              <v-tab data-testid="tract-form-geography-tab">{{$t('geography')}}</v-tab>
              <v-tab data-testid="tract-form-cruise-tab" v-if="isEditing">{{$t('cruise')}}</v-tab>
              <v-tab data-testid="tract-form-operational-tab" v-if="isEditing">{{$t('operational')}}</v-tab>
              <v-tab data-testid="tract-form-contracts-tab" v-if="isEditing">{{$t('contracts')}}</v-tab>
              <v-tab data-testid="tract-form-files-tab" v-if="isEditing">{{ $t('files') }}</v-tab>
              <v-spacer/>
              <v-btn icon v-if="isEditing && $vuetify.breakpoint.lgAndUp" class="ma-0">
                <Icon
                :small="false"
                :icon="hideNotes ? 'mdi-book-outline' : 'mdi-book-off-outline'"
                :tooltipText="notesVisibilityTooltip"
                @icon-clicked="hideNotes = !hideNotes"
                />
              </v-btn>

              <v-tab-item disabled>
                <GeneralInformation
                :propOriginalTract="originalTract"
                :propTract="tract"
                :propLandowners="landowners"
                @apply-to-contracts-toggled="applyToContractsToggled"
                @tract-changed="generalInformationMutated"
                @cost-set="tract.cost = $event"
                @ownership-changed="ownershipMutated"/>
              </v-tab-item>

              <v-tab-item disabled>
                <TractGeography :propTract="tract" :isEditing="isEditing" @tract-changed="geographyMutated" @shapefile="shapeFileUpload" @geojson="geojsonUploaded"/>
              </v-tab-item>

              <v-tab-item v-if="isEditing" disabled>
                <CruiseData
                  :flat="true"
                  :tract="tract"
                  @tract-contents-updated="tractContentAdded"
                />
              </v-tab-item>

              <v-tab-item v-if="isEditing" disabled>
                <Operational
                :tract="tract"
                :landowners="landowners"
                :refreshTractDestinations="refreshTractDestinations"
                @refresh-tract-contracts="refreshTractContracts = true"
                @tract-destinations-refreshed="refreshTractDestinations = false"
                @open-cruise-tab="openCruiseTab"
                />
              </v-tab-item>

              <v-tab-item v-if="isEditing" disabled>
                <TractContracts
                :tractId="tract.tractId"
                :refreshTractContracts="refreshTractContracts"
                @refresh-tract-destinations="refreshTractDestinations = true"
                @tract-contracts-refreshed="refreshTractContracts = false"
                />
              </v-tab-item>

              <v-tab-item disabled>
                <TractFiles :tract="tract" :isEditing="isEditing"/>
              </v-tab-item>

            </v-tabs>
          </v-col>
          <v-col class="mt-6" cols="12" lg="2" v-if="isEditing && (!hideNotes || $vuetify.breakpoint.mdAndDown)">
            <TractNotes
            :tractId="tract.tractId"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-container fluid class="px-0">
        <v-row justify="end" align="center" dense>
          <v-col cols="auto">
            <v-btn
            v-if="isEditing"
            class="primary"
            @click="trySaveTract(true)">
              {{ $t('save') }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
            data-testid="tract-form-save-btn"
            class="primary"
            @click="trySaveTract()"
            >
              {{saveBtnText}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
    <v-dialog v-model="editTractStatusDialog" width="400">
      <EditTractStatus
      v-if="tract"
      :tract="tract"
      :propStatus="tract.status"
      @close="closeEditStatusForm"/>
    </v-dialog>
    <v-dialog
      v-if="confirmSubmitDialog"
      v-model="confirmSubmitDialog"
      width="400px"
    >
      <ConfirmDialog
        :title="$t('confirmSaveChanges')"
        :body="$t('confirmTractUpdateMessage')"
        color="primary"
        :confirmText="$t('confirm')"
        @confirm="saveTract"
        @cancel="confirmSubmitDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getDefaultTract } from '@/components/tract/tract-form/FormHelpers.js'
import { localToUTC } from '@/utils/DateFormatter.js'
import { TractStatus } from '@/utils/Enumerations.js'
import { shapefileClient } from '../../../utils/Shapefiles'
import * as rules from '@/utils/rules.js'
import { formatPercentage } from '../../../utils/NumericMutations'
import { TractTypeCategory } from '../../../utils/Enumerations'

export default {
  name: 'TractForm',

  props: {
    tractId: {
      type: Number,
      default: null
    }
  },

  components: {
    GeneralInformation: () => import('@/components/tract/tract-form/GeneralInformation.vue'),
    CruiseData: () => import('@/components/tract/cruise-data/CruiseData.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    TractGeography: () => import('@/components/tract/tract-form/Geography.vue'),
    Operational: () => import('@/components/tract/tract-form/Operational.vue'),
    TractStatusIcon: () => import('@/components/tract/TractStatusIcon.vue'),
    TractNotes: () => import('@/components/tract/tract-detail/TractNotes.vue'),
    EditTractStatus: () => import('@/components/tract/tract-form/EditTractStatus.vue'),
    TractContracts: () => import('@/components/tract/tract-detail/TractContracts.vue'),
    TractFiles: () => import('@/components/tract/tract-form/TractFiles.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    tract: null,
    originalTract: {},
    currentTab: 0,
    applyDatesToContracts: false,
    editTractStatusDialog: false,
    shapefile: null,
    geojsonFile: null,
    confirmSubmitDialog: false,
    refreshTractContracts: false,
    refreshTractDestinations: false,
    hideNotes: false,
    landowners: [],
    originalLandowners: []
  }),

  computed: {
    ...mapGetters('user-settings', ['mutatedUserSettings']),
    ...mapGetters('user', ['companyInfo']),

    isEditing () {
      return (this.tractId !== null && this.tractId !== undefined) ||
        (this.tract?.tractId !== undefined && this.tract?.tractId !== null)
    },

    hasEnteredLocation () {
      if (this.isEditing) return true

      return this.tract.spot !== null
    },

    saveBtnText () {
      if (this.hasEnteredLocation) {
        return this.isEditing ? this.$t('saveAndClose') : this.$t('save')
      }

      return this.$t('chooseLocation')
    },

    statusText () {
      if (this.tract === null) return ''
      return TractStatus.find(t => t.value === this.tract.status).name
    },

    titleText () { return this.isEditing ? this.$t('editing', { name: this.tract?.name }) : this.$t('createNewTract') },

    notesVisibilityTooltip () {
      return this.$t(this.hideNotes ? 'show' : 'hide').concat(` ${this.$t('tractNotes')}`)
    },

    tractChangesWillUpdateContracts () {
      /* eslint-disable */
      // Disabled in order to compare null and undefined with type conversion
      return this.isEditing &&
        (this.applyDatesToContracts ||
        (this.tract.loggerAccountId != this.originalTract.loggerAccountId) ||
        (this.tract.haulerAccountId != this.originalTract.haulerAccountId) ||
        (this.tract.supplierAccountId != this.originalTract.supplierAccountId) ||
        (this.tract.consultingForesterAccountId != this.originalTract.consultingForesterAccountId) ||
        (this.landownersMutated) ||
        (this.tract.type?.depletes !== this.originalTract.type?.depletes))
      /* eslint-enable */
    },

    landownersMutated () {
      if (this.originalLandowners.length !== this.landowners.length) return true
      return !this.originalLandowners.every(original => this.landowners.some(owner => original.accountId === owner.accountId && original.ownership === owner.ownership))
    }
  },

  created () {
    this.getTract(this.tractId)
    this.getLandowners(this.tractId)
  },

  beforeDestroy () {
    this.updateUserSettings(this.mutatedUserSettings)
  },

  methods: {
    ...mapActions('tract', ['getTractWithId', 'createTract', 'updateTract', 'fetchTractLandowners']),
    ...mapActions('user-settings', ['updateUserSettings']),
    async getTract (tractId) {
      if (tractId) {
        const tractFromServer = await this.getTractWithId(tractId)
        tractFromServer.foresterUserId = tractFromServer.foresterUser?.applicationUserId
        tractFromServer.loggerIds = tractFromServer.loggers?.map(l => l.accountId)
        tractFromServer.userIds = tractFromServer.users?.map(u => u.applicationUserId)
        tractFromServer.certificationIds = tractFromServer.tractCertifications?.map(t => t.certificationId)
        this.tract = JSON.parse(JSON.stringify(tractFromServer))
        this.originalTract = JSON.parse(JSON.stringify(tractFromServer))
      } else {
        this.tract = getDefaultTract()
      }
    },

    async getLandowners (tractId) {
      if (!tractId) return
      this.landowners = await this.fetchTractLandowners(tractId)
      this.originalLandowners = JSON.parse(JSON.stringify(this.landowners))
    },

    applyToContractsToggled (shouldApplyDates) {
      this.applyDatesToContracts = shouldApplyDates
    },

    tractContentAdded (tractContents) {
      this.tract.tractContents = tractContents
    },

    closeEditStatusForm (newStatus) {
      if (newStatus !== undefined) {
        this.getTract(this.tractId)
      }

      this.editTractStatusDialog = false
    },

    trySaveTract (saveSilently = false) {
      if (this.companyInfo.requireApprovalForContractModifications && this.tractChangesWillUpdateContracts) {
        this.confirmSubmitDialog = true
      } else {
        this.saveTract(saveSilently)
      }
    },

    async saveTract (saveSilently = false) {
      if (!this.hasEnteredLocation) {
        this.currentTab = 1
        return
      }

      if (!this.verifyRequest(this.tract) || !this.verifyLandowners(this.landowners)) {
        return
      }

      const request = this.getTractRequestObject(this.tract, this.landowners)

      let tractId = this.tract.tractId

      if (this.isEditing) {
        await this.updateTract(request)
        if (!saveSilently) this.$emit('close')
      } else {
        const res = await this.createTract(request)
        this.tract.tractId = res.tractId
        tractId = res.tractId
      }

      if (this.shapefile) {
        try {
          shapefileClient.uploadShapefile(tractId, this.shapefile)
        } catch (e) {
          return
        }
      }

      if (this.geojsonFile) {
        try {
          shapefileClient.uploadGeojson(tractId, this.geojsonFile)
        } catch (e) {
          return
        }
      }

      this.getTract(tractId)
    },

    verifyLandowners (landowners) {
      const zeroOwnershipAccount = landowners.find(landowner => landowner.ownership === 0)
      if (zeroOwnershipAccount !== undefined) {
        this.setSnackError(this.$t('accountAssignedZeroOwnership', { account: zeroOwnershipAccount.accountName }))
        return false
      }

      if (landowners.some(landowner => landowner.accountId === undefined)) {
        this.setSnackError(this.$t('allLandownersMustBeSet'))
        return false
      }

      const totalOwnership = landowners.reduce((ownership, lo) => ownership + lo.ownership, 0)
      if (Number(totalOwnership.toFixed(2)) !== 100 && landowners.length > 0) {
        this.setSnackError(this.$t('landownershipIsNotAllocated', { actual: formatPercentage(totalOwnership) }))
        return false
      }

      return true
    },

    verifyRequest (tract) {
      if (tract.name.length === 0) {
        this.setSnackError(this.$t('nameFieldIsRequired'))
        return false
      }

      if (tract.type.tractTypeId === null) {
        this.setSnackError(this.$t('tractTypeIsRequired'))
        return false
      }

      if (tract.type.category === 0 && tract.businessEntityId === null) {
        this.setSnackError(this.$t('stumpageRequiresEntity'))
        return false
      }

      if (tract.spot === null) {
        this.setSnackError(this.$t('specifyTractLocationInGeography'))
        return false
      }

      if (tract.cost > rules.MAX_TRACT_COST) {
        this.setSnackError(this.$t('invalidTractCost'))
        return false
      }
      if (tract.rpi && (isNaN(parseFloat(tract.rpi)) || rules.default.rules.twoDecimalPlacesOrFewer(tract.rpi) !== true || (tract.rpi > rules.MAX_RPI || tract.rpi < 0))) {
        this.setSnackError(this.$t('invalidEntity', { entity: this.$t('rpi') }))
        return false
      }

      if (tract.dbh && (!/^\d{0,3}$/.test(tract.dbh) || (tract.dbh > rules.MAX_DBH || tract.dbh < 0))) {
        this.setSnackError(this.$t('invalidEntity', { entity: this.$t('dbh') }))
        return false
      }

      if (tract.age && (!/^\d{0,3}$/.test(tract.age) || (tract.age > rules.MAX_AGE || tract.age < 0))) {
        this.setSnackError(this.$t('invalidEntity', { entity: this.$t('age') }))
        return false
      }

      if (!!tract.teamId !== (tract.userIds?.length > 0)) {
        this.setSnackError(this.$t('selectBothTeamAndUsers'))
        return false
      }

      return true
    },

    getTractRequestObject (tract, landowners) {
      const requestObject = {
        name: tract.name,
        code: tract.code,
        paused: tract.paused,
        mapAndParcelNumber: tract.mapAndParcelNumber,
        tractTypeId: tract.type.tractTypeId,
        businessEntityId: tract.businessEntityId,
        foresterUserId: tract.foresterUserId,
        haulerAccountId: tract.haulerAccountId,
        supplierAccountId: tract.supplierAccountId,
        contractAccountId: tract.contractAccountId,
        contractSettingId: tract.contractSettingId,
        loggerAccountId: tract.loggerAccountId,
        loggerIds: tract.loggerIds,
        loggingAccessibilityTagId: tract.loggingAccessibilityTagId,
        requiresLogger: tract.requiresLogger,
        requiresExt1: tract.requiresExt1,
        requiresExt2: tract.requiresExt2,
        spot: tract.spot,
        cost: tract.cost,
        acres: parseInt(tract.acres),
        certificationIds: tract.certificationIds,
        purchaseDate: tract.purchaseDate ? localToUTC(tract.purchaseDate) : null,
        harvestStartDate: tract.harvestStartDate ? localToUTC(tract.harvestStartDate) : null,
        harvestEndDate: tract.harvestEndDate ? localToUTC(tract.harvestEndDate) : null,
        status: tract.status,
        pickupRange: tract.pickupRange,
        consultingForesterAccountId: tract.consultingForesterAccountId,
        landowners: landowners.map(lo => ({
          accountId: lo.accountId,
          ownership: lo.ownership
        })),
        rpi: tract.rpi ?? 0.0,
        dbh: tract.dbh ?? 0,
        age: tract.age ?? 0,
        userIds: tract.userIds,
        teamId: tract?.teamId ?? tract?.team?.teamId
      }

      if (this.isEditing) {
        requestObject.tractId = this.tract.tractId
        requestObject.modifiedAt = tract.modifiedAt
        requestObject.shouldApplyDates = this.applyDatesToContracts
      }

      if (tract.type.category === TractTypeCategory.Delivered.value) {
        requestObject.purchaseDate = null
        requestObject.businessEntityId = null
      }

      if (tract.type.category === TractTypeCategory.Stumpage.value && tract.type.depletes) {
        // Wipe out cost if it's a stumpage tract that depletes. The cost is derived from tract payments.
        if (!this.originalTract?.type?.depletes) {
          requestObject.cost = 0
        }
      }

      return requestObject
    },

    generalInformationMutated (generalInfo) {
      this.tract.name = generalInfo.name
      this.tract.code = generalInfo.code
      this.tract.foresterUserId = generalInfo.foresterUserId
      this.tract.businessEntityId = generalInfo.businessEntity?.businessEntityId
      this.tract.type = generalInfo.type
      this.tract.loggerAccountId = generalInfo.loggerAccountId
      this.tract.haulerAccountId = generalInfo.haulerAccountId
      this.tract.supplierAccountId = generalInfo.supplierAccountId
      this.tract.contractAccountId = generalInfo.contractAccountId
      this.tract.contractSettingId = generalInfo.contractSettingId
      this.tract.loggerIds = generalInfo.loggerAccountIds
      this.tract.certificationIds = generalInfo.certificationIds
      this.tract.harvestStartDate = generalInfo.harvestStartDate
      this.tract.harvestEndDate = generalInfo.harvestEndDate
      this.tract.purchaseDate = generalInfo.purchaseDate
      this.tract.requiresExt1 = generalInfo.requiresExt1
      this.tract.requiresExt2 = generalInfo.requiresExt2
      this.tract.requiresLogger = generalInfo.requiresLogger
      this.tract.cost = generalInfo.cost
      this.tract.consultingForesterAccountId = generalInfo.consultingForesterAccountId
      this.tract.rpi = generalInfo.rpi
      this.tract.dbh = generalInfo.dbh
      this.tract.age = generalInfo.age
      this.tract.userIds = generalInfo.userIds
      this.tract.teamId = generalInfo.teamId
    },

    ownershipMutated (landowners) {
      this.landowners = landowners
    },

    geographyMutated (geographyInfo) {
      this.tract.spot = geographyInfo.spot
      this.tract.mapAndParcelNumber = geographyInfo.mapAndParcelNumber
      this.tract.acres = geographyInfo.acres
      this.tract.pickupRange = geographyInfo.pickupRange
      this.tract.loggingAccessibilityTagId = geographyInfo.loggingAccessibilityTagId
    },

    shapeFileUpload (shapefile) {
      this.shapefile = shapefile
    },

    geojsonUploaded (geojson) {
      this.geojsonFile = geojson
    },

    openCruiseTab () {
      this.currentTab = 2
    }
  }
}
</script>
