<template>
  <v-card>
    <v-card-title class="primary">
      <span class="headline white--text">{{title}}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <div style="width: 100%;" ref="imageContainer">
        <img v-if="qrCodeLoader.success" :src="dataUrl" :draggable="false" :style="imageStyle"/>
      </div>
      <div style="text-align: center">
        <v-btn outlined color="primary" @click="toPdf(propContract)" id="download-contract-qr-code-button">
          <v-icon class="mr-1">mdi-download</v-icon>
          {{$t('downloadQrCodeSheet')}}
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ResourceLoader } from '../../utils/ResourceLoader'
import { tickTock } from '../../utils/componentHelpers'
import { generateContractIdSheetPDF, getContractQrCodeData } from '../../utils/pdf-formatters/ContractIdentification'
import { getLegnaQrCodeUrl } from '../../utils/LegnaQrCode'

export default {
  name: 'ContractQrCode',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    propContract: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    dataUrl: undefined,
    isMounted: false,
    imageSize: 0,
    imageOffset: { x: 0, y: 0 },
    qrCodeLoader: ResourceLoader.empty
  }),

  watch: {
    vuetifyBreakpointName () {
      this.repeatInterval(this.onResize, 500, 3)
    },
    dialogFullscreen () {
      this.repeatInterval(this.onResize, 400, 3)
    }
  },

  computed: {
    ...mapGetters('dialog', ['topDialogState']),
    ...mapGetters('user', ['companyInfo']),

    title () {
      return this.$t('contractQrCode')
    },

    imageStyle () {
      return `
        image-rendering: pixelated;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        transform: translate(${this.imageOffset.x}px, ${0}px);
      `
    },

    vuetifyBreakpointName () {
      return this.$vuetify?.breakpoint?.name ?? ''
    },

    dialogFullscreen () {
      return this.topDialogState?.fullscreen ?? false
    },

    qrCodeData () {
      return getContractQrCodeData(this.propContract, this.companyInfo.code)
    }
  },

  created () {
    this.qrCodeLoader = new ResourceLoader(async () => {
      await this.calculateImageSize(this.$refs.imageContainer)
      this.setQrCode()
    })
    window.addEventListener(
      'resize',
      () => {
        this.onResize()
        this.repeatInterval(this.onResize, 400, 2)
      },
      { passive: true }
    )
  },

  async mounted () {
    if (this.$refs.canvasRef) {
      this.$refs.canvasRef.width = 500
      this.$refs.canvasRef.height = 500
    }
    await this.calculateImageSize(this.$refs.imageContainer)
    await this.qrCodeLoader.load()
  },

  beforeDestroy () {
    window.removeEventListener(
      'resize',
      () => {
        this.onResize()
        this.repeatInterval(this.onResize, 400, 2)
      },
      { passive: true }
    )
  },

  methods: {
    ...mapActions('contract', ['getContract']),

    tickTock,

    async toPdf () {
      const contract = await this.getContract(this.propContract)
      const text = this.qrCodeData
      const qrCode = await getLegnaQrCodeUrl(250, text)
      generateContractIdSheetPDF(contract, qrCode)
    },

    async calculateImageSize () {
      const container = this.$refs.imageContainer
      this.tickTock(
        () => {
          this.imageSize = (container)
            ? Math.min(container.clientWidth, window.outerHeight - 230) - 200
            : 0
        },
        () => {
          this.imageOffset = {
            x: (container) ? (container.clientWidth - this.imageSize) / 2 : 0,
            y: (container) ? (container.clientHeight - this.imageSize) / 2 : 0
          }
        }
      )
    },

    repeatInterval (action, interval, n) {
      const intervalId = setInterval(() => {
        action()
        if (--n === 0) clearInterval(intervalId)
      }, interval)
    },

    async setQrCode () {
      this.dataUrl = await getLegnaQrCodeUrl(this.imageSize, this.qrCodeData)
    },

    async onResize () {
      await this.calculateImageSize()
      await this.setQrCode()
    }
  }
}
</script>
