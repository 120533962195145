<template>
  <v-row dense>
    <v-col cols="12" v-if="!isEditing">
      <span class="subtitle-1">
        {{ $t('printerSettings') }}
      </span>
    </v-col>
    <v-col cols="12">
      <v-text-field data-testid="printer-settings-address"
        v-model="printerSettings.address"
        :label="$t('address')"
        outlined color="primary"
        :value="printerSettings.address"
        @blur="updateSettings"
        :rules="[rules.validIpAddressOrBlank]"
        :readonly="!isEditing"
        style="max-width:200px"
        class="mb-n4"
      />
    </v-col>
    <v-col cols="12" md="6" v-if="displayDetails.logs || displayDetails.both || displayDetails.byproductPurchase">
      <DetailCard :title="$t('purchase')">
        <template>
          <v-col cols="12" class="mr-16" v-if="displayDetails.logs || displayDetails.both">
            <v-row>
              <v-col cols="12">
                <span class="subtitle-2">
                  {{ $t('logs') }}
                </span>
              </v-col>
              <v-col cols="12" lg="4">
                <v-text-field
                  v-model="printerSettings.logPurchaseTicketCopies"
                  type="number"
                  hide-spin-buttons
                  data-testid="printer-log"
                  :label="$t('logPurchaseTicketCopies')"
                  outlined color="primary"
                  :value="printerSettings.logPurchaseTicketCopies"
                  @blur="updateSettings"
                  :rules="[rules.required, rules.integer, rules.validNumericRange(1, 5)]"
                  :readonly="!isEditing"
                  class="mb-n4"
                />
              </v-col>
              <v-col cols="12" md="6" lg="8" class="mt-n7" align-self="end">
                <v-checkbox
                  v-model="printerSettings.showDefectQuantities"
                  :label="$t('showDefectQuantities')"
                  @change="updateSettings"
                  :readonly="!isEditing"
                  :on-icon=determineCheckBoxTrueDiplay
                  :off-icon="determineCheckBoxFalseDiplay"
                  :color="determineCheckBoxEditingColor(printerSettings.showDefectQuantities)"
                  :class="`${determineCheckBoxEditingColor(printerSettings.displayBarcode)}--text`"
                  :ripple="isEditing"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mr-16" v-if="(displayDetails.byproducts || displayDetails.both) && displayDetails.byproductPurchase">
            <v-row>
              <v-col cols="12">
                <span class="subtitle-2">
                  {{ $t('byproducts') }}
                </span>
              </v-col>
              <v-col cols="12" lg="4">
                <v-text-field
                  v-model="printerSettings.byproductPurchaseTicketCopies"
                  type="number"
                  hide-spin-buttons
                  data-testid="printer-byproduct"
                  :label="$t('byproductPurchaseTicketCopies')"
                  outlined color="primary"
                  :value="printerSettings.byproductPurchaseTicketCopies"
                  @blur="updateSettings"
                  :rules="[rules.required, rules.integer, rules.validNumericRange(1, 5)]"
                  :readonly="!isEditing"
                  class="mb-n4"
                />
              </v-col>
            </v-row>
          </v-col>
        </template>
      </DetailCard>
    </v-col>
    <v-col cols="12" md="6" v-if="displayDetails.logs || displayDetails.both || displayDetails.byproductPickup || displayDetails.byproductDelivery">
      <DetailCard :title="$t('sale')">
        <template>
          <v-col cols="12" class="mr-16" v-if="displayDetails.logs || displayDetails.both">
          <v-row>
            <v-col cols="12">
              <span class="subtitle-2">
                {{ $t('logs') }}
              </span>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" lg="4" v-if="displayDetails.logs || displayDetails.both">
                  <v-text-field
                    v-model="printerSettings.logYardSaleTicketCopies"
                    type="number"
                    hide-spin-buttons
                    data-testid="printer-log"
                    :label="$t('logYardSaleTicketCopies')"
                    outlined color="primary"
                    :value="printerSettings.logYardSaleTicketCopies"
                    @blur="updateSettings"
                    :rules="[rules.required, rules.integer, rules.validNumericRange(1, 5)]"
                    :readonly="!isEditing"
                    class="mb-n4"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mr-16" v-if="(displayDetails.byproducts || displayDetails.both) && (displayDetails.byproductDelivery || displayDetails.byproductPickup)">
          <v-row>
            <v-col cols="12">
              <span class="subtitle-2">
                {{ $t('byproducts') }}
              </span>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" lg="4" v-if="displayDetails.byproductDelivery">
                  <v-text-field
                    v-model="printerSettings.byproductDeliveryTicketCopies"
                    type="number"
                    hide-spin-buttons
                    data-testid="printer-byproduct"
                    :label="$t('byproductDeliveryTicketCopies')"
                    outlined color="primary"
                    :value="printerSettings.byproductDeliveryTicketCopies"
                    @blur="updateSettings"
                    :rules="[rules.required, rules.integer, rules.validNumericRange(1, 5)]"
                    :readonly="!isEditing"
                    class="mb-n4"
                  />
                </v-col>
                <v-col cols="12" lg="4" v-if="displayDetails.byproductPickup">
                  <v-text-field
                    v-model="printerSettings.byproductPickupTicketCopies"
                    type="number"
                    hide-spin-buttons
                    data-testid="printer-byproduct"
                    :label="$t('byproductPickupTicketCopies')"
                    outlined color="primary"
                    :value="printerSettings.byproductPickupTicketCopies"
                    @blur="updateSettings"
                    :rules="[rules.required, rules.integer, rules.validNumericRange(1, 5)]"
                    :readonly="!isEditing"
                    class="mb-n4"
                  />
                </v-col>
                <v-col cols="12" lg="4" v-if="printerSettings.enableCustomBol">
                  <v-text-field
                    v-model="printerSettings.bolCopies"
                    type="number"
                    hide-spin-buttons
                    data-testid="printer-byproduct"
                    :label="$t('bolCopies')"
                    outlined color="secondary"
                    :value="printerSettings.bolCopies"
                    @blur="updateSettings"
                    :rules="[rules.required, rules.integer, rules.validNumericRange(1, 5)]"
                    :readonly="!isEditing"
                    class="mb-n4"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" lg="auto" class="mt-n7">
              <v-checkbox
                v-if="displayDetails.byproductDelivery"
                v-model="printerSettings.displayBarcode"
                :label="$t('displayBarcodeOnByproductTickets')"
                @change="updateSettings"
                :readonly="!isEditing"
                :on-icon=determineCheckBoxTrueDiplay
                :off-icon="determineCheckBoxFalseDiplay"
                :color="determineCheckBoxEditingColor(printerSettings.displayBarcode)"
                :class="`${determineCheckBoxEditingColor(printerSettings.displayBarcode)}--text`"
                :ripple="isEditing"
              />
            </v-col>
            <v-col cols="12" sm="4" lg="auto" class="mt-n7">
                <v-checkbox
                  v-if="displayDetails.byproducts || displayDetails.both"
                  v-model="printerSettings.enableCustomBol"
                  :label="$t('enableCustomBol')"
                  @change="updateSettings"
                  :readonly="!isEditing"
                  :on-icon=determineCheckBoxTrueDiplay
                  :off-icon="determineCheckBoxFalseDiplay"
                  :color="determineCheckBoxEditingColor(printerSettings.enableCustomBol)"
                  :class="`${determineCheckBoxEditingColor(printerSettings.enableCustomBol)}--text`"
                  :ripple="isEditing"
                />
            </v-col>
          </v-row>
        </v-col>
        </template>
      </DetailCard>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import fieldRules from '@/utils/rules.js'
export default {
  name: 'PrinterSettingsForm',

  components: {
    DetailCard: () => import('@/components/core/DetailCard.vue')
  },

  props: {
    propPrinter: {
      type: Object,
      required: true
    },
    displayDetails: {
      type: Object,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    printerSettings: {
      displayBarcode: false,
      showDefectQuantities: false,
      address: '',
      logPurchaseTicketCopies: 1,
      logYardSaleTicketCopies: 1,
      byproductDeliveryTicketCopies: 1,
      byproductPickupTicketCopies: 1,
      byproductPurchaseTicketCopies: 1,
      bolCopies: 1,
      enableCustomBol: false
    },
    rules: fieldRules.rules
  }),

  created () {
    if (this.propPrinter) this.printerSettings = JSON.parse(JSON.stringify(this.propPrinter))
  },

  computed: {
    ...mapGetters('receiver-settings', ['loading']),
    determineCheckBoxTrueDiplay () {
      return this.isEditing ? '$checkboxOn' : 'mdi-check'
    },
    determineCheckBoxFalseDiplay () {
      return this.isEditing ? '$checkboxOff' : 'mdi-close'
    }
  },

  methods: {
    updateSettings () {
      this.$emit('updatePrinter', this.printerSettings)
    },
    determineCheckBoxEditingColor (value) {
      return this.isEditing ? undefined : value ? 'success' : 'error'
    }
  }
}
</script>
