import { render, staticRenderFns } from "./AccountCertificationCard.vue?vue&type=template&id=bd9e4fa0&"
import script from "./AccountCertificationCard.vue?vue&type=script&lang=js&"
export * from "./AccountCertificationCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports