import ApiServer from '@/utils/api/ApiServer.js'
import { DeckContentType } from '@/utils/Enumerations'
import store from '@/store'

export default {
  fetchSummaryData: async (_, { sinceTime, untilTime, locationId, includeInternal, includeExternal, useLoadCreatedAt }) => {
    if (sinceTime === undefined) {
      const today = new Date()
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      sinceTime = firstDayOfMonth.toISOString()
    }

    const params = {
      sinceTime,
      untilTime,
      includeInternal,
      includeExternal,
      useLoadCreatedAt: useLoadCreatedAt ?? false,
      locationId: locationId !== -1 ? locationId : undefined
    }

    const ticketSummaryUrl = ApiServer.urlFor('dashboard/ticketSummaries', { params })
    const defectsByAccountUrl = ApiServer.urlFor('dashboard/itemizedDefectSummaryByAccount', { params })

    const { data: ticketSummaries } = await ApiServer.get(ticketSummaryUrl)
    const { data: defectsByAccount } = await ApiServer.get(defectsByAccountUrl)
    return {
      ticketSummaries,
      defectsByAccount
    }
  },

  fetchLandownerSummaries: async (_, { sinceTime, untilTime, locationId, includeInternal, includeExternal, useLoadCreatedAt }) => {
    if (sinceTime === undefined) {
      const today = new Date()
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      sinceTime = firstDayOfMonth.toISOString()
    }

    const params = {
      sinceTime,
      untilTime,
      includeInternal,
      includeExternal,
      useLoadCreatedAt: useLoadCreatedAt ?? false,
      locationId: locationId !== -1 ? locationId : undefined
    }

    const landownerTicketSummaryUrl = ApiServer.urlFor('dashboard/landownerTicketSummaries', { params })

    const { data: landownerTicketSummaries } = await ApiServer.get(landownerTicketSummaryUrl)
    return { landownerTicketSummaries }
  },

  fetchByproductSummaries: async (_, { sinceTime, untilTime, locationId, includeInternal, includeExternal }) => {
    if (sinceTime === undefined) {
      const today = new Date()
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      sinceTime = firstDayOfMonth.toISOString()
    }

    const ticketSummaryUrl = ApiServer.urlFor('dashboard/byproductTicketSummaries', {
      params: {
        sinceTime,
        untilTime,
        includeInternal,
        includeExternal,
        locationId: locationId !== -1 ? locationId : undefined
      }
    })

    const { data: ticketSummaries } = await ApiServer.get(ticketSummaryUrl)
    return ticketSummaries
  },

  fetchItemizedDefectSummary: async (_, { sinceTime, untilTime, locationId }) => {
    if (sinceTime === undefined) {
      const today = new Date()
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      sinceTime = firstDayOfMonth.toISOString()
    }

    const itemizedDefectSummaryUrl = ApiServer.urlFor('dashboard/itemizedDefectSummaryByDefect', {
      params: {
        sinceTime,
        untilTime,
        locationId: locationId !== -1 ? locationId : undefined
      }
    })

    const { data: itemizedDefectSummaryData } = await ApiServer.get(itemizedDefectSummaryUrl)

    return itemizedDefectSummaryData
  },

  toggleSummaryIsTons: (context, summaryKey) => {
    context.commit('toggleSummaryIsTons', summaryKey)
  },

  getYardTimes: async (_, { locationId, contentType = DeckContentType.Logs.value }) => {
    if (!locationId) {
      return []
    } else {
      const url = ApiServer.urlFor('dashboard/timeinyard', {
        params: { locationId, contentType }
      })
      const response = await ApiServer.get(url)
      return response.data
    }
  },

  fetchLogAnalysis: async (_, { locationId, sinceTime, untilTime }) => {
    const url = ApiServer.urlFor('dashboard/logcostanalysis', {
      params: { locationId, sinceTime, untilTime }
    })
    const response = await ApiServer.get(url)
    return response.data
  },

  fetchLogQualityAnalysis: async (_, { locationId, sinceTime, untilTime }) => {
    const url = ApiServer.urlFor('dashboard/logQualityAnalysis', {
      params: { locationId, sinceTime, untilTime }
    })
    const response = await ApiServer.get(url)
    return response.data
  },

  fetchTonsByCertification: async (_, { locationId, sinceTime, untilTime, includeInternal, includeExternal }) => {
    const params = {
      sinceTime,
      untilTime,
      includeExternal,
      includeInternal,
      locationId: locationId !== -1 ? locationId : undefined,
      includeUncertified: true
    }
    const url = ApiServer.urlFor('dashboard/tonsbycertification', {
      params
    })
    const { data: tonsByCertification } = await ApiServer.get(url)
    return tonsByCertification
  },

  fetchLoadsInTransitByDestination: async () => {
    const url = ApiServer.urlFor('dashboard/loadsintransitbydestination')
    const response = await ApiServer.get(url)
    return response.data.map(d =>
      ({
        ...d,
        loadCountAndTract: d.loadCountAndTract.filter(t => t.tractName !== '')
        // TODO - JR: Filter out LYS and Transfer tickets for now.
      })).filter(d => d.loadCountAndTract.length > 0)
  },

  fetchTrucksInYard: async (_, locationId) => {
    const url = ApiServer.urlFor('dashboard/trucksinyard', {
      params: { locationId }
    })
    const response = await ApiServer.get(url)
    return response.data
  },

  fetchTicketsReadyForPickup: async (_, { limitToUnreserved = false, myTracts = false }) => {
    const url = ApiServer.urlFor('dashboard/ticketsforpickup', {
      params: { limitToUnreserved, tractsForUserWithId: myTracts ? store.getters['user/userInfo'].applicationUserId : undefined }
    })
    const response = await ApiServer.get(url)

    // Filter out log yard sale tickets for now.
    return response.data.filter(t => t.tract !== null)
  },

  fetchForecastedDaysRemaining: async () => {
    const url = ApiServer.urlFor('dashboard/standingtimberforecast')
    const response = await ApiServer.get(url)
    return response.data
  },

  fetchIncompleteTickets: async (context) => {
    const url = ApiServer.urlFor('dashboard/incompletetickets')
    const response = await ApiServer.get(url)
    context.commit('setIncompleteTickets', response.data)
    return response.data
  },

  getWidgetInteractionStatus: (context, { widgetId, x, y = undefined }) => {
    const hasTouchScreen = context.state.hasTouchScreen
    if (!hasTouchScreen & !!x) return true
    const lastX = context.state.lastWidgetTap.x
    const lastY = context.state.lastWidgetTap.y
    const lastId = context.state.lastWidgetTap.widgetId
    if (!!x && lastX === x && lastY === y && lastId === widgetId) {
      context.commit('setLastWidgetTap', { widgetId: undefined, x: undefined, y: undefined })
      return true
    } else {
      context.commit('setLastWidgetTap', { widgetId: widgetId, x: x, y: y })
      return false
    }
  }
}
