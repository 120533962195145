export default {
  getIconForObjectType: (objectType, enabled = true) => {
    switch (objectType.toLowerCase()) {
      case 'logger':
        return 'mdi-axe'
      case 'destination':
        return enabled ? 'mdi-domain' : 'mdi-domain-off'
      case 'account':
        return enabled ? 'mdi-account' : 'mdi-account-outline'
      case 'fromaccount':
        return 'mdi-account-arrow-right'
      case 'tract':
        return enabled ? 'mdi-crosshairs-gps' : 'mdi-crosshairs-off'
      case 'product':
        return enabled ? 'mdi-pine-tree-variant' : 'mdi-pine-tree-variant-outline'
      case 'region':
        return enabled ? 'mdi-map-marker' : 'mdi-map-marker-off'
      case 'landowner':
        return 'mdi-target-account'
      case 'tracttype':
        return 'mdi-target-variant'
      case 'defect':
        return enabled ? 'mdi-alert-decagram' : 'mdi-alert-decagram-outline'
      case 'date':
        return 'mdi-clock-outline'
      case 'certification':
        return 'mdi-certificate-outline'
      case 'reservation':
        return enabled ? 'truck-account' : 'truck-account-outline' // This is a custom icon, caller must be able to associate with our SVG
      case 'mytracts':
        return enabled ? 'mdi-account-circle' : 'mdi-account-circle-outline'
      case 'driver':
        return enabled ? 'mdi-truck' : 'mdi-truck-outline'
    }
  },

  getColorForIcon: (icon) => {
    const iconName = icon.replace('mdi-', '')
    switch (iconName) {
      case 'pencil':
      case 'checkbox-marked-circle': return 'success'
      case 'delete-forever': return 'error'
      case 'pause-circle': return 'warning'
      case 'transfer':
      case 'check-circle-outline': return 'grey'
      case 'wallet-bifold':
      case 'cancel':
      case 'certificate-outline':
      case 'circle-outline': return 'black'
      default: return 'primary'
    }
  }
}
