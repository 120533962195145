import { Enum, EnumValue } from '@/utils/Enumerations'
import i18n from '../../i18n'

export class DashboardLogsWidgets extends Enum {
  static CompletedLoads = new EnumValue(11, 'completedLoads')
  static CreatedLoads = new EnumValue(18, 'createdLoads')
  static TotalTons = new EnumValue(12, 'totalTons')
  static PercentageTractCertified = new EnumValue(13, 'percentageTractCertified')
  static PercentageAccountCertified = new EnumValue(14, 'percentageAccountCertified')
  static TransferLoads = new EnumValue(15, 'transferLoads')
  static TrucksInYard = new EnumValue(16, 'trucksInYard')
  static CompletedLoadsByAccount = new EnumValue(0, 'completedLoadsByAccount')
  static CompletedLoadsByLogger = new EnumValue(1, 'completedLoadsByLogger')
  static CompletedLoadsByTract = new EnumValue(2, 'completedLoadsByTract')
  static CompletedLoadsByProduct = new EnumValue(3, 'completedLoadsByProduct')
  static CompletedLoadsByRegion = new EnumValue(4, 'completedLoadsByRegion')
  static CompletedLoadsByDestination = new EnumValue(25, 'completedLoadsByDestination')
  static CompletedLoadsByLandOwner = new EnumValue(27, 'completedLoadsByLandOwner')
  static CreatedLoadsByAccount = new EnumValue(19, 'createdLoadsByAccount')
  static CreatedLoadsByLogger = new EnumValue(20, 'createdLoadsByLogger')
  static CreatedLoadsByTract = new EnumValue(21, 'createdLoadsByTract')
  static CreatedLoadsByTractType = new EnumValue(22, 'createdLoadsByTractType')
  static CreatedLoadsByProduct = new EnumValue(23, 'createdLoadsByProduct')
  static CreatedLoadsByRegion = new EnumValue(24, 'createdLoadsByRegion')
  static CreatedLoadsByDestination = new EnumValue(26, 'createdLoadsByDestination')
  static DailyInventoryCost = new EnumValue(5, 'dailyInventoryCost')
  static ItemizedDefectSummary = new EnumValue(7, 'itemizedDefectSummary')
  static DefectsByAccount = new EnumValue(8, 'defectsByAccount')
  static LogQualityAnalysis = new EnumValue(9, 'logQualityAnalysis')
  static TonsByCertification = new EnumValue(10, 'tonsByTractCertification')
  static CompletedLoadsByTractType = new EnumValue(17, 'completedLoadsByTractType')
  static LoadsByDriver = new EnumValue(28, 'loadsByDriver')
}

export class DashboardOperationalWidgets extends Enum {
  static IncompleteTickets = new EnumValue(3, 'incompleteTickets')
  static LoadsInTransitByDestination = new EnumValue(0, 'loadsInTransitByDestination')
  static LoadsReadyForPickup = new EnumValue(1, 'loadsReadyForPickup')
  static ForecastedDaysRemainingByLogger = new EnumValue(2, 'forecastedDaysRemainingByLogger')
}

export class DashboardByproductWidgets extends Enum {
  static WeighedOutLoads = new EnumValue(1, 'weighedOutLoads')
  static WeighedOutTons = new EnumValue(2, 'weighedOutTons')
  static CreatedLoads = new EnumValue(3, 'createdLoads')
  static CompletedLoadsByDestination = new EnumValue(4, 'loadsByDestination')
  static CompletedLoadsByProduct = new EnumValue(5, 'loadsByProduct')
  static CompletedLoadsByAccount = new EnumValue(8, 'loadsByContractAccount')
  static CreatedLoadsByDestination = new EnumValue(6, 'createdLoadsByDestination')
  static CreatedLoadsByProduct = new EnumValue(7, 'createdLoadsByProduct')
  static CreatedLoadsByAccount = new EnumValue(9, 'createdLoadsByContractAccount')
  static LoadsByDriver = new EnumValue(10, 'loadsByDriver')
}

export class AccountLoggerToggle extends Enum {
  static Logger = new EnumValue(0, 'logger')
  static Account = new EnumValue(1, 'account')
}

export const logsWidgetMappings = [
  {
    widget: DashboardLogsWidgets.CompletedLoads,
    widgetType: 'card',
    icon: 'mdi-truck',
    color: 'grey'
  },
  {
    widget: DashboardLogsWidgets.CreatedLoads,
    widgetType: 'card',
    icon: 'mdi-truck',
    color: 'grey'
  },
  {
    widget: DashboardLogsWidgets.TotalTons,
    widgetType: 'card',
    icon: 'mdi-weight',
    color: 'secondary'
  },
  {
    widget: DashboardLogsWidgets.PercentageTractCertified,
    widgetType: 'card',
    icon: 'mdi-crosshairs-gps',
    color: 'secondary'
  },
  {
    widget: DashboardLogsWidgets.PercentageAccountCertified,
    widgetType: 'card',
    icon: 'mdi-account',
    color: 'secondary'
  },
  {
    widget: DashboardLogsWidgets.TransferLoads,
    widgetType: 'card',
    icon: 'mdi-transfer',
    color: 'grey',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.TrucksInYard,
    widgetType: 'card',
    icon: 'mdi-truck',
    color: 'secondary',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CompletedLoadsByAccount,
    widgetType: 'chart',
    loadStatus: 'completed',
    type: 'account',
    elementId: 'loads-by-account-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CompletedLoadsByLogger,
    widgetType: 'chart',
    loadStatus: 'completed',
    type: 'logger',
    elementId: 'loads-by-logger-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CompletedLoadsByTract,
    widgetType: 'chart',
    loadStatus: 'completed',
    type: 'tract',
    elementId: 'loads-by-tract-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CompletedLoadsByProduct,
    widgetType: 'chart',
    chartType: 'barChartCardLoadsByProduct',
    loadStatus: 'completed',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CompletedLoadsByRegion,
    widgetType: 'chart',
    loadStatus: 'completed',
    type: 'region',
    elementId: 'loads-by-region-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CompletedLoadsByDestination,
    widgetType: 'chart',
    loadStatus: 'completed',
    type: 'destination',
    elementId: 'loads-by-destination-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CompletedLoadsByLandOwner,
    widgetType: 'chart',
    loadStatus: 'completed',
    type: 'landOwner',
    elementId: 'loads-by-land-owner-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CreatedLoadsByAccount,
    widgetType: 'chart',
    loadStatus: 'created',
    type: 'account',
    elementId: 'loads-by-account-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CreatedLoadsByLogger,
    widgetType: 'chart',
    loadStatus: 'created',
    type: 'logger',
    elementId: 'loads-by-logger-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CreatedLoadsByTract,
    widgetType: 'chart',
    loadStatus: 'created',
    type: 'tract',
    elementId: 'loads-by-tract-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CreatedLoadsByTractType,
    widgetType: 'chart',
    loadStatus: 'created',
    type: 'tractType',
    elementId: 'loads-by-tract-type-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CreatedLoadsByProduct,
    widgetType: 'chart',
    chartType: 'barChartCardLoadsByProduct',
    loadStatus: 'created',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CreatedLoadsByRegion,
    widgetType: 'chart',
    loadStatus: 'created',
    type: 'region',
    elementId: 'loads-by-region-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CreatedLoadsByDestination,
    widgetType: 'chart',
    loadStatus: 'created',
    type: 'destination',
    elementId: 'loads-by-destination-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.DailyInventoryCost,
    widgetType: 'chart',
    chartType: 'logAnalysisChart',
    elementId: 'cost-summary-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.ItemizedDefectSummary,
    widgetType: 'chart',
    type: 'ItemizedDefectSummaryChart',
    overrideTitle: i18n.t('itemizedDefectSummary'),
    allowSecondaryGrouping: false,
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.DefectsByAccount,
    widgetType: 'chart',
    chartType: 'stackedBarChartCardForDefectsByAccount',
    type: 'defectSummary',
    elementId: 'defects-by-account-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.LogQualityAnalysis,
    widgetType: 'chart',
    chartType: 'logQualityAnalysisChart',
    elementId: 'small-end-diameter-summary-chart',
    title: 'logQualityAnalysis',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.TonsByCertification,
    widgetType: 'chart',
    chartType: 'barChartCardTonsByCertification',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.CompletedLoadsByTractType,
    widgetType: 'chart',
    loadStatus: 'completed',
    type: 'tractType',
    elementId: 'loads-by-tract-type-chart',
    isInteractive: true
  },
  {
    widget: DashboardLogsWidgets.LoadsByDriver,
    widgetType: 'chart',
    type: 'driver',
    elementId: 'loads-by-driver-chart',
    isInteractive: true
  }
]

export const byproductsWidgetMappings = [
  {
    widget: DashboardByproductWidgets.WeighedOutLoads,
    widgetType: 'card'
  },
  {
    widget: DashboardByproductWidgets.WeighedOutTons,
    widgetType: 'card'
  },
  {
    widget: DashboardByproductWidgets.CreatedLoads,
    widgetType: 'card'
  },
  {
    widget: DashboardByproductWidgets.CompletedLoadsByDestination,
    widgetType: 'chart',
    loadStatus: 'completed'
  },
  {
    widget: DashboardByproductWidgets.CompletedLoadsByProduct,
    widgetType: 'chart',
    loadStatus: 'completed'
  },
  {
    widget: DashboardByproductWidgets.CompletedLoadsByAccount,
    widgetType: 'chart',
    loadStatus: 'completed'
  },
  {
    widget: DashboardByproductWidgets.CreatedLoadsByDestination,
    widgetType: 'chart',
    loadStatus: 'created'
  },
  {
    widget: DashboardByproductWidgets.CreatedLoadsByProduct,
    widgetType: 'chart',
    loadStatus: 'created'
  },
  {
    widget: DashboardByproductWidgets.CreatedLoadsByAccount,
    widgetType: 'chart',
    loadStatus: 'created'
  },
  {
    widget: DashboardByproductWidgets.LoadsByDriver,
    widgetType: 'chart',
    loadStatus: 'created'
  }
]
