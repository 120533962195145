<template>
  <v-autocomplete
  color="black"
  item-color="primary"
  v-model="chosenTractType"
  data-testid="tract-type"
  :label="$t('tractType')"
  :items="allTractTypes"
  return-object
  item-text="name"
  item-value="name"
  :rules="[rules.required]"
  :persistent-hint="persistentHint"
  :hint="hint"
  >
    <template #item="{item}">
      {{`${item.name} (${categoryFromInt(item.category)})`}}
      <Icon
      v-if="item.depletes"
      icon="mdi-wallet-bifold"
      :tooltipText="$t('depletes')"
      iconColor="black"
      :small="false"/>
    </template>
    <template #selection="{item}">
      {{`${item.name} (${categoryFromInt(item.category)})`}}
      <Icon
      v-if="item.depletes"
      icon="mdi-wallet-bifold"
      :tooltipText="$t('depletes')"
      iconColor="black"
      :small="false"/>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TractTypeCategory } from '@/utils/Enumerations.js'
import { rules } from '@/utils/FormRules'

export default {
  name: 'TractTypeSelector',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    tractTypeId: {
      type: Number,
      default: undefined
    },
    hint: {
      type: String,
      default: '',
      required: false
    },
    persistentHint: {
      type: Boolean,
      default: false,
      required: false
    },
    propFetchTractTypes: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  data: () => ({
    chosenTractType: undefined,
    rules
  }),

  created () {
    this.refreshTractTypes(this.tractTypeId, true)
  },

  watch: {
    chosenTractType (val) {
      this.$emit('tract-type', val)
    }
  },

  computed: {
    ...mapGetters('tract-type', ['allTractTypes'])
  },

  methods: {
    ...mapActions('tract-type', ['fetchTractTypes', 'createTractType']),
    categoryFromInt: (x) => TractTypeCategory.fromInt(x),

    async refreshTractTypes (tractTypeId, creation = false) {
      if (!creation || this.propFetchTractTypes) await this.fetchTractTypes()
      if (tractTypeId) {
        this.chosenTractType = this.allTractTypes.find(tt => tt.tractTypeId === tractTypeId)
      }
    }
  }
}
</script>
