<template>
  <material-card
  color="primary"
  full-width
  cardClass="mb-0">
  <template #header>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters align="center">
          <v-col cols="auto">
            <Icon iconColor="white" :icon="team.isActive ? 'mdi-checkbox-marked-circle' : 'mdi-cancel'" :small="false" margin="mr-2"
            :tooltipText="team.isActive ? $t('active') : $t('inactive')"></Icon>
          </v-col>
          <v-col>
            <p class="headline font-weight-regular mb-2" v-text="team.name" style="word-break: break-word"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <BaseDialogActions hideFullscreen hideRefresh class="mt-2">
          <template #left>
            <Icon
            :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
            icon="mdi-pencil"
            iconColor="tertiary"
            :small="false"
            :tooltipText="$t('editItem', { item: $t('team') })"
            @icon-clicked="$emit('edit', team)"/>
          </template>
        </BaseDialogActions>
      </v-col>
      <v-col cols="12">
        <p class="category font-weight-thin" v-text="team.description" />
      </v-col>
    </v-row>
  </template>
    <v-container>
      <v-data-table
      mobile-breakpoint="0"
      :items="team.users"
      :headers="[{text: $t('user'), value: 'name'}]"
      hide-default-footer
      item-text="name"
      dense
      :items-per-page="32"
      item-value="applicationUserId">
      </v-data-table>
    </v-container>
  </material-card>
</template>

<script>
import { userAssignedClaim } from '@/utils/ClaimUtility'
import { UserClaims } from '@/utils/Enumerations'

export default {
  name: 'TeamDetail',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    team: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    UserClaims
  }),

  methods: {
    userAssignedClaim
  }
}

</script>
